import React, { FunctionComponent } from "react";
import Modal from "antd/es/modal";
import "./result.css";

interface ModalProps {
  visible: boolean;
  onCancel: any;
  onOk: any;
  source: string;
}

const NavigationModal: FunctionComponent<ModalProps> = props => {
  const openRoomLink = () => {
    window.open(props.source, "_blank");
    props.onOk();
  };

  const getDomainName = () => {
    if (props.source.length > 0) {
      let domainUrl = new URL(props.source);
      let domain = domainUrl.hostname.replace("www.", "");
      return domain;
    }
  };

  return (
    <div>
      <Modal
        title="You’re leaving Rentalios.com"
        visible={props.visible}
        footer={null}
        wrapClassName="navigation-modal"
        closeIcon={<span onClick={props.onCancel}>X</span>}
      >
        <div>
          <p className="navigation-modal-message">
            You are about to leave this site.{" "}
            <span style={{ color: "#66b015", textDecoration: "underline" }}>
              {getDomainName()}
            </span>{" "}
            will open in a new browser window or tab. Would you like to proceed?
          </p>
        </div>
        <div style={{ position: "relative", marginBottom: "90px" }}>
          <button
            className="navigation-modal-cancel"
            key="back"
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            className="navigation-modal-proceed"
            key="submit"
            type="primary"
            onClick={openRoomLink}
          >
            Proceed
          </button>
        </div>
        <div>
          <p style={{ color: "rgba(74, 74, 74, 0.7)", fontSize: "12px" }}>
            Rentalios is not rensponsible for any content on third party sites.
          </p>
        </div>
      </Modal>
    </div>
  );
};
export default NavigationModal;
