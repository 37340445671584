import { PRIMARY_COLOR } from "../../../constants";

const styles = {
  navbar: {
    boxShadow: "0 2px 10px -1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#ffffff",
    height: "9vh"
  },
  brand: {
    fontSize: "19px",
    fontWeight: 900,
    color: PRIMARY_COLOR,
    verticalAlign: "middle"
  },
  login_background: {
    backgroundImage:
      "url('https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5ca3d02ba06ad52c415dfec9_Background-min.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  black_background: {
    backgroundColor: "#00000078",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  container: {
    paddingTop: "50px"
  },
  choice_modal: {
    background: "white",
    margin: "auto",
    display: "block",
    //width: "50%",
    padding: "40px 50px 20px 50px",
    color: "#4a4a4a",
    textAlign: "center" as "center",
    fontFamily: "AvenirNext, Helvetica"
  },
  login_title: {
    fontWeight: "bold" as "bold",
    fontSize: "20px",
    marginBottom: "20px"
  },
  choice_button: {
    background: "none",
    border: `2px solid ${PRIMARY_COLOR}`,
    width: "100%",
    padding: "15px",
    marginBottom: "15px",
    color: "#414850",
    fontWeight: "bold" as "bold"
  },
  skip_button: {
    textAlign: "center" as "center",
    textDecoration: "none",
    color: PRIMARY_COLOR,
    cursor: "pointer",
    marginTop: "60px",
    marginLeft: "auto"
  },
  add_button: {
    textAlign: "left" as "left",
    paddingLeft: "30px",
    color: PRIMARY_COLOR,
    cursor: "pointer",
    background:
      "url('https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5d000007d43eb76f5d439480_add.svg') no-repeat",
    backgroundPosition: "left center"
  },
  min_button: {
    textAlign: "left" as "left",
    paddingLeft: "30px",
    color: PRIMARY_COLOR,
    cursor: "pointer",
    background:
      "url('https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5d00000a7c779f398c8fb60e_min.svg') no-repeat",
    backgroundPosition: "left center"
  },
  submit_button: {
    color: "#FFF",
    background: PRIMARY_COLOR,
    padding: "5px",
    width: "20%",
    margin: "auto",
    borderRadius: "5px",
    border: "none"
  },
  add_more_rents: {
    color: "#FFF",
    background: PRIMARY_COLOR,
    padding: "5px",
    float: "right" as "right",
    borderRadius: "5px",
    border: "none"
  },
  alert_error: {
    position: "fixed" as "fixed",
    top: "200px",
    display: "none"
  },
  edit_rental: {
    position: "absolute" as "absolute",
    top: "100px",
    display: "none"
  }
};

export default styles;
