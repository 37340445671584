import React from "react";

const Logo: React.FunctionComponent<{
  width?: string;
  height?: string;
}> = ({ width = "11", height = "21" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 11 21"
    fill="none"
  >
    <path
      d="M0.549418 11.152H2.74174V20.1774C2.74174 20.3556 2.88613 20.5 3.06432 20.5H6.78148C6.95968 20.5 7.10406 20.3556 7.10406 20.1774V11.1945H9.62432C9.78819 11.1945 9.92606 11.0715 9.94477 10.9088L10.3275 7.58606C10.3381 7.49465 10.3091 7.4031 10.2479 7.33452C10.1867 7.26587 10.0991 7.22658 10.0072 7.22658H7.10419V5.14374C7.10419 4.51587 7.44226 4.19748 8.1091 4.19748C8.20413 4.19748 10.0072 4.19748 10.0072 4.19748C10.1854 4.19748 10.3297 4.05303 10.3297 3.8749V0.824968C10.3297 0.646774 10.1854 0.502387 10.0072 0.502387H7.39135C7.3729 0.501484 7.33193 0.5 7.27155 0.5C6.81768 0.5 5.24006 0.589097 3.99387 1.73555C2.61309 3.006 2.80503 4.52716 2.8509 4.7909V7.22652H0.549418C0.371224 7.22652 0.226837 7.3709 0.226837 7.5491V10.8294C0.226837 11.0075 0.371224 11.152 0.549418 11.152Z"
      fill="#3B5891"
    />
  </svg>
);

export default Logo;
