import React, { FunctionComponent } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Checkbox from "antd/es/checkbox";
import InputNumber from "antd/es/input-number";
import SearchGlassIcon from "../../../components/icons/SearchGlass";
import customHook from "./hook";
import Popover from "antd/es/popover";
import ArrowDownIcon from "../../../components/icons/Down";
import { generateSharableQuery } from "../../../utilities";
import { useHistory } from "react-router-dom";

interface SearchProps {
  page?: Number;
  address?: String;
  page_size?: Number;
  house_type?: string;
  private_room?: number;
  private_bath?: number;
  onSearchRoom: Function;
  laundry?: number;
  min_price?: number;
  max_price?: number;
  wheelchair?: number;
  smoking?: number;
  cats?: number;
  parking?: number;
}

const roomOptions = [
  { label: "Shared", value: "0" },
  { label: "Private", value: "1" }
];

const propertyOptions = [
  { label: "House", value: "1" },
  { label: "Apartment", value: "0" }
];

const laundryOptions = [
  { label: "No Laundry", value: "0" },
  { label: "In Unit", value: "1" },
  { label: "Hookups", value: "2" },
  { label: "On Building", value: "3" }
];

const TopInput: FunctionComponent<SearchProps> = props => {
  const topElement = {
    paddingLeft: "16px",
    paddingTop: "13px",
    paddingBottom: "15px",
    paddingRight: "22px",
    borderBottom: "solid 1px #E0E0E0"
  };
  const history = useHistory();

  const searchRoom = async () => {
    delete inputs.onSearchRoom;
    if (!inputs.address) {
      alert("Missing the address field.");
    } else {
      props.onSearchRoom(inputs);
    }
  };

  const handleEstimateBtn = () => {
    delete inputs.onSearchRoom;
    if (!inputs.address) {
      alert("Missing the address field.");
    } else {
      const searchQuery = generateSharableQuery(inputs);
      history.push({
        pathname: "/results",
        search: searchQuery
      });
    }
  };

  const { inputs, handleInputChange, handleSubmit } = customHook(
    {
      ...props
    },
    searchRoom
  );

  return (
    <div style={topElement}>
      <Row>
        <Col span={5}>
          <Input
            suffix={<SearchGlassIcon />}
            placeholder="Enter Address or Zip Code"
            onChange={e => handleInputChange(e.target.value, "address")}
            value={inputs.address}
          />
        </Col>
        <Col style={{ marginLeft: 10, minWidth: "100px" }} span={2}>
          <Popover
            placement="bottomLeft"
            content={
              <div className="price-popover">
                <Row>
                  <Col span={12} style={{ paddingRight: "5px" }}>
                    <InputNumber
                      onChange={e => handleInputChange(e, "min_price")}
                      value={inputs.min_price}
                      placeholder="Min Rent"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={12}>
                    <InputNumber
                      onChange={e => handleInputChange(e, "max_price")}
                      value={inputs.max_price}
                      placeholder="Max Rent"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </div>
            }
            trigger="click"
          >
            <div className="property-type-collapse">
              <div className="text-input">Desired Rent</div>
            </div>
          </Popover>
        </Col>
        <Col span={3}>
          <Popover
            placement="bottomLeft"
            content={
              <div className="property-popover-content">
                <Checkbox.Group
                  defaultValue={inputs.house_type}
                  options={propertyOptions}
                  onChange={e => handleInputChange(e, "house_type")}
                />
              </div>
            }
            trigger="click"
          >
            <div className="property-type-collapse">
              <div className="text-input">
                Property Type
                <div className="arrow">
                  <ArrowDownIcon />
                </div>
              </div>
            </div>
          </Popover>
        </Col>
        <Col span={3}>
          <Popover
            placement="bottomLeft"
            content={
              <div className="property-popover-content">
                <Checkbox.Group
                  defaultValue={inputs.private_room}
                  options={roomOptions}
                  onChange={e => handleInputChange(e, "private_room")}
                />
              </div>
            }
            trigger="click"
          >
            <div className="property-type-collapse">
              <div className="text-input">
                Bedroom Type
                <div className="arrow">
                  <ArrowDownIcon />
                </div>
              </div>
            </div>
          </Popover>
        </Col>
        <Col span={3}>
          <Popover
            placement="bottomLeft"
            content={
              <div className="property-popover-content">
                <Checkbox.Group
                  defaultValue={inputs.private_bath}
                  options={roomOptions}
                  onChange={e => handleInputChange(e, "private_bath")}
                />
              </div>
            }
            trigger="click"
          >
            <div className="property-type-collapse">
              <div className="text-input">
                Bathroom Type
                <div className="arrow">
                  <ArrowDownIcon />
                </div>
              </div>
            </div>
          </Popover>
        </Col>
        <Col style={{ marginLeft: 10, width: "80px" }} span={2}>
          <Popover
            placement="bottomLeft"
            content={
              <div className="more-popover-content">
                <div>
                  <div className="more-type-title">
                    <span>Laundry</span>
                  </div>
                  <div>
                    <Checkbox.Group
                      defaultValue={inputs.laundry}
                      onChange={e => handleInputChange(e, "laundry")}
                    >
                      <Row>
                        <Col span={12}>
                          <Checkbox value="0">No Laundry</Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox value="1">In Unit</Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox value="2">Hookups</Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox value="3">On Building</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </div>
                </div>
                <div>
                  <div
                    style={{ marginTop: "17px" }}
                    className="more-type-title"
                  >
                    <span>Amenities</span>
                  </div>
                  <div>
                    <div>
                      <span>
                        <Checkbox
                          checked={
                            inputs.wheelchair === 1 || inputs.wheelchair === "1"
                          }
                          onChange={e =>
                            handleInputChange(
                              e.target.checked ? 1 : undefined,
                              "wheelchair"
                            )
                          }
                        >
                          Accessibility
                        </Checkbox>
                      </span>
                      <span className="right-checkbox">
                        <Checkbox
                          checked={
                            inputs.smoking === 1 || inputs.smoking === "1"
                          }
                          onChange={e =>
                            handleInputChange(
                              e.target.checked ? 1 : undefined,
                              "smoking"
                            )
                          }
                        >
                          Smoking
                        </Checkbox>
                      </span>
                    </div>
                    <div>
                      <span>
                        <Checkbox
                          checked={inputs.cats === 1 || inputs.cats === "1"}
                          onChange={e =>
                            handleInputChange(
                              e.target.checked ? 1 : undefined,
                              "cats"
                            )
                          }
                        >
                          Pet Friendly
                        </Checkbox>
                      </span>
                      <span className="right-checkbox">
                        <Checkbox
                          checked={
                            inputs.parking === 1 ||
                            inputs.parking === "1" ||
                            inputs.parking === 2 ||
                            inputs.parking === "2"
                          }
                          onChange={e =>
                            handleInputChange(
                              e.target.checked ? 1 : undefined,
                              "parking"
                            )
                          }
                        >
                          Parking
                        </Checkbox>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            }
            trigger="click"
          >
            <div className="property-type-collapse">
              <div className="text-input">
                More
                <div className="arrow">
                  <ArrowDownIcon />
                </div>
              </div>
            </div>
          </Popover>
        </Col>
        <Col style={{ marginLeft: 10 }} span={2}>
          <button onClick={handleSubmit} className="search-btn">
            <span>Search</span>
          </button>
        </Col>
        <Col span={2}>
          <button onClick={handleEstimateBtn} className="btn-estimate-rent">
            <span>Estimate Rent</span>
          </button>
        </Col>
      </Row>
    </div>
  );
};
export default TopInput;
