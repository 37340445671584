import React, { FunctionComponent } from "react";
import Table from "antd/es/table";
import { PredictRangeProps } from "../Types";
import Graph from "./Graph";

interface Props {
  predictRanges: PredictRangeProps[];
}

const columns = [
  {
    title: "Month",
    dataIndex: "month",
    key: "month"
  },
  {
    title: "Rent Estimate",
    dataIndex: "rentEstimate",
    align: "center" as any,
    key: "rentEstimate"
  },
  {
    title: "High",
    dataIndex: "highest",
    align: "center" as any,
    key: "highest"
  },
  {
    title: "Low",
    dataIndex: "lowest",
    align: "center" as any,
    key: "lowest"
  },
  {
    title: "% change",
    dataIndex: "percentChange",
    key: "percentChange",
    align: "center" as any,
    render: (text, record) => (
      <button
        className={
          record.trend === "netral"
            ? "trend-netral-btn"
            : record.trend === "up"
            ? "trend-up-btn"
            : "trend-down-btn"
        }
      >
        {record.percentChange}
      </button>
    )
  }
];

const Component: FunctionComponent<Props> = props => {
  return (
    <div className="report-comparables-section bottom-line">
      <div>
        <span className="report-section-title" style={{ marginBottom: "0px" }}>
          Historical Graph
        </span>
        <span
          className="report-section-title"
          style={{ fontSize: "16px", marginTop: "0px" }}
        >
          {props.predictRanges[0].month} -{" "}
          {props.predictRanges[props.predictRanges.length - 1].month}
        </span>
      </div>
      <div className="graph-detail" style={{ marginBottom: "90px" }}>
        <Graph predictRanges={props.predictRanges} />
      </div>
      <div className="table-detail">
        <Table
          bordered={true}
          pagination={false}
          columns={columns}
          dataSource={props.predictRanges}
        />
      </div>
    </div>
  );
};

export default Component;
