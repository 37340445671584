import React, { FunctionComponent, useEffect, useRef } from "react";
import Chart from "chart.js";
import { PredictRangeProps } from "../Types";
import { numberToCurrency } from "../../../../utilities";

interface Props {
  predictRanges: PredictRangeProps[];
}

const Component: FunctionComponent<Props> = props => {
  const lineChartRef = useRef(null);

  useEffect(() => {
    if (lineChartRef.current) {
      const context: any = lineChartRef.current;
      if (context) {
        let myLineChart = new Chart(context.getContext("2d"), {
          type: "line",
          data: {
            labels: props.predictRanges.map(a => a.xLabel),
            datasets: [
              {
                fill: "none",
                backgroundColor: "#66B015",
                borderColor: "#66B015",
                data: props.predictRanges.map(a => a.price)
              }
            ]
          },
          options: {
            scales: {
              yAxes: [
                {
                  position: "right",
                  ticks: {
                    callback: function(value, index, values) {
                      return numberToCurrency(
                        value.toString(),
                        props.predictRanges[0].currencyCode
                      );
                    }
                  }
                }
              ]
            },
            legend: {
              display: false
            }
          }
        });
      }
    }
  });

  return (
    <div>
      <canvas ref={lineChartRef}></canvas>
    </div>
  );
};

export default Component;
