import React, { FunctionComponent, ChangeEvent } from "react";
import styled from "styled-components";
import MaterialIcon from "@material/react-material-icon";

type TextInputType = {
  value?: string | number;
  style?: React.CSSProperties;
  className?: string;
  placeholder?: string;
  type?: string;
  min?: number;
  hasIcon?: boolean;
  step?: number;
  variant?: "small" | "large" | undefined;
  onChange?: (e: any) => void;
};

const Input = React.memo(styled.input<TextInputType>`
  height: 44px;
  border-radius: 9px !important;
  -webkit-appearance: none;
  border: solid 1px #979797;
  // padding: 0px 30px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  font-family: AvenirNext;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  color: #4a4a4a;

  &::placeholder {
    color: #7d7d7d;
  }

  ${props => {
    if (props.variant == "small")
      return `
        height: 31px;
        font-size: 14px;
        // padding: 0px 20px;

        &::placeholder {
          font-size: 14px;
        }
      `;
  }}
`);

const Icon = React.memo(styled.div`
  width: 32px;
  height: 23px;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  justify-content: left;
  text-align: left;
  user-select: none;

  & > i {
    align-self: center;
    font-size: 32px;
    position: relative;
    left: -4px;
  }
`);

class TextInput extends React.Component<TextInputType, {}> {
  shouldComponentUpdate(nextProps: TextInputType) {
    // If they are different, trigger a re-render.
    if (this.props.value != nextProps.value) return true;
    // Do NOT re-render
    return false;
  }

  render() {
    const {
      style = {},
      className,
      placeholder,
      type,
      min,
      hasIcon = false,
      step,
      value,
      variant,
      ...rest
    } = this.props;

    return (
      <div className="position-relative w-100" style={style}>
        <Input
          value={value}
          style={style}
          className={className}
          placeholder={placeholder}
          type={type}
          min={min}
          step={step}
          variant={variant}
          {...rest}
        />

        {hasIcon && (
          <Icon>
            <MaterialIcon icon="arrow_drop_down" />
          </Icon>
        )}
      </div>
    );
  }
}

export default TextInput;
