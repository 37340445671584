import React, { useState, useEffect } from "react";

const POSITION_ERROR = {
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3
};

function usePosition() {
  const [position, setPosition] = useState<Position | null>(null);
  const [positionError, setPositionError] = useState<PositionError>();

  function updatePosition(pos: Position) {
    setPosition(pos);
  }

  function updateError(error: PositionError) {
    setPositionError(error);
  }

  // First check if the navigator object exists.
  // This is because there could be issues with IE.
  if (!window.navigator.geolocation) {
    const error: PositionError = {
      ...POSITION_ERROR, // Error info required
      code: 2,
      message: "Location is not supported by the browser"
    };
    setPositionError(error);
  }

  useEffect(() => {
    const watchId = window.navigator.geolocation.watchPosition(
      updatePosition,
      updateError
    );

    return () => window.navigator.geolocation.clearWatch(watchId);
  }, []);

  // Expose the information about the position and any error.
  // We do not expose the "setState" function of these vars.
  return { position, positionError };
}

export default usePosition;
