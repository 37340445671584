import React from "react";

const Logo: React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = "#414850", width = "26", height = "24" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 26 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6682 0.75186C24.9721 0.624145 25.3276 0.696199 25.5526 0.933711C25.7776 1.17198 25.8241 1.52272 25.6686 1.80865L13.9758 23.285C13.8383 23.5385 13.5695 23.6895 13.2856 23.6895C13.2231 23.6895 13.1598 23.6818 13.0965 23.667C12.7485 23.5823 12.5043 23.277 12.5043 22.927V12.1459L1.54038 12.1349C1.17046 12.1341 0.851323 11.881 0.775933 11.5272C0.701325 11.173 0.890385 10.8169 1.23062 10.6732L24.6682 0.75186ZM14.0672 11.3842V19.8675L23.2409 3.01794L5.29698 10.6137L13.2863 10.6217C13.7176 10.6225 14.0672 10.9633 14.0672 11.3842Z"
      fill="#66B015"
    />
  </svg>
);

export default Logo;
