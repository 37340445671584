import React, { useState } from "react";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Form from "antd/es/form";
import CurrentLocationIcon from "../../icons/CurrentLocation";
import DotMarkerIcon from "../../icons/DotMarker";
import FilterToggle from "../Results/FilterToggle";
import { generateSharableQuery } from "../../../utilities";
import { useAppState } from "../../../store/app/state";
import "./error.css";

const { Option } = Select;

const BottomInput = props => {
  const { appState, dispatchAppState } = useAppState();
  const [showAdvanced, setShowAdvanced] = useState(false);

  const toggleClick = () => {
    setShowAdvanced(!showAdvanced);
  };

  const validateNumber = e => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      dispatchAppState({
        type: "setTargetPrice",
        payload: value
      });
    }
  };

  const predictRoom = () => {
    if (appState.address.length === 0) {
      alert("Missing the address field.");
    } else {
      const searchQuery = generateSharableQuery(appState);

      props.history.push({
        pathname: "/results",
        search: searchQuery
      });
    }
  };

  return (
    <div className="error-bottom-input">
      <div className="d-flex" style={{ marginBottom: "32px" }}>
        <div className="address-wrapper">
          <Input
            prefix={<DotMarkerIcon />}
            suffix={<CurrentLocationIcon />}
            placeholder="Enter Address or Zip Code"
            onChange={e =>
              dispatchAppState({ type: "setAddress", payload: e.target.value })
            }
            value={appState.address}
          />
        </div>
        <div className="toggle-input">
          <FilterToggle
            style={{ margin: "5px" }}
            toggled={showAdvanced}
            onClick={toggleClick}
          />
        </div>
      </div>
      <div className="predict-param-wrapper d-flex">
        <Form.Item colon={false} labelAlign="left" label="Property Type">
          <Select
            value={
              appState.propertyType ? appState.propertyType.toString() : "-1"
            }
            onChange={e =>
              dispatchAppState({ type: "setPropertyType", payload: e })
            }
          >
            <Option value={"-1"}>-</Option>
            <Option value={"0"}>Apartment</Option>
            <Option value={"1"}>House</Option>
          </Select>
        </Form.Item>
        <Form.Item colon={false} labelAlign="left" label="Bedroom">
          <Select
            value={
              appState.selectedRoom ? appState.selectedRoom.toString() : "-1"
            }
            onChange={e => dispatchAppState({ type: "setRoom", payload: e })}
          >
            <Option value={"-1"}>-</Option>
            <Option value={"0"}>Shared</Option>
            <Option value={"1"}>Private</Option>
          </Select>
        </Form.Item>
        <Form.Item colon={false} labelAlign="left" label="Bathroom">
          <Select
            value={
              appState.selectedBath ? appState.selectedBath.toString() : "-1"
            }
            onChange={e => dispatchAppState({ type: "setBath", payload: e })}
          >
            <Option value={"-1"}>-</Option>
            <Option value={"0"}>Shared</Option>
            <Option value={"1"}>Private</Option>
          </Select>
        </Form.Item>
        <Form.Item colon={false} labelAlign="left" label="Target Rent">
          <Input onChange={validateNumber} value={appState.targetPrice} />
        </Form.Item>
        <Form.Item colon={false} labelAlign="left" label="Laundry">
          <Select
            value={appState.laundry ? appState.laundry.toString() : "-1"}
            onChange={e =>
              dispatchAppState({
                type: "setLaundry",
                payload: e
              })
            }
          >
            <Option value={"-1"}>-</Option>
            <Option value={"0"}>No Laundry</Option>
            <Option value={"1"}>In Unit Laundry</Option>
            <Option value={"2"}>Laundry Hookups</Option>
            <Option value={"3"}>On Building Laundry</Option>
            <Option value={"4"}>On Site Laundry</Option>
          </Select>
        </Form.Item>
      </div>
      {showAdvanced ? (
        <div className="predict-param-wrapper d-flex">
          <Form.Item colon={false} labelAlign="left" label="Furnising">
            <Select
              value={
                appState.furnishing ? appState.furnishing.toString() : "-1"
              }
              onChange={e =>
                dispatchAppState({
                  type: "setFurnishing",
                  payload: e
                })
              }
            >
              <Option value={"-1"}>-</Option>
              <Option value={"0"}>Unfurnished</Option>
              <Option value={"1"}>Furnished</Option>
            </Select>
          </Form.Item>
          <Form.Item colon={false} labelAlign="left" label="Accessibility">
            <Select
              value={
                appState.accessibility
                  ? appState.accessibility.toString()
                  : "-1"
              }
              onChange={e =>
                dispatchAppState({
                  type: "setAccessibility",
                  payload: e
                })
              }
            >
              <Option value={"-1"}>-</Option>
              <Option value={"0"}>Not Accessible</Option>
              <Option value={"1"}>Accessible</Option>
            </Select>
          </Form.Item>
          <Form.Item colon={false} labelAlign="left" label="Smoking Policy">
            <Select
              value={
                appState.smokingPolicy
                  ? appState.smokingPolicy.toString()
                  : "-1"
              }
              onChange={e =>
                dispatchAppState({
                  type: "setSmokingPolicy",
                  payload: e
                })
              }
            >
              <Option value={"-1"}>-</Option>
              <Option value={"0"}>Non-Smoking</Option>
              <Option value={"1"}>Smoking</Option>
            </Select>
          </Form.Item>
          <Form.Item colon={false} labelAlign="left" label="Parking">
            <Select
              value={appState.parking ? appState.parking.toString() : "-1"}
              onChange={e =>
                dispatchAppState({
                  type: "setParking",
                  payload: e
                })
              }
            >
              <Option value={"-1"}>-</Option>
              <Option value={"0"}>No Parking</Option>
              <Option value={"1"}>Off-street Parking</Option>
              <Option value={"2"}>Street Parking</Option>
            </Select>
          </Form.Item>
          <Form.Item colon={false} labelAlign="left" label="Pet Policy">
            <Select
              value={appState.petPolicy ? appState.petPolicy.toString() : "-1"}
              onChange={e =>
                dispatchAppState({
                  type: "setPetPolicy",
                  payload: e
                })
              }
            >
              <Option value={"-1"}>-</Option>
              <Option value={"0"}>No Pets</Option>
              <Option value={"1"}>Pet Friendly</Option>
            </Select>
          </Form.Item>
        </div>
      ) : (
        ""
      )}
      <div className="predict-param-wrapper d-flex">
        <div className="search-wrapper">
          <span
            onClick={predictRoom}
            style={{ margin: "8px", display: "block" }}
          >
            Search
          </span>
        </div>
      </div>
    </div>
  );
};
export default BottomInput;
