// src/comparables-context.tsx
import React, { createContext, useState, useMemo, useContext } from "react";
import "./results.d";

const ResultsContext = createContext<ResultsContextValue | undefined>(
  undefined
);

function ResultsProvider(props: ResultsProviderProps) {
  const [results, setResults] = useState<Results>({});
  const value = useMemo(() => {
    return {
      results,
      setResults
    };
  }, [results]);

  return <ResultsContext.Provider value={value} {...props} />;
}

function useResults() {
  const context = useContext(ResultsContext);
  if (!context) {
    throw new Error("useResults must be used within a ResultsProvider");
  }
  return context;
}
export { ResultsProvider, useResults };
