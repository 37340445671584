import React from "react";

const Logo: React.FunctionComponent<{
  width?: string;
  height?: string;
}> = ({ width = "26px", height = "35px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 26 35"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#66B015">
        <path d="M13 34.037c8.176-10.247 12.264-17.055 12.264-20.425 0-9.194-7.012-13.167-12.308-13.167C7.659.445.89 4.488.89 13.612c0 3.753 4.036 10.561 12.11 20.425z" />
        <path
          fillRule="nonzero"
          d="M13 0C5.82 0 0 5.746 0 12.833c0 6.897 12.032 21.344 12.544 21.955a.594.594 0 0 0 .912 0C13.968 34.177 26 19.73 26 12.833 26 5.746 20.18 0 13 0zm0 33.49C10.776 30.75 1.182 18.592 1.182 12.833 1.189 6.393 6.476 1.174 13 1.167c6.524.007 11.81 5.226 11.818 11.666 0 5.758-9.594 17.916-11.818 20.657z"
        />
      </g>
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#FFF"
        stroke-width=".682"
        d="M15.41 13.422c-.681-.368-1.406-.65-2.123-.94-.416-.17-.815-.366-1.166-.64-.692-.54-.56-1.418.251-1.766.23-.098.47-.13.714-.144a5.52 5.52 0 0 1 2.683.52c.424.2.564.137.707-.295.15-.456.276-.92.416-1.38.093-.309-.021-.512-.32-.642a6.933 6.933 0 0 0-1.692-.495c-.768-.116-.768-.12-.772-.875-.003-1.063-.003-1.063-1.094-1.063-.158 0-.316-.004-.473 0-.51.014-.596.101-.61.603-.007.225 0 .45-.004.678-.003.667-.007.657-.66.888-1.578.562-2.554 1.615-2.658 3.3-.093 1.493.703 2.5 1.955 3.234.771.453 1.625.72 2.443 1.075.32.137.624.295.89.512.785.636.642 1.693-.29 2.093-.5.214-1.027.267-1.569.2-.835-.102-1.635-.316-2.389-.698-.441-.225-.57-.166-.72.301-.13.404-.245.812-.36 1.219-.154.548-.096.677.438.934.682.323 1.41.488 2.153.604.58.09.599.116.606.705.003.267.003.538.007.804.004.337.169.534.524.541.401.007.807.007 1.209-.003.33-.007.498-.183.498-.51 0-.365.018-.733.004-1.098-.018-.373.147-.562.513-.66a4.064 4.064 0 0 0 2.113-1.328c1.535-1.825.95-4.497-1.223-5.674z"
      />
    </g>
  </svg>
);

export default Logo;
