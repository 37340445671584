import React from "react";
import {
  fetchGeocoderMatch,
  fetchPredictionAndComparables,
  postSearch
} from "./api";
import { Coords } from "google-map-react";
import {
  transform as _transform,
  isEqual as _isEqual,
  isObject as _isObject
} from "lodash";
import { initialState } from "./store/app/reducer";
import { IPredictionData, PredictInput, GeocodeResult } from "./types/api";
const { fitBounds } = require("../node_modules/google-map-react/utils");
import { FiltersState, FiltersReducerAction } from "./store/app/state.d";
import ApartmentIcon from "./components/icons/Apartment";
import HouseIcon from "./components/icons/House";
import PrivateIcon from "./components/icons/Private";
import SharedIcon from "./components/icons/Shared";
import AvailableIcon from "./components/icons/Available";
import UnavailableIcon from "./components/icons/Unavailable";
import SpareroomIcon from "./components/icons/Spareroom";
import CraiglistIcon from "./components/icons/Craiglist";
import FacebookIcon from "./components/icons/Facebook";
import FacebookWhiteIcon from "./components/icons/FacebookWhite";
import TrademeWhiteIcon from "./components/icons/TrademeWhite";
import KijijiIcon from "./components/icons/Kijiji";
import VivanunciosIcon from "./components/icons/Vivanuncios";
import InfokostIcon from "./components/icons/Infokost";
import FlatmatesIcon from "./components/icons/Flatmates";
import TrademeIcon from "./components/icons/Trademe";
import OzflatmatesIcon from "./components/icons/Ozflatmates";
import OzflatmatesWhiteIcon from "./components/icons/OzflatmatesWhite";
import C99WhiteIcon from "./assets/images/c99.svg";

var currencyCode: string;

const numberToCurrency = (num: string, code: string) => {
  var codeUsed = "";
  var separator = "";
  var symbol = "";

  if (code == "") {
    codeUsed = currencyCode;
    symbol = getSymbol(currencyCode);
    separator = getSeparator(currencyCode);
  } else {
    codeUsed = code;
    symbol = getSymbol(code);
    separator = getSeparator(code);
  }

  const format = parseFloat(num.replace(/,/g, ""))
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator);

  const thousand = getThousand(codeUsed);
  const newPrice = getShortenedNumber(format, separator, thousand);

  return `${symbol}${newPrice}`;
};

const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      a => {
        resolve(a.coords);
      },
      a => {
        console.log(a);
        resolve(undefined);
      },
      {
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: Infinity
      }
    );
  });
};

const getCurrencyCode = () =>{
  return currencyCode;
}

const mapPredictionData = (
  data: FiltersState & { latitude: number; longitude: number }
) => {
  const mappedParams = {
    address: data.address,
    currency_code: data.currencySymbol,
    client_platform: 0,
    longitude: data.longitude,
    latitude: data.latitude,
    sqft: data.sqft,
    private_room: data.selectedRoom,
    private_bath: data.selectedBath,
    house_type: data.propertyType,
    laundry: data.laundry,
    parking: data.parking,
    cats: data.petPolicy,
    dogs: data.petPolicy,
    furnished: data.furnishing,
    smoking: data.smokingPolicy,
    wheelchair: data.accessibility
  };

  Object.keys(mappedParams).forEach(k => {
    const val = mappedParams[k];

    if (!val || val == "-1") {
      if (k == "sqft") mappedParams[k] = 1;
      else mappedParams[k] = "-1";
    }
  });

  return mappedParams;
};

const reverseTypeMapping = (val: IPredictionData["house_type"]) => {
  switch (val) {
    case 0:
      return "Apartment";
    case 1:
      return "House";
    default:
      return "Unknown";
  }
};

const reverseParkingMapping = (val: IPredictionData["parking"]) => {
  switch (val) {
    case 0:
      return "No Parking";
    case 1:
      return "Off-street Parking";
    case 2:
      return "Street Parking";
    default:
      return "Unknown";
  }
};

const reverseLaundryMapping = (val: number) => {
  switch (val) {
    case 0:
      return "No Laundry";
      break;
    case 1:
      return "In Unit Laundry";
      break;
    case 2:
      return "Laundry Hookups";
      break;
    case 3:
      return "On Building Laundry";
      break;
    case 4:
      return "On Site Laundry";
      break;
    default:
      return "Unknown";
      break;
  }
};

const reverseMapping = (comparable: any) => {
  return {
    address: comparable.address,
    latitude: comparable.latitude,
    longitude: comparable.longitude,
    type: reverseTypeMapping(comparable.house_type),
    room: comparable.private_room === 1 ? "Private" : "Shared",
    bath: comparable.private_bath === 1 ? "Private" : "Shared",
    furnished: comparable.furnished == 1 ? "Furnished" : "Unfurnished",
    pet_friendly: comparable.pet_friendly === 1 ? "Pet Friendly" : "No Pets",
    laundry: reverseLaundryMapping(comparable.laundry),
    currency: comparable.currency_code,
    distance: comparable.distance,
    price: numberToCurrency(String(comparable.price), comparable.currency_code),
    parking: reverseParkingMapping(comparable.parking),
    wheelchair: comparable.wheelchair === 1 ? "Accessible" : "Not Accessible",
    smoking: comparable.smoking === 1 ? "Smoking" : "No Smoking",
    href: comparable.href,
    title: comparable.title,
    run_id: comparable.run_id,
    source: comparable.source
  };
};

function getShortenedNumber(price, separator, thousand) {
  if (price.length <= 5) {
    return price;
  }

  const priceArr = price.split(separator);
  var resultString = "";

  for (let i = 0; i <= priceArr.length - 2; i++) {
    resultString += priceArr[i];

    if (i !== priceArr.length - 2) {
      resultString += separator;
    }
  }

  return resultString + thousand;
}

function getSeparator(code) {
  var sep = "";

  switch (code) {
    case "IDR":
      sep = ".";
      break;
    case "USD":
      sep = ",";
      break;
    case "MXN":
      sep = ",";
      break;
    default:
      sep = ",";
      break;
  }

  return sep;
}

function getSymbol(currency) {
  var symbol = "";

  switch (currency) {
    case "USD":
      symbol = "$";
      break;
    case "IDR":
      symbol = "Rp.";
      break;
    case "MXN":
      symbol = "$";
      break;
    case "GBP":
      symbol = "£";
      break;
    case "INR": 
      symbol = "₹";
      break;
    default:
      symbol = "$";
      break;
  }

  return symbol;
}

function getThousand(currency) {
  var thousand = "";

  switch (currency) {
    case "USD":
      thousand = "k";
      break;
    case "IDR":
      thousand = "k";
      break;
    case "MXN":
      thousand = " mil";
      break;
    default:
      thousand = "k";
      break;
  }

  return thousand;
}

// Serializes an object into a URL query string.
function serializeObject(obj: object) {
  return Object.entries(obj)
    .map(
      ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
    )
    .join("&");
}

// Converts a url query string to an object.
function searchParamsToObject(search: string = "") {
  return search
    .slice(1)
    .split("&")
    .map(p => p.split("="))
    .reduce((obj, [key, value]) => {
      let val: string | null | undefined = decodeURIComponent(value);

      if (value === "null") {
        // val = null;
      } else if (value === "undefined") {
        val = "-1";
      }

      return { ...obj, [key]: val };
    }, {});
}

// Verify that all parameters from url query are valid.
function verifyParams() {
  // TODO...
}

// Calls the Prediction and Comparables API; if given a valid input data.
async function callPredictionAndComparables(
  predictionInputData: FiltersState & {
    name: any;
    latitude: any;
    longitude: any;
  }
) {
  const { latitude, longitude } = predictionInputData;
  if (!latitude || !longitude) throw "Error: Missing coordinates.";

  const mappedParams: PredictInput = mapPredictionData(predictionInputData);
  const response = await fetchPredictionAndComparables(mappedParams);

  if (response.status === 200) {
    const data = response.data;
    const [{ comparables, currency, prediction }] = data;
    const flattenedComparables = Object.values(comparables);

    var similarToMappedParams = mappedParams;
    similarToMappedParams.currency_code = currency.currency_code;
    similarToMappedParams.address = predictionInputData.name;
    // Asynchronously try to insert the search and results to the DB
    try {
      // We do not wait for the insertion to finish since we prioritize the UX
      postSearch({
        searchParams: similarToMappedParams,
        prediction,
        comparables
      });
    } catch (error) {
      console.error("Could not save search");
      console.error(error);
    }

    const predictionObject = {
      prediction: prediction,
      currency: currency,
      comparables: flattenedComparables
    };
    return { statusCode: response.status, predictionObject };
  } else {
    return { statusCode: response.status, predictionObject: null };
  }
}

// Handles the execution of the Prediction. This includes:
// 1) Parsing State and Props into objects that can be consumed by the API.
// 2) Calling the reverse geocoder to get corrdinates from address.
// 3) Calling API to get prediction and comparables.
// 4) Updating app state and router.

type IPredictionCallProps = {
  state: FiltersState;
  dispatch: (value: FiltersReducerAction) => void;
  setResults: (
    value: React.SetStateAction<{
      maximum: number;
      minimum: number;
      price: number;
    }>
  ) => void;
  setComparables: (value: any) => void;
  history: any;
};

function isNumeric(val) {
  return !isNaN(val);
}

async function handlePredictionExecution(props: IPredictionCallProps) {
  let match: GeocodeResult | null = null;

  // Check if the "address" are really coordinates
  const { address } = props.state;
  if (props.state.address) {
    const components = address.split(",");

    if (components.length === 2) {
      const [lat, lng] = components;

      if (isNumeric(lat) && isNumeric(lng)) {
        // They are coordinates, so create the match object that will be used in predict API
        match = {
          coordinates: { lat, lng },
          display_name: address
        };
      }
    }
  }

  // Check if match was not obtained already by the browser's location
  if (!match) {
    // Use the geocoder to get the locations from the address.
    const apiResults = await fetchGeocoderMatch(props.state.address);
    match = apiResults[0];
  }

  if (match) {
    // Save the geocoder result in the app state.
    const coords = { lat: match.coordinates.lat, lng: match.coordinates.lng };
    const nameDisplayed = match.display_name;
    props.dispatch({ type: "setCoords", payload: coords });

    // Build the Prediction API input data.
    const predictionInputData = {
      ...props.state,
      latitude: coords.lat,
      longitude: coords.lng,
      name: nameDisplayed
    };

    // Execute "callPrediction" to get the Prediction and Comparables.
    const { statusCode, predictionObject } = await callPredictionAndComparables(
      predictionInputData
    );

    if (statusCode !== 200 || !predictionObject) {
      props.history.push({
        pathname: "/error",
        state: { errorCode: statusCode }
      });
      return;
    } else {
      // Save responses in App state.
      currencyCode = predictionObject.currency.currency_code;
      props.setResults(predictionObject.prediction);
      props.setComparables(predictionObject.comparables);

      // And redirect to the Results page with the serialized params.
      const newState = {
        ...predictionInputData,
        price: predictionObject.prediction.price
      };
      const searchQuery = generateSharableQuery(newState);
      props.history.push({
        pathname: "/results",
        search: searchQuery
      });
    }
  } else {
    throw "No matching location was found. Please check the spelling of the address and try again.";
  }
}

const compareCoordinates = (coords1: Coords, coords2: Coords) =>
  coords1.lat == coords2.lat && coords1.lng == coords2.lng;

// Given an array of [lat, lng] and the size of the map container, this function
// calculates the required center and zoom values to show all the markers on the map.
const calculateMapBounds = (
  coordsArray: number[][],
  size: {
    width: number;
    height: number;
  }
) => {
  const latArray = coordsArray.map(([lat]) => lat);
  const lngArray = coordsArray.map(([, lng]) => lng);
  // Fallback "bounds" coordinates.
  let bounds = {
    nw: { lat: 39.8283, lng: -98.5795 },
    se: { lat: 39.8283, lng: -98.5795 }
  };

  if (latArray.length != 0 || lngArray.length != 0) {
    // Calculate north-west coordinate.
    const nwLat = Math.max(...latArray);
    const nwLng = Math.min(...lngArray);

    // Calculate southeast coordinate
    const seLat = Math.min(...latArray);
    const seLng = Math.max(...lngArray);

    // Build the bounds object with those 2 viewport boundaries.
    bounds = {
      nw: { lat: nwLat, lng: nwLng },
      se: { lat: seLat, lng: seLng }
    };
  }

  // Check if the nw and se coords are equal.
  // If they are equal, they cause the fitBounds function to give random results
  if (compareCoordinates(bounds.nw, bounds.se)) {
    return { center: bounds.nw, zoom: 13 };
  } else {
    // Call the "fitBounds" function to get the center and zoom needed.
    const { center, zoom } = fitBounds(bounds, size);
    return { center, zoom };
  }
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function _difference(object: object, base: object) {
  function changes(object: any, base: any) {
    return _transform(object, (result, value, key) => {
      if (!_isEqual(value, base[key])) {
        result[key] =
          _isObject(value) && _isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

const generateSharableQuery = (newState: any) => {
  // Remove some fields that we don't want to serialize.
  const { coords, latitude, longitude, price, ...cleanedState } = newState;

  // Get the diff. with respect to the initial state.
  const diff = _difference(cleanedState, initialState);

  // Serialize and stringify the difference and return it.
  return serializeObject(diff);
};

const getBedIcon = (bedroomType: string) => {
  if (bedroomType === "Private") {
    return <PrivateIcon />;
  } else {
    return <SharedIcon />;
  }
};

const getIsFurnishedIcon = (furnishing: string) => {
  if (furnishing === "Furnished") {
    return <AvailableIcon />;
  } else {
    return <UnavailableIcon />;
  }
};

const getIsAvailableIcon = (input: string) => {
  if (input.includes("No") || input.includes("Not")) {
    return <UnavailableIcon />;
  } else {
    return <AvailableIcon />;
  }
};

const getPropertyIcon = (propertyType: string) => {
  if (propertyType === "Apartment") {
    return <ApartmentIcon />;
  } else {
    return <HouseIcon />;
  }
};

const getDistance = (currency_code: string, distance: any) => {
  if (currency_code === "USD") {
    // let miles = distance / 1.609344;
    return `${distance.toFixed(2)} miles`;
  } else {
    return `${distance.toFixed(2)} km`;
  }
};

const getSourceIcon = (source: string, isWhite: boolean = false) => {
  if (source === "facebook") {
    return isWhite ? <FacebookWhiteIcon /> : <FacebookIcon />;
  } else if (source === "spareroom") {
    return isWhite ? <SpareroomIcon color={"white"} /> : <SpareroomIcon />;
  } else if (source === "craigslist") {
    return isWhite ? <CraiglistIcon color={"white"} /> : <CraiglistIcon />;
  } else if (source === "vivanuncios") {
    return isWhite ? <VivanunciosIcon color={"white"} /> : <VivanunciosIcon />;
  } else if (source === "infokost") {
    return isWhite ? <InfokostIcon color={"white"} /> : <InfokostIcon />;
  } else if (source === "trademe") {
    return isWhite ? <TrademeWhiteIcon /> : <TrademeIcon />;
  } else if (source === "flatmates") {
    return isWhite ? <FlatmatesIcon color={"white"} /> : <FlatmatesIcon />;
  } else if (source === "c99") {
    <img src={C99WhiteIcon} width="20px" height="20px" />;
  } else if (source === "ozflatmates") {
    return isWhite ? <OzflatmatesWhiteIcon /> : <OzflatmatesIcon />;
  } else if (source === "kijiji") {
    return isWhite ? <KijijiIcon color={"white"} /> : <KijijiIcon />;
  } else if (source === "mamikos") {
  }
};

export {
  numberToCurrency,
  getCurrentPosition,
  getSeparator,
  mapPredictionData,
  reverseMapping,
  searchParamsToObject,
  verifyParams,
  handlePredictionExecution,
  calculateMapBounds,
  generateSharableQuery,
  getSymbol,
  getDistance,
  getPropertyIcon,
  getBedIcon,
  getIsFurnishedIcon,
  getIsAvailableIcon,
  getSourceIcon,
  getCurrencyCode
};
