import React, { FunctionComponent } from "react";

const Marker = props => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick(props);
    }
  };
  return (
    <div onClick={onClick} className="search-price-marker">
      <span>{props.price}</span>
    </div>
  );
};

export default Marker;
