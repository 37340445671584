import React, { useState, FunctionComponent, useEffect, useMemo } from "react";
import {
  Row,
  Col,
  Modal,
  ModalDialog,
  Form,
  Button,
  Alert
} from "react-bootstrap";
import RentalInfo from "../components/pages/Rentals/RentalInfo";
import styles from "../components/pages/Rentals/RentRegistry.style";
import { useUser } from "../store/user/context";
import { getRentals, putRental, delRentals, fetchGeocoderMatch } from "../api";
import SwitchButton from "../components/SwitchButton";
import { FiltersState } from "../store/app/state.d";
import { HomeProps } from "../components/pages/Home/Home";

const RentalsInfo: FunctionComponent<HomeProps> = props => {
  const [rental_forms, set_rental_forms] = useState([{}]);
  const { user } = useUser();
  const [user_type, set_user_type] = useState();
  const [validated, set_validated] = useState(false);

  const isLoggedIn = useMemo(() => !!user, [user]);
  let del_id,
    lrd_count = 0,
    tnt_count = 0;

  useEffect(() => {
    if (user && user.id) {
      handleGetRentals(user.id);
    }
  }, [user]);

  // Merges the rental object in the index with this new data.
  const updateForm = (index: number, payload: {}) => {
    rental_forms[index] = { ...rental_forms[index], ...payload };
    set_rental_forms([...rental_forms]);
    console.log(rental_forms);
  };

  async function handleGetRentals(user_id: number) {
    // API call goes here.
    try {
      set_rental_forms([]);
      const response = await getRentals(user_id);
      response.data.forEach((rental, i) => {
        lrd_count = rental.type == 0 ? lrd_count + 1 : lrd_count;
        tnt_count = rental.type == 1 ? tnt_count + 1 : tnt_count;
        rental_forms[i] = rental;
        const new_forms = [...rental_forms];
        set_rental_forms(new_forms);
        set_user_type("Landlord");
      });
      if (lrd_count == 0) {
        document.getElementById("empty_lrd_mssg")!.style.display = "block";
        document.getElementById("add_room_btn")!.style.cssFloat = "unset";
      }
      if (tnt_count == 0) {
        document.getElementById("empty_tnt_mssg")!.style.display = "block";
        document.getElementById("add_room_btn")!.style.cssFloat = "unset";
      }
      if (tnt_count == 0 && lrd_count == 0) {
        document.getElementById("empty_lrd_mssg")!.style.display = "none";
        document.getElementById("empty_tnt_mssg")!.style.display = "none";
        document.getElementById("empty_none_mssg")!.style.display = "block";
        document.getElementById("rental_container")!.style.display = "none";
      }
      console.log(rental_forms);
    } catch (error) {
      console.error(error);
      document.getElementById("alert_error")!.style.display = "block";
    }
  }

  async function handleDelRental() {
    // API call goes here.
    try {
      const response = await delRentals(del_id);
      handleDismissAlert("del_ok");
      document.getElementById("rental-" + del_id)!.style.display = "none";
      console.log(del_id);
    } catch (error) {
      console.error(error);
      document.getElementById("alert_error")!.style.display = "block";
    }
  }

  async function handleFormSubmission(event, rental: {}) {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === true) {
      // Start loading spinner here
      // pleaseWait(true)

      // Check if the user is logged in.
      if (isLoggedIn) {
        const address_data = await fetchGeocoderMatch(rental["address"]!);
        const [firstAddress] = address_data;

        let [lat, lng]: number[] | string[] = [0, 0];
        if (firstAddress) {
          [lat, lng] = [
            firstAddress.coordinates.lat,
            firstAddress.coordinates.lng
          ];
        }
        rental["lat"] = lat;
        rental["lng"] = lng;

        // API call goes here.
        try {
          const response = await putRental(rental);
          handleCancelRequest(rental["id"]);
          let url =
            process.env.NODE_ENV === "development"
              ? "localhost:3000"
              : "https://www.rentalios.com";
          window.location.href = url + "/rentals_info";
        } catch (error) {
          console.error(error);
          document.getElementById("alert_error")!.style.display = "block";
        }
      } else {
        alert("You need to be logged in to do this.");
      }

      // Stop loading spinner here
      // pleaseWait(false)
    } else {
      set_validated(true);
      console.log(validated);
    }
  }

  function handleDelRequest(id: number) {
    del_id = id;
    document.getElementById("del_ok")!.style.display = "block";
  }

  function handleEditRequest(id: number) {
    document.getElementById(id + "-info")!.style.display = "none";
    document.getElementById(id + "-edit")!.style.display = "block";
    document.getElementById(id + "-edit-btn")!.style.display = "none";
    document.getElementById(id + "-save-btn")!.style.display = "block";
    document.getElementById(id + "-cancel-btn")!.style.display = "block";
    document.getElementById(id + "-del-btn")!.style.display = "block";
  }

  function handleCancelRequest(id: number) {
    document.getElementById(id + "-info")!.style.display = "block";
    document.getElementById(id + "-edit")!.style.display = "none";
    document.getElementById(id + "-edit-btn")!.style.display = "block";
    document.getElementById(id + "-save-btn")!.style.display = "none";
    document.getElementById(id + "-cancel-btn")!.style.display = "none";
    document.getElementById(id + "-del-btn")!.style.display = "none";
  }

  function handleDismissAlert(alert_id) {
    document.getElementById(alert_id)!.style.display = "none";
  }

  function switchTabs(payload: FiltersState["userType"]) {
    if (payload == "Estimate Rent") {
      set_user_type(payload);
      document.getElementById("tenant_r")!.style.display = "block";
      document.getElementById("landlord_r")!.style.display = "none";
      if (document.getElementById("empty_tnt_mssg")!.style.display == "block") {
        document.getElementById("add_room_btn")!.style.cssFloat = "unset";
      } else {
        document.getElementById("add_room_btn")!.style.cssFloat = "right";
      }
    } else {
      set_user_type(payload);
      document.getElementById("tenant_r")!.style.display = "none";
      document.getElementById("landlord_r")!.style.display = "block";
      if (document.getElementById("empty_lrd_mssg")!.style.display == "block") {
        document.getElementById("add_room_btn")!.style.cssFloat = "unset";
      } else {
        document.getElementById("add_room_btn")!.style.cssFloat = "right";
      }
    }
  }

  let edit_rental = (
    <Col
      md={{ span: 8, offset: 2 }}
      style={styles.edit_rental}
      id="edit_rental"
    >
      <ModalDialog>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "center", width: "100%" }}>
            Edit rental
          </Modal.Title>
        </Modal.Header>
      </ModalDialog>
    </Col>
  );

  let alert_error = (
    <Col
      md={{ span: 6, offset: 3 }}
      style={styles.alert_error}
      id="alert_error"
    >
      <Alert
        variant="danger"
        onClose={() => {
          handleDismissAlert("alert_error");
        }}
        dismissible
      >
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <p>
          Something went wrong while processing the information. Please try
          again in a few minutes, if the error perisists, please contact support
          for more information.
        </p>
      </Alert>
    </Col>
  );

  let delete_ok = (
    <Col md={{ span: 4, offset: 4 }} style={styles.alert_error} id="del_ok">
      <ModalDialog style={{ boxShadow: "0 0 20px 0 #7b7b7b" }}>
        <Modal.Header style={{ display: "block" }}>
          <div style={{ textAlign: "center", paddingBottom: "20px" }}>
            <b>
              Are you sure you want to
              <br />
              delete this address?
            </b>
          </div>
          <Row>
            <Col md={{ span: 6 }}>
              <Button
                variant="secondary"
                style={{ float: "right", width: "90%" }}
                onClick={() => {
                  handleDismissAlert("del_ok");
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col md={{ span: 6 }}>
              <Button
                variant="danger"
                style={{ float: "left", width: "90%" }}
                onClick={() => {
                  handleDelRental();
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal.Header>
      </ModalDialog>
    </Col>
  );

  let empty_list_lrd = (
    <div style={{ display: "none", margin: "40px 0px" }} id="empty_lrd_mssg">
      <img
        src="https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5cf96f86995e8c01e088b9b6_closed-door-with-border-silhouette.svg"
        width="40px"
        height="40px"
        style={{ display: "block", margin: "auto", marginBottom: "20px" }}
      />
      Enter at least one of the rooms you are currently renting or have rented
      in the past as a <br />
      Landlord or Tenant to get the most benefit from Rentalios.com
    </div>
  );

  let empty_list_tnt = (
    <div style={{ display: "none", margin: "40px 0px" }} id="empty_tnt_mssg">
      <img
        src="https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5cf96f86995e8c01e088b9b6_closed-door-with-border-silhouette.svg"
        width="40px"
        height="40px"
        style={{ display: "block", margin: "auto", marginBottom: "20px" }}
      />
      Enter at least one of the rooms you are currently renting or have rented
      in the past as a <br />
      Landlord or Tenant to get the most benefit from Rentalios.com
    </div>
  );

  let empty_list_none = (
    <div
      style={{ display: "none", margin: "40px 0px 0px 0px" }}
      id="empty_none_mssg"
    >
      <img
        src="https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5cf96f86995e8c01e088b9b6_closed-door-with-border-silhouette.svg"
        width="40px"
        height="40px"
        style={{ display: "block", margin: "auto", marginBottom: "20px" }}
      />
      Enter at least one of the rooms you are currently renting or have rented
      in the past as a <br />
      Landlord or Tenant to get the most benefit from Rentalios.com
      <br />
      <br />
      <br />
      <br />
      <a style={styles.skip_button} href="/rentals_registry">
        <Button id={"add_room_btn"} style={styles.add_more_rents}>
          + Add a room
        </Button>
      </a>
    </div>
  );

  return (
    <div>
      <div>
        <ModalDialog
          id="user_rent"
          className="container"
          size="lg"
          style={{ margin: "0% auto", padding: "50px 0px" }}
        >
          <Col style={styles.choice_modal}>
            <div style={styles.login_title}>My Rooms</div>
            {empty_list_none}
            <div id={"rental_container"}>
              <SwitchButton
                options={["Estimate Rent", "Search Rooms"]}
                selected={user_type}
                clickHandler={(payload: FiltersState["userType"]) =>
                  switchTabs(payload)
                }
              />
              <div>
                <div id="landlord_r">
                  {empty_list_lrd}
                  {rental_forms.map((rental, index) => {
                    if (!rental["type"]) {
                      return (
                        <Form
                          id={"rental-" + rental["id"]}
                          noValidate
                          onSubmit={e => handleFormSubmission(e, rental)}
                          validated={validated}
                        >
                          <div key={index}>
                            <RentalInfo
                              data={rental}
                              handleDataChange={payload =>
                                updateForm(index, payload)
                              }
                              handleDelRequest={payload =>
                                handleDelRequest(payload)
                              }
                              handleEditRequest={payload =>
                                handleEditRequest(payload)
                              }
                              handleCancelRequest={payload =>
                                handleCancelRequest(payload)
                              }
                            />
                          </div>
                        </Form>
                      );
                    }
                  })}
                </div>

                <div id="tenant_r" style={{ display: "none" }}>
                  {empty_list_tnt}
                  {rental_forms.map((rental, index) => {
                    if (rental["type"])
                      return (
                        <Form
                          id={"rental-" + rental["id"]}
                          noValidate
                          onSubmit={e => handleFormSubmission(e, rental)}
                          validated={validated}
                        >
                          <div key={index}>
                            <RentalInfo
                              data={rental}
                              handleDataChange={payload =>
                                updateForm(index, payload)
                              }
                              handleDelRequest={payload =>
                                handleDelRequest(payload)
                              }
                              handleEditRequest={payload =>
                                handleEditRequest(payload)
                              }
                              handleCancelRequest={payload =>
                                handleCancelRequest(payload)
                              }
                            />
                          </div>
                        </Form>
                      );
                  })}
                </div>

                <div id="tenant_r" style={{ display: "none" }}>
                  {empty_list_tnt}
                  {rental_forms.map((rental, index) => {
                    if (rental["type"])
                      return (
                        <Form
                          id={"rental-" + rental["id"]}
                          noValidate
                          onSubmit={e => handleFormSubmission(e, rental)}
                          validated={validated}
                        >
                          <div key={index}>
                            <RentalInfo
                              data={rental}
                              handleDataChange={payload =>
                                updateForm(index, payload)
                              }
                              handleDelRequest={payload =>
                                handleDelRequest(payload)
                              }
                              handleEditRequest={payload =>
                                handleEditRequest(payload)
                              }
                              handleCancelRequest={payload =>
                                handleCancelRequest(payload)
                              }
                            />
                          </div>
                        </Form>
                      );
                  })}
                </div>

                <a style={styles.skip_button} href="/rentals_registry">
                  <Button id={"add_room_btn"} style={styles.add_more_rents}>
                    + Add a room
                  </Button>
                </a>
              </div>
            </div>
          </Col>
        </ModalDialog>
      </div>
      {edit_rental}
      {alert_error}
      {delete_ok}
    </div>
  );
};

export default RentalsInfo;
