import React, { FunctionComponent, useState } from "react";
import Input from "antd/es/input";
import Select from "antd/es/select";
import CurrentLocationIcon from "../../icons/CurrentLocation";
import LocationArrowIcon from "../../icons/Pin";
import { SearchProps } from "./Types";
import Form from "antd/es/form";
import { getCurrentPosition } from "../../../utilities";

const { Option } = Select;

interface Props {
  inputs: SearchProps;
  setReportValue: Function;
}

const TopInput: FunctionComponent<Props> = props => {
  const [inputs, setInputs] = useState(props.inputs);

  const handleInputChange = (value: any, field: string) => {
    setInputs(inputs => ({
      ...inputs,
      [field]: value
    }));
  };

  const handleGetLocation = async () => {
    let location: any = await getCurrentPosition();
    let addressPosition = `${location.latitude},${location.longitude}`;
    handleInputChange(addressPosition, "address");
  };

  const suffix = (
    <span onClick={handleGetLocation} style={{ cursor: "pointer" }}>
      <CurrentLocationIcon />
    </span>
  );

  return (
    <div>
      <div className="top-input border-btm">
        <div className="address-input-wrapper">
          <Form.Item label="Address">
            <Input
              style={{ height: "30px", marginRight: "40px", marginTop: "3px" }}
              prefix={<LocationArrowIcon />}
              placeholder="Enter Address or Zip Code"
              onChange={e => handleInputChange(e.target.value, "address")}
              onBlur={props.setReportValue(inputs)}
              value={inputs.address}
            />
          </Form.Item>

          <Form.Item label="Property Type">
            <Select
              onBlur={props.setReportValue(inputs)}
              value={inputs.house_type}
              onChange={e => handleInputChange(e, "house_type")}
            >
              <Option value={0}>Apartment</Option>
              <Option value={1}>House</Option>
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className="top-input border-btm">
        <div className="room-title">
          <span>Amenities (Optional)</span>
        </div>
        <div className="amenities-input-wrapper">
          <Form.Item label="Laundry">
            <Select
              onBlur={props.setReportValue(inputs)}
              value={inputs.laundry}
              onChange={e => handleInputChange(e, "laundry")}
            >
              <Option value={-1}>-</Option>
              <Option value={0}>No Laundry</Option>
              <Option value={1}>In Unit Laundry</Option>
              <Option value={2}>Laundry Hookups</Option>
              <Option value={3}>On Building Laundry</Option>
              <Option value={4}>On Site Laundry</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Smoking Policy">
            <Select
              onBlur={props.setReportValue(inputs)}
              value={inputs.smoking}
              onChange={e => handleInputChange(e, "smoking")}
            >
              <Option value={-1}>-</Option>
              <Option value={0}>Non-Smoking</Option>
              <Option value={1}>Smoking</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Accessibility">
            <Select
              onBlur={props.setReportValue(inputs)}
              value={inputs.wheelchair}
              onChange={e => handleInputChange(e, "wheelchair")}
            >
              <Option value={-1}>-</Option>
              <Option value={0}>Not Accessible</Option>
              <Option value={1}>Accessible</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Pet Policy">
            <Select
              onBlur={props.setReportValue(inputs)}
              value={inputs.cats}
              onChange={e => handleInputChange(e, "cats")}
            >
              <Option value={-1}>-</Option>
              <Option value={0}>No Pets</Option>
              <Option value={1}>Pet Friendly</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Parking">
            <Select
              onBlur={props.setReportValue(inputs)}
              value={inputs.parking}
              onChange={e => handleInputChange(e, "parking")}
            >
              <Option value={-1}>-</Option>
              <Option value={0}>No Parking</Option>
              <Option value={1}>Off-street Parking</Option>
              <Option value={2}>Street Parking</Option>
            </Select>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};
export default TopInput;
