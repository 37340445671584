import React, { FunctionComponent } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import InputNumber from "antd/es/input-number";
import customHook from "../hook";
import Checkbox from "antd/es/checkbox";

interface SearchProps {
  page?: Number;
  address?: String;
  page_size?: Number;
  house_type?: string;
  private_room?: number;
  private_bath?: number;
  onSearchRoom: Function;
  laundry?: number;
  min_price?: number;
  max_price?: number;
  wheelchair?: number;
  smoking?: number;
  cats?: number;
  parking?: number;
}

const propertyOptions = [
  { label: "House", value: "1" },
  { label: "Apartment", value: "0" }
];

const roomOptions = [
  { label: "Shared", value: "0" },
  { label: "Private", value: "1" }
];

const TopInput: FunctionComponent<SearchProps> = props => {
  const searchRoom = async () => {
    delete inputs.onSearchRoom;
    if (!inputs.address) {
      alert("Missing the address field.");
    } else {
      props.onSearchRoom(inputs);
    }
  };

  const { inputs, handleInputChange, handleSubmit } = customHook(
    {
      ...props
    },
    searchRoom
  );
  return (
    <div className="search-mobile">
      <Row className="border-btm">
        <Col span={24}>
          <span style={{ paddingBottom: "15px" }} className="input-title">
            Estimated Rent:
          </span>
        </Col>
        <Col span={12} className="pad10">
          <InputNumber
            onChange={e => handleInputChange(e, "min_price")}
            value={inputs.min_price}
            placeholder="Min Rent"
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={12}>
          <InputNumber
            onChange={e => handleInputChange(e, "max_price")}
            value={inputs.max_price}
            placeholder="Max Rent"
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <Row className="border-btm">
        <Col span={24}>
          <span className="input-title">House type</span>
        </Col>
        <Col span={24}>
          <Checkbox.Group
            defaultValue={inputs.house_type}
            options={propertyOptions}
            onChange={e => handleInputChange(e, "house_type")}
          />
        </Col>
      </Row>
      <Row className="border-btm">
        <Col span={24}>
          <span className="input-title">Bedroom type</span>
        </Col>
        <Col span={24}>
          <Checkbox.Group
            defaultValue={inputs.private_room}
            options={roomOptions}
            onChange={e => handleInputChange(e, "private_room")}
          />
        </Col>
      </Row>
      <Row className="border-btm">
        <Col span={24}>
          <span className="input-title">Bathroom type</span>
        </Col>
        <Col span={24}>
          <Checkbox.Group
            defaultValue={inputs.private_bath}
            options={roomOptions}
            onChange={e => handleInputChange(e, "private_bath")}
          />
        </Col>
      </Row>
      <Row className="border-btm">
        <Col span={24}>
          <span className="input-title">Laundry</span>
        </Col>
        <Col span={24}>
          <Checkbox.Group
            style={{ width: "100%" }}
            defaultValue={inputs.laundry}
            onChange={e => handleInputChange(e, "laundry")}
          >
            <Row style={{ width: "100%" }}>
              <Col span={12} className="pad10">
                <Checkbox value="0">No Laundry</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="1">In Unit</Checkbox>
              </Col>
              <Col span={12} className="pad10">
                <Checkbox value="2">Hookups</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="3">On Building</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>
      <Row className="border-btm">
        <Col span={24}>
          <span className="input-title">Amenities</span>
        </Col>
        <Col span={24}>
          <Row style={{ width: "100%" }}>
            <Col span={12} className="pad10">
              <Checkbox
                checked={inputs.wheelchair === 1 || inputs.wheelchair === "1"}
                onChange={e =>
                  handleInputChange(
                    e.target.checked ? 1 : undefined,
                    "wheelchair"
                  )
                }
              >
                Accessibility
              </Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox
                checked={inputs.smoking === 1 || inputs.smoking === "1"}
                onChange={e =>
                  handleInputChange(e.target.checked ? 1 : undefined, "smoking")
                }
              >
                Smoking
              </Checkbox>
            </Col>
            <Col span={12} className="pad10">
              <Checkbox
                checked={inputs.cats === 1 || inputs.cats === "1"}
                onChange={e =>
                  handleInputChange(e.target.checked ? 1 : undefined, "cats")
                }
              >
                Pet Friendly
              </Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox
                checked={
                  inputs.parking === 1 ||
                  inputs.parking === "1" ||
                  inputs.parking === 2 ||
                  inputs.parking === "2"
                }
                onChange={e =>
                  handleInputChange(e.target.checked ? 1 : undefined, "parking")
                }
              >
                Parking
              </Checkbox>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ padding: "15px" }}>
        <button onClick={searchRoom} className="btn-estimate-rent">
          Apply Filters
        </button>
      </Row>
    </div>
  );
};
export default TopInput;
