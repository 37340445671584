import React, { useReducer } from "react";
import { reducer, initialState } from "./reducer";
import { FiltersState, FiltersReducerAction } from "./state.d";

type AppStateContextValue = {
  appState: FiltersState;
  dispatchAppState: React.Dispatch<FiltersReducerAction>;
};

type AppProviderProps = {
  value?: AppStateContextValue;
  children: React.ReactNode;
};

const AppContext = React.createContext<AppStateContextValue | undefined>(
  undefined
);

function AppStateProvider(props: AppProviderProps) {
  const [appState, dispatchAppState] = useReducer(reducer, initialState);

  const value = React.useMemo(() => {
    return {
      appState,
      dispatchAppState
    };
  }, [appState]);

  return <AppContext.Provider value={value} {...props} />;
}

function useAppState() {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error("useAppState must be used within a AppStateProvider");
  }
  return context;
}

export { AppStateProvider, useAppState };
