import React, { FunctionComponent, useState } from "react";
import TopSection from "./ReportResult/Top";
import BreakdownSection from "./ReportResult/BreakdownSection";
import ComparablesSection from "./ReportResult/ComparablesSection";
import HistoricalGraphSection from "./ReportResult/HistoricalGraphSection";
import {
  ReportResultProps,
  ReportRoomPriceResultProps,
  ComparablesProps,
  TotalCalc,
  PredictRangeProps
} from "./Types";

interface Props {
  roomDetail: ReportResultProps;
  roomPrices: ReportRoomPriceResultProps[];
  comparables: ComparablesProps[];
  totalPrice: TotalCalc;
  predictRanges: PredictRangeProps[];
  onBack: Function;
}
const Component: FunctionComponent<Props> = props => {
  const onBack = () => {
    props.onBack();
  };
  return (
    <div className="report-result-wrapper">
      <div>
        <TopSection
          inputs={props.roomDetail}
          bigPrice={props.totalPrice.totalAvg}
          currencyCode={props.roomPrices[0].currencyCode}
          onBack={onBack}
        />
      </div>
      <div>
        <BreakdownSection
          rooms={props.roomPrices}
          totalPrice={props.totalPrice}
        />
      </div>
      <div>
        <ComparablesSection comparables={props.comparables} />
      </div>
      <div>
        <HistoricalGraphSection predictRanges={props.predictRanges} />
      </div>
    </div>
  );
};

export default Component;
