import React, { FunctionComponent, useState, useMemo } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useUser } from "../../../store/user/context";
import "./Rentals.d";

const styles = {
	edit: {
		float: "right" as "right",
		width: "50px",
		textAlign: "left" as "left",
		cursor: "pointer",
		color: "green",
		paddingLeft: "20px",
		marginLeft: "10px",
		border: "none",
		boxShadow: "none",
		background: "url('https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5d6845a5ffc2980b60fde04c_edit.svg')",
		backgroundPosition: "left 8px",
    backgroundRepeat: "no-repeat"
	},
	save: {
		float: "right" as "right",
		width: "105px",
		textAlign: "left" as "left",
		cursor: "pointer",
		color: "green",
		paddingLeft: "20px",
		marginLeft: "10px",
		border: "none",
		boxShadow: "none",
		background: "url('https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5d72b84a0a7b12ab37d84a94_save.svg')",
		backgroundPosition: "left 8px",
    backgroundRepeat: "no-repeat",
	},
	cancel: {
		float: "right" as "right",
		width: "95px",
		textAlign: "left" as "left",
		cursor: "pointer",
		color: "gray",
		paddingLeft: "20px",
		marginLeft: "10px",
		border: "none",
		background: "url('https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5d709e249b8d7b96738a4f3f_remove.svg')",
		backgroundPosition: "left 8px",
    backgroundRepeat: "no-repeat",
	},
	delete: {
		float: "right" as "right",
		width: "70px",
		textAlign: "left" as "left",
		cursor: "pointer",
		color: "red",
		paddingLeft: "20px",
		marginLeft: "10px",
		border: "none",
		background: "url('https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5d72b84a44bd70c5b54e4f07_delete.svg')",
		backgroundPosition: "left 8px",
    backgroundRepeat: "no-repeat",
	},
	label: {
		color: "#737373"
	}
};

const RentalInfo: FunctionComponent<{
  add_handler?: () => void;
  rmv_handler?: () => void;
  data;
  handleDataChange: (data: {}) => void;
	handleDelRequest: (del_id: number) => any;
	handleEditRequest: (edit_id: number) => void;
	handleCancelRequest: (edit_id: number) => void;
}> = props => {
	const { data, handleDataChange, handleDelRequest, 
		handleEditRequest, handleCancelRequest } = props;

  return (
    <div className="rental" style={{ textAlign: "left" as "left"}}>
			<Row style={{ marginTop: "30px" }}>
				<Col md={9}>
					<b>{data.address}</b>
				</Col>
				<Col md={{ span: 1, offset: 2 }} id={data.id+'-edit-btn'}>
					<Button style={styles.edit}
						onClick={(e: any) => handleEditRequest(data.id)}>
						Edit
					</Button>
				</Col>
				<Col md={1} id={data.id+'-save-btn'} style={{display:"none"}}>
					<Button style={styles.save} type="submit">
						Save
					</Button>
				</Col>
				<Col md={1} id={data.id+'-cancel-btn'} style={{display:"none"}}>
					<Button style={styles.cancel} 
						onClick={(e: any) => handleCancelRequest(data.id)}>
						Cancel
					</Button>
				</Col>
				<Col md={1} id={data.id+'-del-btn'} style={{display:"none"}}>
					<Button style={styles.delete} 
						onClick={(e: any) => handleDelRequest(data.id)}>
						Delete
					</Button>
				</Col>
			</Row>
			<hr style={{marginTop: "0", borderColor: "#bbb"}}/>
			<div id={data.id+"-info"} style={{ marginTop: "20px" }}>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Street Address:</Col> 
					<Col md={9}>{data.address}</Col>
				</Row>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Rent price:</Col> 
					<Col md={9}>${data.price}</Col>
				</Row>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Property Type:</Col> 
					<Col md={9}>{data.house_type == 1? "Apartment": "House" }</Col>
				</Row>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Room Size:</Col> 
					<Col md={9}>{data.room_size == 0? "Small (70 - 125 sqft)": 
						(data.room_size == 1? "Medium (125 - 150 sqft)":
						(data.room_size == 2? "Large (150 - 200 sqft)":
						"Extra (200 - 500 sqft)"))}</Col>
				</Row>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Bedroom Type:</Col> 
					<Col md={9}>{data.private_room == 1? "Shared Bedroom": "Private Bedroom"}</Col>
				</Row>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Bathroom Type:</Col> 
					<Col md={9}>{data.private_bath == 1? "Shared Bathroom": "Private Bathroom"}</Col>
				</Row>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Furnishing:</Col> 
					<Col md={9}>{data.furnished == 1? "Yes": "No"}</Col>
				</Row>
			</div>
			<div id={data.id+"-edit"} style={{ marginTop: "20px", display: 'none'}}>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Street Address:</Col> 
					<Col md={9}>
						<Form.Control
							required
							type="text"
							name="address"
							placeholder="Address"
							value={data.address}
							onChange={(e: any) => handleDataChange({ address: e.target.value })}
						/>
						<Form.Control.Feedback type="invalid">Please fill in the address!</Form.Control.Feedback>
					</Col>
				</Row>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Rent price:</Col> 
					<Col md={9}>
						<Form.Control
							required
							type="number"
							name="price"
							placeholder="$ Rent"
							value={data.price}
							onChange={(e: any) => handleDataChange({ price: e.target.value })}
						/>
						<Form.Control.Feedback type="invalid">How much is the rent?</Form.Control.Feedback>
					</Col>
				</Row>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Property Type:</Col> 
					<Col md={9}>
						<Form.Control
							required
							as="select"
							name="house_type"
							value={data.house_type}
							onChange={(e: any) =>
								handleDataChange({ house_type: e.target.value })
							}
						>
							<option value="">Property Type</option>
							<option value="1">Apartment</option>
							<option value="0">House</option>
						</Form.Control>
						<Form.Control.Feedback type="invalid">Choose a property type!</Form.Control.Feedback>
					</Col>
				</Row>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Room Size:</Col> 
					<Col md={9}>
						<Form.Control
							as="select"
							name="room_size"
							value={data.room_size}
							onChange={(e: any) =>
								handleDataChange({ room_size: e.target.value })
							}
						>
							<option value="">Room Size</option>
							<option value="0">Small (70 - 125 sqft)</option>
							<option value="1">Medium (125 - 150 sqft)</option>
							<option value="2">Large (150 - 200 sqft)</option>
							<option value="3">Extra (200 - 500 sqft)</option>
						</Form.Control>
					</Col>
				</Row>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Bedroom Type:</Col> 
					<Col md={9}>
						<Form.Control
							required
							as="select"
							name="private_room"
							value={data.private_room}
							onChange={(e: any) => handleDataChange({ private_room: e.target.value })}
						>
							<option value="">Bedroom Type</option>
							<option value="1">Shared Bedroom</option>
							<option value="0">Private Bedroom</option>
						</Form.Control>
						<Form.Control.Feedback type="invalid">Choose a bedroom type!</Form.Control.Feedback>
					</Col>
				</Row>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Bathroom Type:</Col> 
					<Col md={9}>
						<Form.Control
							required
							as="select"
							name="private_bath"
							value={data.private_bath}
							onChange={(e: any) =>
								handleDataChange({ private_bath: e.target.value })
							}
						>
							<option value="">Bathroom Type</option>
							<option value="1">Shared Bathroom</option>
							<option value="0">Private Bathroom</option>
						</Form.Control>
						<Form.Control.Feedback type="invalid">Choose a bathroom type!</Form.Control.Feedback>
					</Col>
				</Row>
				<Row style={{marginBottom: "5px"}}>
					<Col md={3} style={styles.label}>Furnishing:</Col> 
					<Col md={9}>
						<Form.Control
							required
							as="select"
							name="furnished"
							value={data.furnished}
							onChange={(e: any) =>
								handleDataChange({ furnished: e.target.value })
							}
						>
							<option value="">Furnishing</option>
							<option value="1">Yes</option>
							<option value="0">No</option>
						</Form.Control>
						<Form.Control.Feedback type="invalid">Is the place furnished?</Form.Control.Feedback>
					</Col>
				</Row>
			</div>
    </div>
  );
};

export default RentalInfo;
