import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import './css/Chart.css'
const utilities = require('../../pages/Dashboard-pages/util/AnalyticsFunctions.js')

export default function Chart({ data, source }) {    
    const [chartState] = useState({
        labels: /*Object.keys(data)*/parseDates(Object.keys(getSourceData(data))),
        datasets: [
            {
                label: 'Scraped data for ' + source,
                fill: true,
                lineTension: 0.1,
                backgroundColor: 'rgba(102, 176, 21, 0.5)',
                borderColor: 'rgba(102, 176, 21, 1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(102, 176, 21, 1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 5,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(102, 176, 21, 1)',
                pointHoverBorderColor: 'rgba(102, 176, 21, 1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: Object.values(getSourceData(data))
            }
        ]
    })

    function getSourceData(dataValues) { 
        return dataValues["all"]        
        
        /*
        if (source === "all") {
            return (dataValues[source] === undefined) ? dataValues["craigslist"] : dataValues["all"]
        }
        
        return (dataValues[source] === undefined) ? dataValues["all"] : dataValues[source] 
        */
    }

    function parseDates(dates) {                
        const newDates  = Array.from(dates, x => String(x))                                
        var index 
        var array: Array<String> = []
 
        for (index = 0; index < newDates.length; index++) {            
            const newDate = new Date(newDates[index])
            const month = newDate.getMonth()
            const day = newDate.getDate()
                        
            const monthString = utilities.parseMonth(month + 1)
            const dayString = day.toString().length > 1 ? day.toString() : "0" + day.toString()
            const dateString = monthString + " " + dayString

            array.push(dateString)
        }

        return array
    } 

    return (
        <div className="container mb-5 pt-5">
            <h1 className="title outsideContainer">SCRAPED DATA</h1>
            <div className="container card chart">
                <p className="title insideContainer">SCRAPED DATA PER DAY</p>
                <Line data={chartState} />
            </div>            
        </div>
    )
}
