import React from "react";
import Login from "./Login";
import { Navbar as BootstrapNavbar, Nav } from "react-bootstrap";
import styled from "styled-components";
import { PageLink } from "./PageLink";
import { PRIMARY_COLOR } from "../constants";
import cookie from "react-cookies";

import { t } from "../gaia/gaia";
const styles = {
  brand: {
    fontSize: "19px",
    fontWeight: 900,
    color: PRIMARY_COLOR,
    verticalAlign: "middle"
  },
  item: {
    fontFamily: "AvenirNext, Helvetica",
    fontSize: "16px",
    color: "#4a4a4a",
    paddingLeft: "2rem",
    paddingRight: "2rem"
  }
};

const Navbar = styled(BootstrapNavbar)`
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: #fff !important;

  @media (min-width: 992px) {
    height: 61px;
    background-color: red;
  }
`;

const Header: React.FunctionComponent = //<{
  //navbarOverride: JSX.Element | false;
  /*}>*/ props => {
    const isLoggedIn = cookie.load("rentalios-email") ? true : false;
    return (
      <Navbar bg="light" expand="lg">
        <a href="https://www.rentalios.com">
          <BootstrapNavbar.Brand style={styles.brand}>
            <div className="d-flex flex-row">
              <img src={require("../assets/images/logoTM.svg")} height="40" />
            </div>
          </BootstrapNavbar.Brand>
        </a>

        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />

        <BootstrapNavbar.Collapse>
          {/*props.navbarOverride */ false || (
            <>
              <Nav className="mr-auto">
                <PageLink to="https://www.rentalios.com" isExternal={true}>
                  {t("Home")}
                </PageLink>

                {isLoggedIn ? (
                  <PageLink to="rentalreport" isExternal={false}>
                    House Rent Report
                  </PageLink>
                ) : null}

                <PageLink
                  to="https://www.rentalios.com/#about"
                  isExternal={true}
                >
                  {t("About Us")}
                </PageLink>

                <PageLink
                  to="https://www.rentalios.com/#testimonials"
                  isExternal={true}
                >
                  {t("Testimonials")}
                </PageLink>

                <PageLink to="https://www.rentalios.com/blog" isExternal={true}>
                  {t("Blog")}
                </PageLink>

                <PageLink
                  to="https://www.rentalios.com/#contact"
                  isExternal={true}
                >
                  {t("Contact Us")}
                </PageLink>
              </Nav>
            </>
          )}

          {/* Avatar goes here */}
          {/* TODO: - Decouple the login logic from the <Login /> component. Do this by:
          1) Moving all the logic of Login to its separate file (store in state).
          2) Making the jsx component only render the props its given, which will be given
             by the state.*/}
          <div className="d-flex">
            <Login />
          </div>
        </BootstrapNavbar.Collapse>
      </Navbar>
    );
  };

export default Header;
