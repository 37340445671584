import React, { useMemo } from "react";
import styled from "styled-components";
import MaterialIcon from "@material/react-material-icon";
import { Dropdown } from "react-bootstrap";
import { CustomNavLink } from "./CustomNavLink";
import UserLoginButton from "./UserLoginButton";
import { PRIMARY_COLOR } from "../constants";

export const ContainerButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex !important;
  flex-direction: row !important;
  padding-right: 0;

  outline-color: ${PRIMARY_COLOR} !important;
`;

export const AvatarImage = styled.div`
  border-radius: 100px;
  overflow: hidden;
  width: 35px;
  height: 35px;
  background-size: cover;
  border-radius: 100px;
`;

const styles = {
  user_frame: {
    background: "none",
    color: "#4a4a4a",
    padding: "0px",
    border: "none",
    boxShadow: "none"
  },
  sub_menu: {
    marginTop: ".7rem"
  }
};

const LoggedOutItem = ({ user, handleLogout, handleRentals }) => {
  const user_data = useMemo(() => {
    return user ? user : {};
  }, [user]);

  const CustomDropdownButton = React.useMemo(() => {
    return React.forwardRef((props: any, ref: any) => {
      return (
        <ContainerButton
          ref={ref}
          onClick={e => {
            e.preventDefault();
            props.onClick(e);
          }}
          className="d-flex flex-row align-items-center p-0 pt-2 p-lg-0"
        >
          <AvatarImage
            style={{ backgroundImage: `url(${user_data.picture})` }}
          />

          <UserLoginButton className="px-2 py-0 text-decoration-none">
            {user_data.name}
          </UserLoginButton>

          <MaterialIcon icon="arrow_drop_down" />
        </ContainerButton>
      );
    });
  }, [user_data]);

  return (
    <Dropdown className="d-flex flex-column flex-md-row">
      <Dropdown.Toggle
        id="user-dropdown-toggle"
        style={styles.user_frame}
        className="d-flex h-100  "
        as={CustomDropdownButton}
      />

      <Dropdown.Menu style={styles.sub_menu}>
        <Dropdown.Item href="/rentals_info">
          <CustomNavLink className="px-0">Rentals</CustomNavLink>
        </Dropdown.Item>

        <Dropdown.Item onClick={handleLogout}>
          <CustomNavLink className="px-0">Logout</CustomNavLink>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LoggedOutItem;
