import React, { FunctionComponent, useState } from "react";
import HeatMap from "../../components/AnalyticsChart/components/HeatMap"
import useWindowSize from '../../hooks/useWindowSize';
import { LG_SCREEN_BREAKPOINT } from '../../constants';

const heatMapAnalytics: FunctionComponent = () => {
    const [country, setCountry] = useState("us")
    const countryToSrcHeatMap = {
        us: "https://datastudio.google.com/embed/reporting/fb15bc78-4961-4b14-8ba9-a82f89476019/page/seCiB",
        mx: "https://datastudio.google.com/embed/reporting/f8ce7bb2-b764-41cf-8f4b-e9c826bffd12/page/seCiB",
        id: "https://datastudio.google.com/embed/reporting/0d82f4b6-53f1-4dca-abd2-5e3d9bb30002/page/seCiB",
        au: "https://datastudio.google.com/embed/reporting/d06dea7e-f812-4143-8023-d327017c0da1/page/seCiB"
    }
    const [width] = useWindowSize();
    const isMobile = React.useMemo(
        () => window.innerWidth <= LG_SCREEN_BREAKPOINT,
        [width]
    )
    const countryOnCheck = (event) => {
        console.log(event)
        const value = event.target.value
        setCountry(value)
    }
    return (
        <div className="container mb-5 pt-5">
            <h1 className="title outsideContainer" style={{ float: "left" }}>Heat Map</h1>
            <select
                id="country"
                value={country}
                className={isMobile ? "granularityInputMobile mr-3" : "granularityInput mr-3"}
                onChange={countryOnCheck}
                style={{ float: "right" }}
            >
                <option value="us">United States</option>
                <option value="mx">Mexico</option>
                <option value="id">Indonesia</option>
                <option value="au">Australia</option>
            </select>
            <div className="container card chart">
                <HeatMap url={countryToSrcHeatMap[country]} />
            </div>
        </div>
    )
}

export default heatMapAnalytics