import React from 'react'
import '../css/Chart.css'
import useWindowSize from '../../../hooks/useWindowSize';
import { LG_SCREEN_BREAKPOINT } from '../../../constants';

export default function AnalyticsFilter({start, end, granularity, source}) {
    const [width] = useWindowSize(); 
    const isMobile = React.useMemo(
        () => window.innerWidth <= LG_SCREEN_BREAKPOINT,
        [width]
    )
    
    function getStringFromNumber() {
        switch (granularity.val) {
            case "1": return "Daily"
            case "7": return "Weekly"
            case "30": return "Monthly"
            default: return "Weekly"
        }
    }        

    return (
        <div>
            <h2 className="title inContainer">FILTERS</h2>            
            {/* Start Date filter input */}
            <input 
                className={ isMobile ? "filterInputMobile mr-1" : "filterInput mr-1"} 
                type="date" 
                id="startDate"
                value={start.val} 
                max={end.val} 
                onChange={() => start.func({event})}                            
                name="startDate">
            </input> 
            {/* End Date filter input */}                       
            <input 
                className={ isMobile ? "filterInputMobile mr-3" : "filterInput mr-3"} 
                type="date" 
                value={end.val}                
                id="endDate"
                max={end.val} 
                onChange={() => end.func({event})}
                name="endDate">                            
            </input>
            {/* Granularity filter input */}
            <select
                id="interval"
                value={getStringFromNumber()}
                className={ isMobile ? "granularityInputMobile mr-3" : "granularityInput mr-3"} 
                onChange={() => granularity.func({event})}                          
            >
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>                            
                <option value="Monthly">Monthly</option>
            </select>
            {/* Source filter input */}
            <select
                id="source"
                value={source.val}
                className={ isMobile ? "sourceInputMobile mt-3" : "sourceInput"} 
                onChange={() => source.func({event})}                          
            >
                <option value="">All sources</option>
                <option value="craigslist">Craigslist</option>
                <option value="facebook">Facebook</option>
                <option value="infokost">Infokost</option>
                <option value="mamikos">Mamikos</option>
                <option value="rentalios">Rentalios</option>
                <option value="spareroom">Spareroom</option>
                <option value="vivanuncios">Vivanuncios</option>
            </select>
        </div>
    )
}
