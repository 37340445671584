import React, { FunctionComponent } from "react";
import Table from "antd/es/table";
import { ComparablesProps } from "../Types";
import {
  getPropertyIcon,
  getBedIcon,
  getIsFurnishedIcon,
  getIsAvailableIcon
} from "../../../../utilities";

const columns = [
  {
    title: "#",
    dataIndex: "number",
    key: "number",
    align: "center" as any,
    width: 30
  },
  {
    title: "Approximate Address",
    dataIndex: "address",
    width: 180,
    key: "address"
  },
  {
    title: "Price",
    dataIndex: "price",
    align: "center" as any,
    className: "b-500",
    key: "price"
  },
  {
    title: "Days Old",
    dataIndex: "daysOld",
    align: "center" as any,
    key: "daysold"
  },
  {
    title: "Distance",
    dataIndex: "distance",
    align: "center" as any,
    key: "distance"
  },
  {
    title: "Laundry",
    dataIndex: "laundry",
    key: "laundry"
  },
  {
    title: "Parking",
    dataIndex: "parking",
    key: "parking"
  },
  {
    title: "Type",
    dataIndex: "propertyType",
    key: "propertyType",
    align: "center" as any,
    render: propertyType => getPropertyIcon(propertyType)
  },
  {
    title: "Bed",
    dataIndex: "bedroomType",
    key: "bedroomType",
    align: "center" as any,
    render: bedroomType => getBedIcon(bedroomType)
  },
  {
    title: "Bath",
    dataIndex: "bathroomType",
    key: "bathroomType",
    align: "center" as any,
    render: bathroomType => getBedIcon(bathroomType)
  },
  {
    title: "Furnishing",
    dataIndex: "furnishing",
    key: "furnishing",
    align: "center" as any,
    render: furnishing => getIsFurnishedIcon(furnishing)
  },
  {
    title: "Accessibility",
    dataIndex: "accessibility",
    key: "accessibility",
    align: "center" as any,
    render: accessibility => getIsAvailableIcon(accessibility)
  },
  {
    title: "Smoking",
    dataIndex: "smokingPolicy",
    key: "smokingPolicy",
    align: "center" as any,
    render: smokingPolicy => getIsAvailableIcon(smokingPolicy)
  },
  {
    title: "Pet Policy",
    dataIndex: "petPolicy",
    key: "petPolicy",
    align: "center" as any,
    render: petPolicy => getIsAvailableIcon(petPolicy)
  }
];

interface Props {
  comparables: ComparablesProps[];
}

const Component: FunctionComponent<Props> = props => {
  return (
    <div className="report-comparables-section bottom-line">
      <div>
        <span className="report-section-title">Comparables</span>
      </div>
      <div>
        {props.comparables.map((item, index) => {
          return (
            <div key={index}>
              <div>
                <span className="room-title">Room {index + 1}</span>
              </div>
              <div className="table-detail">
                <Table
                  scroll={{ x: 1300 }}
                  rowKey={record => `Room ${index + 1}_number${record.number}`}
                  bordered={true}
                  pagination={false}
                  columns={columns}
                  dataSource={item.data}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Component;
