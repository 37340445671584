import React, { FunctionComponent } from "react";
import { AvatarImage, ContainerButton } from "./LoginLoggedInItem";
import UserLoginButton from "./UserLoginButton";

const LoggedInItem: FunctionComponent<{
  handleShow: () => void;
}> = ({ handleShow }) => (
  <ContainerButton
    className="d-flex flex-row align-items-center p-0 pt-2 p-lg-0"
    onClick={handleShow}
  >
    <AvatarImage
      className="mr-2"
      style={{
        backgroundImage: `url(${require("../assets/images/empty_avatar.png")})`
      }}
    />

    <UserLoginButton className="p-0">Sign in</UserLoginButton>
  </ContainerButton>
);

export default LoggedInItem;
