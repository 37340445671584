// Dependencies
import React, { useState, useEffect } from "react";
import SwitchButton from "../components/SwitchButton";
import Button from "../components/Button";
import AdvancedSettings from "../components/pages/Home/AdvancedSettings";
import "../components/pages/Home/home.css";
import FilterToggle from "../components/pages/Results/FilterToggle";
import {
  PropertyTypeField,
  RoomsInput,
  BathsInput,
  MainContent,
  MainTitle,
  AdvancedOptionsButton,
  Description,
  TargetPriceField
} from "../components/Fields";
import AddressInput from "../components/AddressInput";
import { generateSharableQuery } from "../utilities";
import { useAppState } from "../store/app/state";
import useWindowSize from "../hooks/useWindowSize";
import { HomeProps } from "../components/pages/Home/Home";
import { FiltersState } from "../store/app/state.d";

import { t } from "../gaia/gaia";
type TitleType = "Estimate Rent" | "Search Rooms" | "searchError";

const Home = (props: HomeProps) => {
  const { appState: state, dispatchAppState: dispatch } = useAppState();
  const [advancedSettingsToggle, setAdvancedSettings] = useState(false);
  const [showErrors, setErrorsVisibility] = useState(false);
  const [mainTitleText, setMainTitleText] = useState("");
  const [mainDescriptionText, setMainDescriptionText] = useState("");
  const [searchError, setSearchError] = useState(false);
  const [width] = useWindowSize();
  const isMobile = React.useMemo(() => window.innerWidth <= 767, [width]);

  // Set the Main Title and Description Texts
  // useEffect(() => {
  //   changeTitleText(state.userType);
  //   changeDescriptionText("");
  // }, []);

  // Checks the form for the required fields and highlights missing required fields.
  // Return true or false depending on form completion.
  function checkForm() {
    // Check if an address was given.
    if (!state.address) {
      return false;
    } else {
      return true;
    }
  }

  async function handleButtonClick() {
    let selectedButton = state.userType;
    if (checkForm()) {
      const searchQuery = generateSharableQuery(state);
      if (selectedButton === "Estimate Rent") {
        props.history.push({
          pathname: "/results",
          search: searchQuery
        });
      } else {
        props.history.push({
          pathname: "/searchresults",
          search: searchQuery
          // state: {
          //   address: state.address,
          //   house_type: state.propertyType,
          //   laundry: state.laundry,
          //   private_room:state.furnishing
          // }
        });
      }
    } else {
      setErrorsVisibility(true);
      alert("Missing the address field.");
    }
  }

  useEffect(() => {
    // Check for a state in history
    if (typeof props.location.state !== "undefined") {
      // Check for errors
      if (props.location.state.hasOwnProperty("searchError")) {
        // If there's an error, change the Title and Descriptions texts, and update history state
        if (props.location.state.searchError) {
          changeTitleText("searchError");
          changeDescriptionText("searchError");
          setSearchError(true);
          props.history.replace("/", { searchError: false });
        }
      }
    }
  }, [props.location]);

  // const changeTitleText = (payload: TitleType) => {
  //   if (payload === "Landlord") {
  //     setMainTitleText("Rent Your Rooms Competitively");
  //   } else if (payload === "Tenant") {
  //     setMainTitleText("Always a Fair Price");
  //   } else if (payload === "searchError") {
  //     setMainTitleText("Sorry, we do not have data for that address yet.");
  //   }
  // };

  const changeTitleText = (payload: TitleType) => {
    if (payload === "Estimate Rent") {
      return (
        <MainTitle
          style={
            searchError
              ? { textTransform: "none" }
              : { textTransform: "uppercase" }
          }
        >
          {t("Rent Your Rooms Competitively")}
        </MainTitle>
      );
    } else if (payload === "Search Rooms") {
      return (
        <MainTitle
          style={
            searchError
              ? { textTransform: "none" }
              : { textTransform: "uppercase" }
          }
        >
          {t("Search Rooms")}
        </MainTitle>
      );
    } else if (payload === "searchError") {
      setMainTitleText("Sorry, we do not have data for that address yet.");
    }
  };

  const changeDescriptionText = (payload: string) => {
    if (payload !== "searchError") {
      return (
        <Description>{t("Rentalios is the best way to compare")}</Description>
      );
      // setMainDescriptionText(
      //   "Rentalios is the best way to compare room rents with other local listings"
      // );
    } else {
      setMainDescriptionText("Please try another US address.");
    }
  };

  function handleAddressChange(value: string) {
    dispatch({ type: "setAddress", payload: value });
  }

  return (
    <MainContent className="container">
      <div className="justify-content-center text-center">
        <SwitchButton
          options={["Estimate Rent", "Search Rooms"]}
          selected={state.userType}
          clickHandler={(payload: FiltersState["userType"]) => {
            dispatch({ type: "setUserType", payload });
            changeTitleText(payload);
          }}
        />
        {/* <MainTitle
          style={
            searchError
              ? { textTransform: "none" }
              : { textTransform: "uppercase" }
          }
        >
          {mainTitleText}
        </MainTitle> */}

        {changeTitleText(state.userType)}

        {/* <Description>{mainDescriptionText}</Description> */}
        {changeDescriptionText("")}

        <div className="mx-auto mt-4" style={{ maxWidth: "678px" }}>
          <div className="d-flex">
            <AddressInput
              style={showErrors && !state.address ? { borderColor: "red" } : {}}
              value={state.address}
              report={false}
              setValue={handleAddressChange}
              onEnterPress={handleButtonClick}
            />
            <FilterToggle
              style={{ margin: "5px" }}
              toggled={advancedSettingsToggle}
              onClick={() => setAdvancedSettings(!advancedSettingsToggle)}
            />
          </div>

          <div className="row no-gutters mt-3">
            <div className="col-md mb-3 mb-md-0">
              <span className="input-title">Property Type:</span>
              <PropertyTypeField
                className="w-100"
                value={state.propertyType}
                onChange={(e: any) =>
                  dispatch({
                    type: "setPropertyType",
                    payload: e.target.value
                  })
                }
              />
            </div>

            <div className="w-100 d-md-none"></div>

            <div className="col ml-md-3 mb-3 mb-md-0">
              <span className="input-title">Bedroom Type:</span>
              <RoomsInput
                className="w-100"
                value={state.selectedRoom}
                onChange={e =>
                  dispatch({ type: "setRoom", payload: e.target.value })
                }
              />
            </div>

            <div className="w-100 d-md-none"></div>

            <div className="col ml-md-3 mb-md-0">
              <span className="input-title">Bathroom Type:</span>
              <BathsInput
                className="w-100"
                value={state.selectedBath}
                onChange={e =>
                  dispatch({ type: "setBath", payload: e.target.value })
                }
              />
            </div>
          </div>

          <div className="row no-gutters mt-3">
            <div
              className={`
                col-md mb-md-0
                ${isMobile ? "d-none" : "d-block"}
              `}
            />

            <div className="col ml-md-3 mb-0">
              <span className="input-title">Desired Rent:</span>
              <TargetPriceField
                className="w-100"
                value={state.targetPrice}
                onChange={e =>
                  dispatch({
                    type: "setTargetPrice",
                    payload: e.target.value
                  })
                }
              />
            </div>

            <div
              className={`
                col-md mb-md-0 ml-md-3
                ${isMobile ? "d-none" : "d-block"}
              `}
            />
          </div>

          {advancedSettingsToggle && (
            <AdvancedSettings
              className="mt-3"
              state={state}
              dispatch={dispatch}
              isMobile={isMobile}
            />
          )}
        </div>
      </div>
      <div className="d-flex">
        <Button className="mt-5 mx-auto" onClick={handleButtonClick}>
          {t("Estimate Rent")}
        </Button>
      </div>
    </MainContent>
  );
};

export default Home;
