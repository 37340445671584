import React from "react";

const Logo: React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = "#66B015", width = "9", height = "20" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 9 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 1L0 10.5L8 20" stroke={color} stroke-width="2.4" />
  </svg>
);

export default Logo;
