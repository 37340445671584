import React, { FunctionComponent } from "react";
import { PRIMARY_COLOR } from "../../constants";

const LocationArrow: FunctionComponent<{ color?: string }> = ({
  color = PRIMARY_COLOR
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="23"
      viewBox="0 0 25 23"
    >
      <path
        fill={color}
        fillRule="nonzero"
        d="M24.793.244a.797.797 0 0 0-.885-.182L.471 9.984c-.34.143-.53.5-.455.854a.778.778 0 0 0 .765.607l10.964.011v10.782c0 .35.244.655.592.74a.784.784 0 0 0 .88-.382L24.908 1.119a.75.75 0 0 0-.116-.875zM13.307 19.178v-8.483c0-.421-.35-.762-.78-.763l-7.99-.008 17.944-7.596-9.174 16.85z"
      />
    </svg>
  );
};

export default LocationArrow;
