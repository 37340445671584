// src/comparables-context.tsx
import React, { createContext, useState, useMemo, useContext } from "react";
import {
  ComparablesContextValue,
  ComparablesProviderProps
} from "./comparables.d";

const ComparablesContext = createContext<ComparablesContextValue | undefined>(
  undefined
);

function ComparablesProvider(props: ComparablesProviderProps) {
  const [comparables, setComparables] = useState([]);
  const value = useMemo(() => {
    return {
      comparables,
      setComparables
    };
  }, [comparables]);
  return <ComparablesContext.Provider value={value} {...props} />;
}
function useComparables() {
  const context = useContext(ComparablesContext);
  if (!context) {
    throw new Error("useComparables must be used within a ComparablesProvider");
  }
  return context;
}
export { ComparablesProvider, useComparables };
