import React, { FunctionComponent, MouseEvent, CSSProperties } from "react";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../constants";

type Button = {
  className?: string;
  style?: CSSProperties;
  variant?: "small";
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
};

const StyledButton = React.memo(styled.button<Button>`
  height: 61px;
  border-radius: 27px;
  background-color: ${PRIMARY_COLOR};
  padding-left: 3rem;
  padding-right: 3rem;

  font-family: AvenirNext, Helvetica;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  outline: none !important;
  cursor: pointer;
  border: none;

  ${props => {
    if (props.variant === "small")
      return `
        height: 44px;
      `;
  }};
`);

const Button: FunctionComponent<Button> = React.memo(
  ({ children, ...props }) => <StyledButton {...props}>{children}</StyledButton>
);

export default Button;
