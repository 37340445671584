import React from "react";

const Logo: React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = "#66B015", width = "18px", height = "18px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
      fill={color}
    />
    <path
      d="M18 8.99995C18 13.95 13.95 18 9 18C6.13127 18 3.60002 16.7062 1.96875 14.625C3.4875 15.8625 5.45625 16.5937 7.59373 16.5937C12.5437 16.5937 16.5937 12.5437 16.5937 7.59373C16.5937 5.45625 15.8625 3.4875 14.625 1.96875C16.7063 3.59994 18 6.13118 18 8.99995Z"
      fill="#498C00"
    />
    <path
      d="M13.8939 6.41248L8.04387 12.825C7.6501 13.275 6.97513 13.275 6.58136 12.825L4.10635 10.0687C3.76887 9.67498 3.76887 9.05625 4.1626 8.66248C4.55636 8.325 5.17509 8.325 5.56886 8.71872L7.31261 10.6875L12.4313 5.11874C12.8251 4.72497 13.4438 4.66873 13.8376 5.0625C14.2313 5.39998 14.2313 6.075 13.8939 6.41248Z"
      fill="white"
    />
  </svg>
);

export default Logo;
