import React, { CSSProperties } from "react";
import styled from "styled-components";
import {
  PRIMARY_COLOR,
  LG_SCREEN_BREAKPOINT as LG,
  XL_SCREEN_BREAKPOINT as XL
} from "../constants";

const SwitchButtonElement = React.memo(styled.div<{
  fullWidth?: Boolean;
}>`
  height: 44px;
  overflow: hidden;
  display: inline-block;

  ${props => {
    if (props.fullWidth) {
      return `flex: 1; width: 100%;`;
    }
  }}

  button {
    height: 100%;
    width: 150px;
    padding-left: 2rem;
    padding-right: 2rem;
    font-family: AvenirNext, Helvetica;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: solid 1px ${PRIMARY_COLOR};
    outline: none;
    background-color: white;
    color: ${PRIMARY_COLOR};
    cursor: pointer;

    &.active {
      background-color: ${PRIMARY_COLOR};
      color: #ffffff;
    }

    &:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }

    @media (min-width: ${LG}px) and (max-width: ${XL + 3}px) {
      font-size: 14px;
      // font-weight: 400;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
`);

const SwitchButton: React.FunctionComponent<{
  options: string[];
  selected: string;
  clickHandler: (arg: any) => void;
  className?: string;
  style?: CSSProperties;
  fullWidth?: Boolean;
}> = props => {
  const { options, selected, clickHandler, style, fullWidth } = props;

  const buttonStyle: CSSProperties = fullWidth ? { flex: 1 } : {};

  return (
    <SwitchButtonElement fullWidth={fullWidth}>
      <div className="d-flex h-100">
        {options.map((option: any) => (
          <button
            key={option}
            value={option}
            className={option == selected ? "active" : ""}
            onClick={() => clickHandler(option)}
            style={{ width: "215px" }}
          >
            {option}
          </button>
        ))}
      </div>
    </SwitchButtonElement>
  );
};

export default SwitchButton;
