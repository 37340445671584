import React, { FunctionComponent, useState } from "react";
import Input from "antd/es/input";
import Row from "antd/es/row";
import Col from "antd/es/col";
import SearchGlassIcon from "../../../icons/SearchGlass";
import PinIcon from "../../../icons/Pin";
import FilterToggle from "../../../pages/Results/FilterToggle";
import "./mobileSearch.css";
import Drawer from "antd/es/drawer";
import Filters from "./Filters";

const FilterTitle = props => {
  const resetForm = () => {
    props.searchInput.cats = undefined;
    props.searchInput.house_type = undefined;
    props.searchInput.laundry = undefined;
    props.searchInput.max_price = undefined;
    props.searchInput.min_price = undefined;
    props.searchInput.parking = undefined;
    props.searchInput.private_bath = undefined;
    props.searchInput.private_room = undefined;
    props.searchInput.smoking = undefined;
    props.searchInput.wheelchair = undefined;
    props.onSearchRoom(props.searchInput);
  };
  return (
    <div className="filter-title">
      <span className="title">Filters</span>
      <span onClick={resetForm} className="btn-reset">
        Reset
      </span>
    </div>
  );
};

const TopInput = props => {
  const [showingDrawer, setShowDrawer] = useState(false);
  const showDrawer = () => {
    setShowDrawer(!showingDrawer);
  };
  const handleAddressChange = (address: string) => {
    props.searchInput.address = address;
  };

  return (
    <div style={{ padding: "15px" }}>
      <Row>
        <Col span={21}>
          <Input
            prefix={<PinIcon />}
            suffix={<SearchGlassIcon />}
            placeholder="Enter Address or Zip Code"
            onBlur={e => handleAddressChange(e.target.value)}
            defaultValue={props.searchInput.address}
          />
        </Col>
        <Col span={3}>
          <FilterToggle
            width="20"
            height="22"
            onClick={showDrawer}
            className="top-toggle"
            toggled={false}
          />
        </Col>
      </Row>
      <Drawer
        visible={showingDrawer}
        onClose={showDrawer}
        title={<FilterTitle {...props} />}
        width={"100%"}
      >
        <Filters
          onSearchRoom={props.onSearchRoom}
          min_price={props.searchInput.min_price}
          max_price={props.searchInput.max_price}
          house_type={props.searchInput.house_type}
          address={props.searchInput.address}
          laundry={props.searchInput.laundry}
          private_room={props.searchInput.private_room}
          private_bath={props.searchInput.private_bath}
          wheelchair={props.searchInput.wheelchair}
          smoking={props.searchInput.smoking}
          cats={props.searchInput.cats}
          parking={props.searchInput.parking}
        />
      </Drawer>
    </div>
  );
};
export default TopInput;
