import React, { FunctionComponent } from "react";
import { ReportResultProps } from "../Types";
import { numberToCurrency } from "../../../../utilities";
import Printer from "../../../../components/icons/Printer";
import Back from "../../../../components/icons/Back";

interface Props {
  inputs: ReportResultProps;
  bigPrice: number;
  currencyCode: string;
  onBack: Function;
}
const Component: FunctionComponent<Props> = props => {
  const onBack = event => {
    event.preventDefault();
    props.onBack();
  };

  const onPrint = event => {
    event.preventDefault();
    window.print();
  };
  return (
    <div>
      <div className="report-result-top ">
        <div
          className="d-flex"
          style={{
            position: "relative",
            paddingTop: "30px",
            paddingBottom: "30px"
          }}
        >
          <div className="d-flex">
            <Back />
            <span
              onClick={onBack}
              style={{
                color: "#66B015",
                display: "block",
                paddingLeft: "5px",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Back
            </span>
          </div>
          <div onClick={onPrint} className="print-button">
            <div style={{ marginRight: "25px" }}>
              <Printer />
            </div>
            <div
              style={{ color: "#66B015", fontWeight: 500, fontSize: "20px" }}
            >
              Print Report
            </div>
          </div>
        </div>
        <div className="result-title bottom-line">
          <div>
            <div style={{ padding: 0 }} className="report-title">
              Rental Report
            </div>
            <span className="address-title">{props.inputs.address}</span>
          </div>
          <div className="right-price" style={{ right: "225px" }}>
            <span className="estimated-rent">Property Type:</span>
            <span className="estimated-price">{props.inputs.propertyType}</span>
          </div>
          <div className="right-price">
            <span className="estimated-rent">Estimated Rent:</span>
            <span className="estimated-price">
              {numberToCurrency(props.bigPrice.toString(), props.currencyCode)}
            </span>
          </div>
        </div>
        <div className="room-title">
          <span className="report-section-title">Amenities</span>
        </div>
        <div className="amenities-input-wrapper bottom-line">
          <div>
            <span className="input-title-param">Laundry:</span>
            <span>{props.inputs.laundry}</span>
          </div>
          <div>
            <span className="input-title-param">Smoking Policy:</span>
            <span>{props.inputs.smokingPolicy}</span>
          </div>
          <div>
            <span className="input-title-param">Pet Policy:</span>
            <span>{props.inputs.petPolicy}</span>
          </div>
          <div>
            <span className="input-title-param">Accessibility:</span>
            <span>{props.inputs.accessibility}</span>
          </div>
          <div>
            <span className="input-title-param">Parking:</span>
            <span>{props.inputs.parking}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;
