import React from "react";

const Logo: React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = "#414850", width = "15", height = "16" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 15 16"
    fill="none"
  >
    <g id="delete">
      <g id="Group">
        <path
          id="Path"
          d="M9.86061 5.17173L8.68645 5.12866L8.43188 12.0803L9.60604 12.1232L9.86061 5.17173Z"
          fill="#D33131"
          stroke="white"
          strokeWidth="0.3"
        />
        <rect
          id="Rectangle"
          x="6.41455"
          y="5.15015"
          width="1.17492"
          height="6.95171"
          fill="#D33131"
          stroke="white"
          strokeWidth="0.3"
        />
        <path
          id="Path_2"
          d="M5.57191 12.0801L5.31735 5.12842L4.14319 5.17151L4.39778 12.1232L5.57191 12.0801Z"
          fill="#D33131"
          stroke="white"
          strokeWidth="0.3"
        />
        <g id="Shape">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.0111084 3.42687V2.25195H13.9928V3.42687H12.7487L11.7775 14.4638C11.751 14.7672 11.4969 15 11.1923 15H2.79176C2.48728 15 2.23328 14.7673 2.20665 14.464L1.2354 3.42687H0.0111084ZM3.32971 13.8251H10.6543L11.5693 3.42687H2.41483L3.32971 13.8251Z"
            fill="#D33131"
          />
          <path
            d="M0.0111084 2.25195V2.10195H-0.138892V2.25195H0.0111084ZM0.0111084 3.42687H-0.138892V3.57687H0.0111084V3.42687ZM13.9928 2.25195H14.1428V2.10195H13.9928V2.25195ZM13.9928 3.42687V3.57687H14.1428V3.42687H13.9928ZM12.7487 3.42687V3.27688H12.6114L12.5993 3.41373L12.7487 3.42687ZM11.7775 14.4638L11.628 14.4507L11.628 14.4508L11.7775 14.4638ZM2.20665 14.464L2.35607 14.4509L2.35607 14.4509L2.20665 14.464ZM1.2354 3.42687L1.38482 3.41373L1.37278 3.27688H1.2354V3.42687ZM10.6543 13.8251V13.9751H10.7916L10.8037 13.8382L10.6543 13.8251ZM3.32971 13.8251L3.18029 13.8382L3.19233 13.9751H3.32971V13.8251ZM11.5693 3.42687L11.7188 3.44002L11.7331 3.27688H11.5693V3.42687ZM2.41483 3.42687V3.27688H2.25105L2.26541 3.44002L2.41483 3.42687ZM-0.138892 2.25195V3.42687H0.161108V2.25195H-0.138892ZM13.9928 2.10195H0.0111084V2.40195H13.9928V2.10195ZM14.1428 3.42687V2.25195H13.8428V3.42687H14.1428ZM12.7487 3.57687H13.9928V3.27688H12.7487V3.57687ZM11.9269 14.477L12.8982 3.44002L12.5993 3.41373L11.628 14.4507L11.9269 14.477ZM11.1923 15.15C11.5746 15.15 11.8937 14.8578 11.9269 14.4768L11.628 14.4508C11.6084 14.6766 11.4191 14.85 11.1923 14.85V15.15ZM2.79176 15.15H11.1923V14.85H2.79176V15.15ZM2.05722 14.4772C2.09065 14.8579 2.40953 15.15 2.79176 15.15V14.85C2.56503 14.85 2.3759 14.6768 2.35607 14.4509L2.05722 14.4772ZM1.08597 3.44002L2.05722 14.4772L2.35607 14.4509L1.38482 3.41373L1.08597 3.44002ZM0.0111084 3.57687H1.2354V3.27688H0.0111084V3.57687ZM10.6543 13.6751H3.32971V13.9751H10.6543V13.6751ZM11.4199 3.41373L10.5048 13.8119L10.8037 13.8382L11.7188 3.44002L11.4199 3.41373ZM2.41483 3.57687H11.5693V3.27688H2.41483V3.57687ZM3.47913 13.8119L2.56425 3.41373L2.26541 3.44002L3.18029 13.8382L3.47913 13.8119Z"
            fill="white"
          />
        </g>
      </g>
      <path
        id="Path_3"
        d="M8.92099 0H5.08286C4.54298 0 4.10376 0.439219 4.10376 0.979102V2.83942H5.27868V1.17492H8.72517V2.83942H9.90009V0.979102C9.90009 0.439219 9.46087 0 8.92099 0Z"
        fill="#D33131"
      />
    </g>
  </svg>
);

export default Logo;
