import styled from "styled-components";
import { CustomNavLink } from "./CustomNavLink";
import { PRIMARY_COLOR } from "../constants";

const UserLoginButton = styled(CustomNavLink)`
  font-size: 14px;
  font-weight: 400;
  color: rgb(74, 74, 74) !important;
  text-decoration: underline;
  text-decoration-color: ${PRIMARY_COLOR};

  &:hover {
    color: rgb(74, 74, 74) !important;
  }
`;

export default UserLoginButton;
