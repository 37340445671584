import React from "react";

const Logo: React.FunctionComponent<{
  width?: string;
  height?: string;
}> = ({ width = "20", height = "20" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M87.5286 30.4429H57.8571V40.0143H73.1714L56.9 71.6L57.8571 79.2572H89.4429V68.7286H72.2143L87.5286 39.0572V30.4429Z"
      fill="white"
    />
    <path
      d="M10 49.2337C10 40.7433 10.9171 34.5127 12.7513 30.5419C14.5855 26.5711 17.015 23.4881 20.0398 21.2929C23.0968 19.0976 26.9421 18 31.5759 18C35.4051 18 38.4943 18.6457 40.8433 19.937C43.1924 21.196 45.3645 23.0523 47.3595 25.5058C49.3868 27.927 50.867 30.9454 51.8002 34.5611C52.7334 38.1445 53.2 42.9062 53.2 48.8463C53.2 61.0169 51.1727 69.4266 47.1182 74.0753C43.0959 78.6918 37.8829 81 31.4793 81C24.947 81 19.7341 78.6111 15.8404 73.8332C11.9468 69.0231 10 60.8232 10 49.2337ZM23.7564 45.5534V49.4274C23.7564 55.7871 23.9656 59.9839 24.3839 62.0177C24.8022 64.0192 25.6871 65.6011 27.0387 66.7633C28.4223 67.9254 30.0635 68.5065 31.962 68.5065C33.5066 68.5065 34.9225 67.9416 36.2096 66.8117C37.4968 65.6818 38.3978 63.9708 38.9126 61.6787C39.4275 59.3543 39.6849 55.2383 39.6849 49.3305C39.6849 44.0361 39.4597 40.2752 39.0092 38.0477C38.5908 35.7879 37.722 33.9639 36.4027 32.5757C35.0833 31.1875 33.4744 30.4935 31.5759 30.4935C28.8085 30.4935 26.8134 31.7041 25.5906 34.1253C24.3678 36.5142 23.7564 40.3236 23.7564 45.5534Z"
      fill="white"
    />
  </svg>
);

export default Logo;
