// Type definitions.
import { HomeProps } from "../components/pages/Home/Home";

// Dependencies
import React from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import cookie from "react-cookies";
import { useUser } from "../store/user/context";
import { PRIMARY_COLOR } from "../constants";

const URL = "https://us-central1-rentalios.cloudfunctions.net";
const clientId =
  "441046532427-qvrimgnl8tb2ebvkjrfu2fomcjkke5bi.apps.googleusercontent.com";

const styles = {
  navbar: {
    boxShadow: "0 2px 10px -1px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#ffffff",
    height: "9vh"
  },
  brand: {
    paddingLeft: "5px",
    fontSize: "19px",
    fontWeight: 900,
    color: PRIMARY_COLOR,
    verticalAlign: "middle"
  },
  login_background: {
    backgroundImage:
      "url('https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5ca3d02ba06ad52c415dfec9_Background-min.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  black_background: {
    backgroundColor: "#00000078",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  container: {
    height: "91vh"
  },
  login_modal: {
    background: "white",
    margin: "auto",
    display: "block",
    //width: "50%",
    padding: "20px",
    color: "#4a4a4a",
    textAlign: "center" as "center",
    fontFamily: "AvenirNext, Helvetica"
  },
  login_title: {
    fontWeight: "bold" as "bold",
    fontSize: "20px",
    marginBottom: "20px"
  },
  google_button: {
    background: "#d74937",
    border: "none",
    width: "100%",
    padding: "15px",
    marginBottom: "15px",
    color: "#FFF",
    fontWeight: "bold" as "bold"
  },
  facebook_button: {
    background: "#3c5a99",
    border: "none",
    width: "100%",
    padding: "15px",
    marginBottom: "15px",
    color: "#FFF",
    fontWeight: "bold" as "bold"
  }
};

const Home = () => {
  const { setUser } = useUser();
  const cookieMeta = {
    path: "/",
    domain:
      process.env.NODE_ENV === "development" ? "localhost" : "rentalios.com"
  };

  const responseGoogle = async response => {
    handleResponse(response, 2);
  };

  const responseFacebook = async response => {
    handleResponse(response, 1);
  };

  const handleResponse = async (response, app) => {
    const userID = app === 1 ? response.userID : response.googleId;
    const name =
      app === 1
        ? response.name
        : response.profileObj.givenName + " " + response.profileObj.familyName;
    const email = app === 1 ? response.email : response.profileObj.email;
    const picture =
      app === 1 ? response.picture.data.url : response.profileObj.imageUrl;

    let user_data = await axios.post(`${URL}/user_login`, {
      app,
      userID,
      name,
      email,
      picture
    });

    const userObject = JSON.stringify(user_data.data[0]);

    window.localStorage.setItem("user", userObject);
    window.localStorage.setItem("token", response.accessToken);

    cookie.save("rentalios_token", response.accessToken, cookieMeta);
    cookie.save("rentalios_user", userObject, cookieMeta);

    setUser(userObject);
    window.location.href =
      user_data.data[0].rentals === 0
        ? "/rent_info"
        : "https://www.rentalios.com";
  };

  return (
    <div style={styles.login_background}>
      <div style={styles.black_background}>
        <div className="container">
          <Row style={styles.container}>
            <Col md={5} xs={11} style={styles.login_modal}>
              <img
                src="https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5cd63beacbc64fad3ee35193_FAVICON%20256%20X%20256.svg"
                width="60px"
                height="60px"
                style={{
                  display: "block",
                  margin: "auto",
                  marginBottom: "20px"
                }}
              />
              <div style={styles.login_title}>Login to see more</div>
              <div
                style={{
                  textAlign: "center" as "center",
                  marginBottom: "20px"
                }}
              >
                Rentalios is the best way to compare room rates with other local
                listings
              </div>
              <div>
                <GoogleLogin
                  clientId={clientId}
                  buttonText="Continue with Google"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                  render={renderProps => (
                    <button
                      style={styles.google_button}
                      onClick={renderProps!.onClick}
                      // disabled={renderProps!.disabled}
                    >
                      Continue with Google
                    </button>
                  )}
                />
                <br />
                <FacebookLogin
                  appId="1278109152340180"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  textButton="Continue with Facebook"
                  render={renderProps => (
                    <button
                      style={styles.facebook_button}
                      onClick={renderProps.onClick}
                    >
                      Continue with Facebook
                    </button>
                  )}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Home;
