import styled, { keyframes } from "styled-components";
import React from "react";

const HeaderText = React.memo(styled.div`
  font-family: AvenirNext;
  font-size: 35px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
`);

const Subtitle = React.memo(styled.div`
  font-family: AvenirNext;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-bottom: 83px;
`);

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Circle = styled.div<{ color: string }>`
  animation: 1s ${rotation} linear infinite;
  width: 140px;
  height: 140px;
  border-radius: 280px;
  border: solid 7px;
  position: absolute;
  ${({ color }) => `border-color: ${color};`}
`;

const CirclePatch = React.memo(styled.div`
  width: 70px;
  height: 70px;
  background-color: white;
  position: absolute;
  top: -7px;
  left: -7px;
  z-index: 1;
`);

const Container = React.memo(styled.div`
  width: 140px;
  height: 140px;
  z-index: 1;
`);

const LogoContainer = React.memo(styled.div`
  color: #4a4a4a;
  z-index: 2;
`);

const LoadingText = React.memo(styled.div`
  width: 140px;
  height: 22px;
  font-family: AvenirNext, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  position: relative;
  z-index: 9;
`);

export {
  LogoContainer,
  Container,
  CirclePatch,
  Circle,
  Subtitle,
  HeaderText,
  LoadingText
};
