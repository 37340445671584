import React from "react";

const Logo: React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = "#66B015", width = "26", height = "26" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
  >
    <g id="printer-">
      <path
        id="Shape"
        d="M6.5 20.3125V25.1875H19.5V20.3125V17.0625H6.5V20.3125ZM8.125 18.6875H17.875V20.3125H8.125V18.6875ZM17.875 21.9375H8.125V23.5625H17.875V21.9375Z"
        fill={color}
      />
      <path
        id="Path"
        d="M19.5 5.6875V0.8125H6.5V5.6875V8.9375H19.5V5.6875Z"
        fill={color}
      />
      <path
        id="Path_2"
        d="M22.75 5.6875H21.125V8.9375V10.5625H4.875V8.9375V5.6875H3.25C1.625 5.6875 0 7.3125 0 8.9375V17.0625C0 18.6875 1.625 20.3125 3.25 20.3125H4.875V17.0625V15.4375H21.125V17.0625V20.3125H22.75C24.375 20.3125 26 18.6875 26 17.0625V8.9375C26 7.3125 24.375 5.6875 22.75 5.6875Z"
        fill={color}
      />
    </g>
  </svg>
);

export default Logo;
