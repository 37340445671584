import React from "react";

const Logo: React.FunctionComponent<{
  width?: string;
  height?: string;
  color?: string;
}> = ({ color = "#4E9CD0", width = "21", height = "20" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M16.646 18.0193C16.36 18.0193 16.1905 18.0193 16.021 18.0193C13.3197 18.0193 10.6184 18.0193 7.91718 18.0193C6.75192 18.0193 6.22226 17.5108 6.22226 16.3456C6.21167 13.6972 6.22226 11.0489 6.21167 8.40064C6.21167 7.54259 6.51887 6.81166 7.1015 6.19725C8.61633 4.64004 10.1418 3.09343 11.6566 1.53623C11.9532 1.22903 12.218 1.25021 12.5146 1.54682C14.0401 3.11462 15.5867 4.67182 17.1015 6.23962C17.6841 6.84343 17.9913 7.57437 17.949 8.44301C17.9066 9.34343 17.9278 10.2439 17.949 11.1443C17.9595 11.4409 17.8642 11.568 17.5782 11.6739C15.7244 12.3413 14.9405 14.2163 15.7456 16.0172C16.0104 16.6633 16.307 17.2883 16.646 18.0193ZM11.4023 13.3583C11.3388 13.3265 11.2858 13.2947 11.2223 13.2629C11.2329 13.1782 11.2329 13.0934 11.2434 13.0087C11.3282 11.7269 11.4871 10.4451 11.4765 9.16335C11.4765 7.97691 10.7668 7.62733 9.63328 8.15699C9.22014 8.34767 9.15659 8.66547 9.31548 9.0786C9.4532 9.42818 9.56972 9.80954 9.58031 10.1909C9.63328 11.4091 9.65447 12.6273 9.67565 13.835C9.69684 14.7142 10.0146 15.085 10.8727 14.9578C11.4871 14.8625 12.1651 14.6295 12.6418 14.2481C14.093 13.0828 14.9299 11.4939 15.343 9.69301C15.4384 9.30106 15.343 8.80318 15.1841 8.42182C14.9829 7.92394 14.4638 7.91335 13.9977 7.97691C13.5422 8.04047 13.6057 8.43242 13.5104 8.75021C13.235 9.65064 13.0019 10.5828 12.61 11.4409C12.324 12.1189 11.8155 12.7121 11.4023 13.3583Z"
      fill={color}
    />
    <path
      d="M5.67143 10.1909C5.67143 10.7947 5.67143 11.335 5.67143 11.8752C5.67143 13.2524 5.63965 14.6295 5.68203 16.0066C5.70321 16.6528 5.84093 17.299 5.93626 17.9981C5.95745 17.9981 5.83033 18.0299 5.69262 18.0299C4.7922 18.0299 3.89177 18.0405 2.99135 18.0299C1.67779 18.0193 0.660841 17.2036 0.45957 15.996C0.247705 14.7248 0.915078 13.6549 2.16508 13.1782C2.34516 13.1041 2.52525 12.871 2.5994 12.6803C3.18203 11.3244 4.1566 10.4981 5.67143 10.1909Z"
      fill="#CACF2E"
    />
    <path
      d="M18.4469 12.1084C20.1206 12.1084 21.3706 13.8351 20.7562 15.4664C20.2159 16.9177 19.5062 18.3054 18.8494 19.7037C18.6587 20.0957 18.2244 20.0957 18.0337 19.6931C17.3876 18.3054 16.6884 16.9283 16.1481 15.4982C15.502 13.8563 16.7414 12.1084 18.4469 12.1084ZM18.4151 13.3796C17.7583 13.3902 17.2181 13.9728 17.2392 14.619C17.271 15.244 17.8431 15.7948 18.4575 15.7737C19.0825 15.7525 19.6333 15.191 19.6333 14.566C19.6333 13.9092 19.0825 13.369 18.4151 13.3796Z"
      fill="#F9CA32"
    />
    <path
      d="M16.8791 0.550864C17.3028 0.349593 17.6312 0.116542 18.0019 0.0423897C18.6905 -0.0953222 19.2519 0.180102 19.6333 0.762729C20.0041 1.31358 20.0146 2.09747 19.5697 2.59536C18.8282 3.42163 18.0337 4.20553 17.2286 4.96824C17.1227 5.06358 16.7519 5.05298 16.646 4.95764C15.8727 4.21612 15.0994 3.464 14.379 2.65892C13.8176 2.04451 13.9024 1.08053 14.4744 0.487305C15.057 -0.105915 15.9574 -0.158881 16.6354 0.360186C16.7413 0.434339 16.8261 0.508491 16.8791 0.550864Z"
      fill="#CA2C37"
    />
  </svg>
);

export default Logo;
