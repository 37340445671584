import React from "react";

const Logo: React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = "#414850", width = "20px", height = "12px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 12"
    fill="none"
  >
    <path
      d="M13.6364 5.14286C15.1455 5.14286 16.3545 3.99429 16.3545 2.57143C16.3545 1.14857 15.1455 0 13.6364 0C12.1273 0 10.9091 1.14857 10.9091 2.57143C10.9091 3.99429 12.1273 5.14286 13.6364 5.14286ZM6.36364 5.14286C7.87273 5.14286 9.08182 3.99429 9.08182 2.57143C9.08182 1.14857 7.87273 0 6.36364 0C4.85455 0 3.63636 1.14857 3.63636 2.57143C3.63636 3.99429 4.85455 5.14286 6.36364 5.14286ZM6.36364 6.85714C4.24545 6.85714 0 7.86 0 9.85714V12H12.7273V9.85714C12.7273 7.86 8.48182 6.85714 6.36364 6.85714ZM13.6364 6.85714C13.3727 6.85714 13.0727 6.87429 12.7545 6.9C13.8091 7.62 14.5455 8.58857 14.5455 9.85714V12H20V9.85714C20 7.86 15.7545 6.85714 13.6364 6.85714Z"
      fill={color}
    />
  </svg>
);

export default Logo;
