import React, { FunctionComponent, CSSProperties } from "react";
import styled from "styled-components";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import { BLACK_COLOR, PRIMARY_COLOR, GRAY_COLOR } from "../constants";
import { t } from '../gaia/gaia';
type FormField = {
  value: string | number;
  variant?: "small" | "large";
  className?: string;
  style?: CSSProperties;
  onButtonClick?: (e: any) => void;
  onEnterPress?: () => void;
  onChange?: (e: any) => void;
};

type FiltersButtonType = {
  showFilter?: boolean;
  isClearFilterBtn?: boolean;
};

const RoomSizeField: FunctionComponent<FormField> = props => {
  return (
    <SelectInput className="w-100" {...props}>
      <option disabled value={-1}>
        Select
      </option>
      <option value={"null"}>-</option>
      <option value={0}>Small (70 - 125 sqft)</option>
      <option value={1}>Medium (125 - 150 sqft)</option>
      <option value={2}>Large (150 - 200 sqft)</option>
      <option value={3}>Extra Large (200 - 500 sqft)</option>
    </SelectInput>
  );
};

const LaundryField = (props: FormField) => {
  return (
    <SelectInput {...props} className="w-100">
      <option disabled value={-1}>
        Select
      </option>
      <option value={"null"}>-</option>
      <option value={0}>{t('No Laundry')}</option>
      <option value={1}>{t('In Unit Laundry')}</option>
      <option value={2}>{t('Laundry Hookups')}</option>
      <option value={3}>{t('On Building Laundry')}</option>
      <option value={4}>{t('On Site Laundry')}</option>{" "}
    </SelectInput>
  );
};

const FurnishingField = (props: FormField) => {
  return (
    <SelectInput {...props} className="w-100">
      <option disabled value={-1}>
        Select
      </option>
      <option value={"null"}>-</option>
      <option value={0}>{t('Unfurnished')}</option>
      <option value={1}>{t('Furnished')}</option>
    </SelectInput>
  );
};

const AccessibilityField = (props: FormField) => {
  return (
    <SelectInput {...props} className="w-100">
      <option disabled value={-1}>
        Select
      </option>
      <option value={"null"}>-</option>
      <option value={0}>Not Accessible</option>
      <option value={1}>Accessible</option>
    </SelectInput>
  );
};

const SmokingPolicyField = (props: FormField) => {
  return (
    <SelectInput {...props} className="w-100">
      <option disabled value={-1}>
        Select
      </option>
      <option value={"null"}>-</option>
      <option value={0}>{t('Non-Smoking')}</option>
      <option value={1}>{t('Smoking')}</option>
    </SelectInput>
  );
};

const ParkingField = (props: FormField) => {
  return (
    <SelectInput {...props} className="w-100">
      <option disabled value={-1}>
        Select
      </option>
      <option value={"null"}>-</option>
      <option value={0}>{t('No Parking')}</option>
      <option value={1}>{t('Off-street Parking')}</option>
      <option value={2}>{t('Street Parking')}</option>
    </SelectInput>
  );
};

const PetPolicyField = (props: FormField) => {
  return (
    <SelectInput {...props} className="w-100">
      <option disabled value={-1}>
        Select
      </option>
      <option value={"null"}>-</option>
      <option value={0}>{t('No Pets')}</option>
      <option value={1}>{t('Pet Friendly')}</option>
    </SelectInput>
  );
};

const PropertyTypeField = (props: FormField) => {
  return (
    <SelectInput {...props} className="w-100" >
      <option disabled value={-1}>
        Select
      </option>
      <option value={"null"}>-</option>
      <option value={0}>{t('Apartment')}</option>
      <option value={1}>{t('House')}</option>
    </SelectInput>
  );
};

const RoomsInput: FunctionComponent<FormField> = props => {
  return (
    <SelectInput className="w-100" {...props}>
      <option disabled value={-1}>
        Select
      </option>
      <option value={"null"}>-</option>
      <option value={0}>{t('Shared')}</option>
      <option value={1}>{t('Private')}</option>
    </SelectInput>
  );
};

const BathsInput: FunctionComponent<FormField> = React.memo(props => {
  return (
    <SelectInput className="w-100" {...props}>
      <option disabled value={-1}>
        Select
      </option>
      <option value={"null"}>-</option>
      <option value={0}>{t('Shared')}</option>
      <option value={1}>{t('Private')}</option>
    </SelectInput>
  );
});

const TotalBedroomsField = React.memo((props: FormField) => {
  return (
    <TextInput
      type="number"
      min={0}
      placeholder="Total bedrooms"
      className="w-100"
      {...props}
    />
  );
});

const TotalBathroomsField = (props: FormField) => {
  return (
    <TextInput
      {...props}
      type="number"
      min={0}
      step={0.5}
      placeholder="Total bathrooms"
      className="w-100"
    />
  );
};

const TargetPriceField = (props: FormField) => {
  return (
    <TextInput
      className="w-100"
      placeholder="Target Rent"
      type="number"
      {...props}
    />
  );
};

const FiltersButton = React.memo(styled.button<FiltersButtonType>`
  ${({ showFilter, isClearFilterBtn }) => {
    let finalCSS = ` color: ${PRIMARY_COLOR}; `;

    // CSS for coloring of the state of the button
    if (isClearFilterBtn !== true) {
      finalCSS += ` 
        border-color: ${PRIMARY_COLOR};
        &:hover { background-color: ${PRIMARY_COLOR}; color: white; } `;
    } else {
      finalCSS += `
        &:hover { color: ${PRIMARY_COLOR}; } `;
    }

    // CSS for showing or hiding the button
    // TODO: - This logic should be outside the button. It makes no sense to mix this App logic with
    // the rendering of a button.
    if (showFilter) {
      finalCSS += " display: none; ";
    }

    return finalCSS;
  }}
`);

// MARK: - Styled components

const MainContent = React.memo(styled.section`
  padding-top: 5rem;
  padding-bottom: 5rem;
`);

const ReportContainer = React.memo(styled.section`
  margin-top: 5rem;
  padding-top: 2rem;
  padding-left: 73px;
  padding-bottom: 5rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
`);

const MainTitle = React.memo(styled.h1`
  margin-top: 36px;
  font-family: AvenirNext, Helvetica;
  font-size: 35px;
  font-weight: bold;
  color: ${BLACK_COLOR};
`);

const ReportTitle = React.memo(styled.h1`
  margin-top: 5px;
  font-family: AvenirNext, Helvetica;
  font-size: 35px;
  font-weight: bold;
  color: ${GRAY_COLOR};
`);

const Description = React.memo(styled.p`
  font-family: AvenirNext, Helvetica;
  font-size: 20px;
  color: ${BLACK_COLOR};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`);

const AdvancedOptionsButton = React.memo(styled.button`
  font-family: AvenirNext, Helvetica;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${PRIMARY_COLOR};
  border: none;
  background-color: transparent;
  text-decoration: underline;
  outline: none !important
  cursor: pointer;
`);

export {
  PropertyTypeField,
  RoomsInput,
  BathsInput,
  RoomSizeField,
  TargetPriceField,
  TotalBedroomsField,
  TotalBathroomsField,
  LaundryField,
  FurnishingField,
  AccessibilityField,
  SmokingPolicyField,
  ParkingField,
  PetPolicyField,
  // Misc. components.
  MainContent,
  MainTitle,
  ReportTitle,
  ReportContainer,
  AdvancedOptionsButton,
  FiltersButton,
  Description
};
