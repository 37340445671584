import React, { useLayoutEffect, useState } from "react";

type WindowSizeType = [number, number];

function useWindowSize() {
  const [size, setSize] = useState<WindowSizeType>([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", updateSize);

    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size; // [Width, Height]
}

export default useWindowSize;
