import axios, { AxiosResponse } from "axios";
// import { MAPS_API_KEY } from "./config";
import { getCurrentPosition } from "../utilities";
// import { Coords } from "google-map-react";
import "../components/pages/Rentals/Rentals.d";
import { Comparable } from "../store/comparables/comparables";
import {
  ReverseGeocodingResult,
  Prediction,
  PredictInput,
  PredictResponseType,
  GeocodeResult,
  SearchInput,
  PredictRangeInput
} from "../types/api.d";
import {
  RoomProps
} from '../components/pages/RentalReport/Types'
import ct from "countries-and-timezones";
import { komodoUrl, Oauth1Helper } from "../config/komodo";

const BASE_URL = "https://us-central1-rentalios.cloudfunctions.net";

const SUPPORTED_COUNTRIES = {
  us: {
    countrycode: "us",
    language: "en"
  },
  id: {
    countrycode: "id",
    language: "id"
  }
};
const { us, id } = SUPPORTED_COUNTRIES;

const fetchGeocoderMatch = async (
  address: string,
  country: string = `${us.countrycode},${id.countrycode}`,
  language: string = "en"
) => {
  let location: any = await getCurrentPosition();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneDetail = ct.getTimezone(timezone);
  // West Tebet, Tebet, South Jakarta
  const params: any = {
    address,
    region: timezoneDetail ? timezoneDetail.country : undefined
  };
  if (location) {
    params.latlon = `${location.latitude},${location.longitude}`;
  }

  const request = {
    url: `${komodoUrl}/geocode?`,
    method: "GET"
  };

  const query = Object.keys(params)
    .map(key => key + "=" + params[key])
    .join("&");
  request.url = request.url + query;
  const authHeader = Oauth1Helper.getAuthHeaderForRequest(request);
  const response: AxiosResponse<GeocodeResult[]> = await axios.get(
    request.url,
    { headers: authHeader }
  );

  // Every search returns an array of possible matches. Leave the logic for deciding the
  // correct match to the component calling this API.
  const data = response.data;
  return data;
};

const fetchPredictionAndComparables = async (mappedParams: PredictInput) => {
  const request = {
    url: `${komodoUrl}/predict?`,
    method: "GET"
  };
  // delete params that doenst match komodo api
  let komodoParams = {
    ...mappedParams
  };

  // delete komodoParams.address;
  delete komodoParams.client_platform;
  delete komodoParams.currency_code;

  const query = Object.keys(komodoParams)
    .map(key => key + "=" + komodoParams[key])
    .join("&");
  request.url = request.url + query;
  const authHeader = Oauth1Helper.getAuthHeaderForRequest(request);
  const response: AxiosResponse<PredictResponseType> = await axios.get(
    request.url,
    { headers: authHeader }
  );

  return response;
};

const getPredictRange = async (params: PredictRangeInput) => {
  const request = {
    url: `${komodoUrl}/predict_range?`,
    method: "GET"
  };
  let cleaningParams = Object.keys(params).map(key => {
    if (params[key] != null) {
      return key + "=" + params[key];
    }
  });
  cleaningParams = cleaningParams.filter(a => a);
  const query = cleaningParams.join("&");
  request.url = request.url + query;
  const authHeader = Oauth1Helper.getAuthHeaderForRequest(request);
  const response = await axios.get(request.url, { headers: authHeader });
  return response;
};


const sendReport = async (params: PredictRangeInput, rooms: RoomProps[], address: String) => {
  const request = {
    url: `${komodoUrl}/report_predict_range?`,
    method: "POST"
  };
  let cleaningParams = Object.keys(params).map(key => {
    if (params[key] != null) {
      return key + "=" + params[key];
    }
  });
  cleaningParams = cleaningParams.filter(a => a);
  const query = cleaningParams.join("&");
  request.url = request.url + query + "&address=" + address;
  const authHeader = Oauth1Helper.getAuthHeaderForRequest(request);
  const response = await axios.post(request.url, { headers: authHeader, rooms: rooms, address: address });

  return response;
};

const fetchSearchRoom = async (params: SearchInput) => {
  const request = {
    url: `${komodoUrl}/search?`,
    method: "GET"
  };
  let cleaningParams = Object.keys(params).map(key => {
    if (
      (Array.isArray(params[key]) && params[key].length > 0) ||
      (!Array.isArray(params[key]) &&
        params[key] != null &&
        params[key] != "null" &&
        params[key] != "undefined" &&
        params[key] != "-1")
    ) {
      if (
        (key === "smoking" ||
          key === "wheelchair" ||
          key === "parking" ||
          key === "cats") &&
        params[key] == 0
      ) {
        return undefined;
      }
      return key + "=" + params[key];
    }
  });
  cleaningParams = cleaningParams.filter(a => a);
  const query = cleaningParams.join("&");
  request.url = request.url + query;
  const authHeader = Oauth1Helper.getAuthHeaderForRequest(request);
  const response = await axios.get(request.url, { headers: authHeader });
  return response;
};

const postRentals = (rentals: RentalForm[]) => {
  return axios.post(`${BASE_URL}/rentals`, {
    rentals
  });
};

const putRental = (rental: {}) => {
  return axios.put(`${BASE_URL}/rentals`, {
    rental
  });
};

const getRentals = (user_id: number) => {
  const options = { params: { user_id } };

  return axios.get(`${BASE_URL}/rentals`, options);
};

const delRentals = (del_id: number) => {
  const options = {
    params: { del_id }
  };
  return axios.delete(`${BASE_URL}/rentals`, options);
};

const postSearch = async ({
  searchParams,
  prediction,
  comparables
}: {
  searchParams: PredictInput;
  prediction: Prediction;
  comparables: Comparable[];
}) => {
  const body: PredictInput & {
    prediction: Prediction;
    comparables_json: string;
  } = {
    ...searchParams,
    prediction,
    comparables_json: JSON.stringify(comparables)
  };

  const response = await axios.post(`${BASE_URL}/searches`, body);

  return response;
};

const getReverseGeocode = async () => {
  let location: any = await getCurrentPosition();
  let reverseResult = await axios.get(
    `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${location.latitude}&longitude=${location.longitude}&localityLanguage=en`
  );
  return reverseResult.data;
};
const postUserLocation = async () => {
  try {
    let reverseGeocode: any = await getReverseGeocode();
    const url = "https://crowd-alerts.wl.r.appspot.com/postLocation";
    const body: any = {
      key: "tD@^DHBG5vFvr&7uUJxU!y6s",
      latitude: reverseGeocode.latitude,
      longitude: reverseGeocode.longitude,
      city: reverseGeocode.city ? reverseGeocode.city : reverseGeocode.locality,
      platform: "Web",
      source: "Rentalios",
      deviceId: "Browser"
    };
    axios.post(url, body);
  } catch (err) {
    console.error(err);
  }
};

export {
  fetchPredictionAndComparables,
  fetchSearchRoom,
  fetchGeocoderMatch,
  postRentals,
  putRental,
  getRentals,
  delRentals,
  postSearch,
  getPredictRange,
  sendReport
};
