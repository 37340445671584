import React from "react";

const Logo: React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = "#E0E0E0", width = "16px", height = "16px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M15.8045 14.8619L11.2545 10.3119C12.1359 9.22325 12.6665 7.83992 12.6665 6.33328C12.6665 2.84132 9.82522 0 6.33325 0C2.84128 0 0 2.84128 0 6.33325C0 9.82522 2.84132 12.6665 6.33328 12.6665C7.83992 12.6665 9.22325 12.1359 10.3119 11.2545L14.8619 15.8045C14.9919 15.9345 15.1625 15.9998 15.3332 15.9998C15.5039 15.9998 15.6745 15.9345 15.8045 15.8045C16.0652 15.5438 16.0652 15.1225 15.8045 14.8619ZM6.33328 11.3332C3.57597 11.3332 1.33333 9.09057 1.33333 6.33325C1.33333 3.57593 3.57597 1.3333 6.33328 1.3333C9.0906 1.3333 11.3332 3.57593 11.3332 6.33325C11.3332 9.09057 9.09057 11.3332 6.33328 11.3332Z"
      fill={color}
    />
  </svg>
);

export default Logo;
