import React from 'react'

const GreenCheck: React.FunctionComponent<{
  width?: string;
  height?: string;
  color?: string;
}> = ({ color = '#4A8D00', width = '25', height = '25' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M12.5 0.865234C5.876 0.865234 0.5 6.24123 0.5 12.8652C0.5 19.4892 5.876 24.8652 12.5 24.8652C19.124 24.8652 24.5 19.4892 24.5 12.8652C24.5 6.24123 19.124 0.865234 12.5 0.865234ZM10.1 18.8652L4.1 12.8652L5.792 11.1732L10.1 15.4692L19.208 6.36123L20.9 8.06523L10.1 18.8652Z"
      fill={color}
    />
  </svg>
)

export default GreenCheck
