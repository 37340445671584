import React, { FunctionComponent, useState } from "react";
import AddressMarkerIcon from "../../../assets/images/marker.svg";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../constants";

interface IAddressMarker {
  lat: number;
  lng: number;
  title: string;
  $hover?: boolean;
  handleHover?: () => void;
  handleTooltipClick?: () => void;
}

// Constants
const container = { height: 60, width: 60 };
const arrow = { height: 10, width: 12 };

const AddressMarkerImg = React.memo(styled.img`
  width: ${container.width}px;
  height: ${container.height}px;
`);

const HoverContainer = styled.div``;

const Arrow = styled.div<{ color: string }>`
  // Positioning
  position: absolute;
  bottom: -${arrow.height - 2}px;
  left: ${container.width / 3}px;

  // Triangle attributes
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 6px 0 6px;

  // Interaction attributes
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none !important;

  ${({ color = "black" }) =>
    `border-color: ${color} transparent transparent transparent;`}
`;

const ArrowLeft = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 5px 4px 0;
  border-color: transparent #414850 transparent transparent;
`;

const TooltipContainer = styled.div`
  z-index: 20;
  position: absolute;
  left: ${container.width}px;
  top: 0px;
  cursor: pointer;
`;

const Tooltip = styled.div`
  // Font
  font-family: AvenirNext;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  text-decoration: underline;

  // Tooltip Attributes
  width: 90px;
  height: ${container.height}px;
  background-color: #414850;
  padding: 0px 0.5rem 0 0.5rem;
`;

const HoverHandler = styled.div`
  width: ${container.width * 2}px;
  height: ${container.height * 2}px;
  position: absolute;
  top: -${container.height / 2}px;
  left: -${container.width / 2}px;
  border-radius: 20px;
  z-index: 1;
  // border: 1px solid red;
`;

const ArrowWithBorders = () => (
  <>
    <Arrow
      color="rgba(0, 0, 0, 0.5)"
      style={{ transform: "translateY(4px)" }}
    />
    <Arrow color={PRIMARY_COLOR} style={{ transform: "translateY(2px)" }} />
    <Arrow color="white" />
  </>
);

const AddressMarker: FunctionComponent<IAddressMarker> = props => {
  const [newHover, setNewHover] = useState(props.$hover);
  const handleHover = (e: any) => props.handleHover && props.handleHover();
  const showHover = () => setNewHover(true);
  const hideHover = () => setNewHover(false);
  const tooltipClickHandler = () => {
    hideHover();
    props.handleTooltipClick && props.handleTooltipClick();
  };

  return (
    <HoverContainer onMouseEnter={handleHover}>
      <AddressMarkerImg title={props.title} src={AddressMarkerIcon} />
      <HoverHandler onMouseEnter={showHover} onMouseLeave={hideHover} />

      {(newHover || props.$hover) && (
        <TooltipContainer
          className="d-flex"
          onClick={tooltipClickHandler}
          onMouseEnter={showHover}
          onMouseLeave={hideHover}
        >
          <ArrowLeft className="align-self-center" />
          <Tooltip className="marker_hover">
            <div className="d-flex h-100 align-items-center">
              <span className="w-100 text-center text-truncate">
                {props.title}
              </span>
            </div>
          </Tooltip>
        </TooltipContainer>
      )}
    </HoverContainer>
  );
};

export default AddressMarker;
