import React, { FunctionComponent, useState } from "react";
import GoogleMapReact from "google-map-react";
import { MAPS_API_KEY } from "../../../api/config";
import Marker from "./Marker";
import { Coords, RoomProps } from "./Types";
import AddressMarker from "./AddressMarker";

interface MapProps {
  coords: Coords;
  rooms: any[];
  zoom?: number;
  onMarkerClick?: Function;
  onDragMarkerEnd?: Function;
}

const Map: FunctionComponent<MapProps> = props => {
  const [draggable, setDraggable] = useState(true);
  const [coords, setCoords] = useState(props.coords);
  const [center, setCenter] = useState(props.coords);

  const onChildMouseUp = (childKey: any, childProps: any, mouse: any) => {
    if (childProps.draggable) {
      setDraggable(true);
      setCenter({ lat: mouse.lat, lng: mouse.lng });
      if (props.onDragMarkerEnd) {
        props.onDragMarkerEnd({ lat: mouse.lat, lng: mouse.lng });
      }
    }
  };

  const onMarkerClick = (index: number) => {
    if (props.onMarkerClick) {
      props.onMarkerClick(index);
    }
  };

  const onChildMouseMove = (hoverKey: any, childProps: any, mouse: any) => {
    setDraggable(false);
    if (childProps.draggable) {
      setCoords({ lat: mouse.lat, lng: mouse.lng });
    }
  };

  return (
    <div className="map-wrapper">
      <GoogleMapReact
        bootstrapURLKeys={{ key: MAPS_API_KEY, language: "en" }}
        defaultCenter={center}
        center={center}
        yesIWantToUseGoogleMapApiInternals
        zoom={props.zoom ? props.zoom : 15}
        onChildMouseMove={onChildMouseMove}
        onChildMouseUp={onChildMouseUp}
        draggable={draggable}
      >
        <AddressMarker draggable={true} lat={coords.lat} lng={coords.lng} />
        {props.rooms.map((item, index) => {
          return (
            <Marker
              lat={item.latitude}
              lng={item.longitude}
              onClick={() => onMarkerClick(index)}
              {...item}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};
export default Map;
