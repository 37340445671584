import React, { FunctionComponent, useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import Map from "../components/pages/Results/Map";
import { calculateMapBounds } from "../utilities";
import { isEmpty as _isEmpty } from "lodash";
import Marker from "../components/pages/Results/Marker";

const MapContainer = styled.div`
  height: 400px;
  width: 500px;
  background-color: lightblue;
`;

const comparables = [
  {
    address: "1620 The Alameda, San Jose, CA 95126, USA",
    latitude: 37.335783,
    longitude: -121.917171,
    type: "Apartment",
    sqft: "Medium (125 - 150 sqft)",
    room: "Shared",
    bath: "Shared",
    furnished: "Unfurnished",
    pet_friendly: 0,
    price: "$1,000",
    parking: "No Parking"
  },
  // {
  //   address: "184 Baypointe Dr, San Jose, CA 95134, USA",
  //   latitude: 37.4087,
  //   longitude: -121.9406,
  //   type: "Apartment",
  //   sqft: "Medium (125 - 150 sqft)",
  //   room: "Private",
  //   bath: "Private",
  //   furnished: "Unfurnished",
  //   pet_friendly: 0,
  //   price: "$800",
  //   parking: "No Parking"
  // },
  {
    address: "275 Pamela Ave, San Jose, CA 95116, USA",
    latitude: 37.366231,
    longitude: -121.847121,
    type: "Apartment",
    sqft: "Medium (125 - 150 sqft)",
    room: "Private",
    bath: "Private",
    furnished: "Unfurnished",
    pet_friendly: 0,
    price: "$1,075",
    parking: "No Parking"
  },
  // {
  //   address: "1545 Walkingshaw Way, San Jose, CA 95132, USA",
  //   latitude: 37.4031,
  //   longitude: -121.8585,
  //   type: "Apartment",
  //   sqft: "Medium (125 - 150 sqft)",
  //   room: "Private",
  //   bath: "Private",
  //   furnished: "Unfurnished",
  //   pet_friendly: 0,
  //   price: "$850",
  //   parking: "No Parking"
  // },
  {
    address: "Hedding & 8th, San Jose, CA 95112, USA",
    latitude: 37.35674,
    longitude: -121.896066,
    type: "Apartment",
    sqft: "Medium (125 - 150 sqft)",
    room: "Private",
    bath: "Private",
    furnished: "Unfurnished",
    pet_friendly: 0,
    price: "$1,500",
    parking: "No Parking"
  },
  {
    address: "300 E Mission St, San Jose, CA 95112, USA",
    latitude: 37.35464,
    longitude: -121.894466,
    type: "Apartment",
    sqft: "Medium (125 - 150 sqft)",
    room: "Private",
    bath: "Private",
    furnished: "Unfurnished",
    pet_friendly: 0,
    price: "$1,750",
    parking: "No Parking"
  },
  {
    address: "190 Ryland St, San Jose, CA 95110, USA",
    latitude: 37.341841,
    longitude: -121.897966,
    type: "Apartment",
    sqft: "Medium (125 - 150 sqft)",
    room: "Private",
    bath: "Private",
    furnished: "Unfurnished",
    pet_friendly: 0,
    price: "$1,197",
    parking: "No Parking"
  },
  {
    address: "26 Norton Ave, San Jose, CA 95126, USA",
    latitude: 37.3249,
    longitude: -121.9153,
    type: "Apartment",
    sqft: "Medium (125 - 150 sqft)",
    room: "Private",
    bath: "Private",
    furnished: "Unfurnished",
    pet_friendly: 0,
    price: "$928",
    parking: "No Parking"
  },
  {
    address: "424 N 9th St, San Jose, CA 95112, USA",
    latitude: 37.3476,
    longitude: -121.887,
    type: "Apartment",
    sqft: "Medium (125 - 150 sqft)",
    room: "Private",
    bath: "Private",
    furnished: "Unfurnished",
    pet_friendly: 0,
    price: "$560",
    parking: "No Parking"
  },
  {
    address: "424 N 9th St, San Jose, CA 95112, USA",
    latitude: 37.3476,
    longitude: -121.887,
    type: "Apartment",
    sqft: "Medium (125 - 150 sqft)",
    room: "Shared",
    bath: "Shared",
    furnished: "Unfurnished",
    pet_friendly: 0,
    price: "$500",
    parking: "Off-street Parking"
  }
];

const Test: FunctionComponent = () => {
  // Size of the map container...
  // TO-DO: Get the size of the HTML container of the map.
  const [size] = useState({
    width: 550, // Map width in pixels
    height: 400 // Map height in pixels
  });
  useEffect(() => {
    const map = document.getElementById("map-container");
    console.log({ map });
  }, [size]);

  const mapAttributes = useMemo(() => {
    // Create a flat array of just [lat, lng] objects.
    const coordsArray = comparables.map(({ latitude, longitude }) => [
      latitude,
      longitude
    ]);

    return calculateMapBounds(coordsArray, size);
  }, [comparables, size]);

  // Computed extended comparables. They are basically the same comparables but the
  // lat,lng are reverse geocoded to get a street address and added to the object from the array.
  const [extendedComparables] = useState(comparables);
  // TODO: - Disabled reverse geocoding because of cost
  // useEffect(() => {
  //   (async () => {
  //     const promisesArray = comparables.map(async comp => {
  //       const { latitude: lat, longitude: lng } = comp;
  //       const { data } = await fetchReverseGeocoding({ lat, lng });
  //       const [firstResult] = data.results;
  //       const addressString = firstResult.formatted_address;

  //       return { ...comp, address: addressString };
  //     });

  //     const resolvedArray = await Promise.all(promisesArray);

  //     setExtendedComparables(resolvedArray);
  //   })();
  // }, [comparables]);

  return (
    <div>
      <h1>This is the test</h1>
      <p>This is a main page?</p>
      <MapContainer>
        <Map
          // center={mapAttributes.center}
          center={mapAttributes.center}
          zoom={mapAttributes.zoom}
        >
          {!_isEmpty(extendedComparables) &&
            extendedComparables.map((comparable, index) => {
              const { latitude, longitude, price } = comparable;
              const key = `${latitude}-${longitude}-${index}`;

              return (
                <Marker
                  key={key}
                  title={price}
                  lat={latitude}
                  lng={longitude}
                  handleTooltipClick={() => {
                    console.log("You clicked listing", index);
                  }}
                />
              );
            })}
        </Map>
      </MapContainer>
    </div>
  );
};

export default Test;
