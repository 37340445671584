import React, { FunctionComponent, useState } from "react";
import DeleteIcon from "../../icons/Delete";
import PlusIcon from "../../icons/PlusCircle";
import Form from "antd/es/form";
import Select from "antd/es/select";
import { RoomProps, SearchProps } from "./Types";
const { Option } = Select;

interface Props {
  rooms: RoomProps[];
  setReportValue: Function;
}

const RoomInput: FunctionComponent<Props> = props => {
  const [rooms, setRooms] = useState(props.rooms);

  const setRoomValue = (index: number, value: number, field: string) => {
    let newRooms = [...rooms];
    newRooms[index][field] = value;
    setRooms(newRooms);
  };

  const deleteRoom = (event: any, index: number) => {
    event.preventDefault();
    if (rooms.length === 1) {
      alert("Need at least 1 room");
    } else {
      let newRooms = [...rooms];
      newRooms.splice(index, 1);
      setRooms(newRooms);
    }
  };
  const addRoom = event => {
    event.preventDefault();
    setRooms([
      ...rooms,
      {
        private_room: 0,
        private_bath: 0,
        furnished: 0
      }
    ]);
  };

  return (
    <div>
      <div className="bottom-input">
        <div>
          {rooms.map((item, index) => {
            return (
              <div className="border-btm" key={index}>
                <div className="room-title">
                  <span>Room {index + 1}</span>
                  <span
                    className="delete-room"
                    onClick={e => deleteRoom(e, index)}
                  >
                    <DeleteIcon /> Delete Room
                  </span>
                </div>
                <div className="amenities-input-wrapper">
                  <Form.Item label="Bedroom Type">
                    <Select
                      onBlur={props.setReportValue(rooms)}
                      value={item.private_room}
                      onChange={e => setRoomValue(index, e, "private_room")}
                    >
                      <Option value={0}>Shared</Option>
                      <Option value={1}>Private</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Bathroom Type">
                    <Select
                      onBlur={props.setReportValue(rooms)}
                      value={item.private_bath}
                      onChange={e => setRoomValue(index, e, "private_bath")}
                    >
                      <Option value={0}>Shared</Option>
                      <Option value={1}>Private</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Furnishing">
                    <Select
                      onBlur={props.setReportValue(rooms)}
                      value={item.furnished}
                      onChange={e => setRoomValue(index, e, "furnished")}
                    >
                      <Option value={0}>Unfurnished</Option>
                      <Option value={1}>Furnished</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            );
          })}
          <div style={{ position: "relative", height: "60px" }}>
            <button className="button-add-room" onClick={addRoom}>
              <PlusIcon /> <span style={{ marginLeft: "10px" }}>Add room</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RoomInput;
