import "../components/pages/RentalReport/rental.css";
import React, { useState, useEffect } from "react";
import TopInput from "../components/pages/RentalReport/StaticTopInput";
import RoomInput from "../components/pages/RentalReport/DynamicRoomInput";
import Result from "../components/pages/RentalReport/Result";
import validator from 'validator';
import cookie from "react-cookies";
import styled from "styled-components";
import { searchParamsToObject } from "../utilities";
import {
  RoomProps,
  SearchProps,
  ReportResultProps,
  ReportRoomPriceResultProps,
  ComparablesProps,
  ComparablesReportProps,
  TotalCalc,
  PredictRangeProps
} from "../components/pages/RentalReport/Types";
import {
  fetchPredictionAndComparables,
  fetchGeocoderMatch,
  getPredictRange,
  sendReport
} from "../api";
import Loading from "./Loading";
import {
  roomKeyToValue,
  laundryKeyToValue,
  furnishedKeyToValue,
  accessibilityKeyToValue,
  smokingKeyToValue,
  parkingKeyToValue,
  petKeyToValue,
  bedBathKeyToValue
} from "../helper/PredictInputToValue";
import { numberToCurrency, getDistance, getCurrencyCode } from "../utilities";
import { PredictRangeInput } from "../types/api";
import moment from "moment";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from 'antd/es/modal';
import GreenCheck from '../components/icons/GreenCheck';

const initialRooms: RoomProps[] = [
  {
    private_room: 0,
    private_bath: 0,
    furnished: 0
  }
];
const initialInput: SearchProps = {
  house_type: 0,
  laundry: -1,
  parking: -1,
  cats: -1,
  wheelchair: -1,
  smoking: -1,
  furnished: -1
};
const initialResult: ReportResultProps = {
  address: "",
  propertyType: "",
  laundry: "",
  accessibility: "",
  smokingPolicy: "",
  parking: "",
  petPolicy: ""
};
const initialRoomPriceResult: ReportRoomPriceResultProps[] = [];
const initialTotalCalc: TotalCalc = {
  totalMin: 0,
  totalMax: 0,
  totalAvg: 0
};
const initialComparables: ComparablesProps[] = [];
const initialPredictRanges: PredictRangeProps[] = [];

const calcPercentChange = (prev: number, curr: number) => {
  let changed = ((curr - prev) / curr) * 100;
  let result = {
    changed: isNaN(changed) ? "-" : `${changed.toFixed(2)}%`,
    trend:
      isNaN(changed) || changed == 0 ? "netral" : changed > 0 ? "up" : "down"
  };

  return result;
};

const getDaysOld = (run_id: any) => {
  let now = moment();
  let past = moment(run_id);
  let dayDiff = now.diff(past, "days");
  if (dayDiff > 1) {
    return `${dayDiff}`;
  }
  return `${dayDiff}`;
};

const RentalReport = props => {
  const setInputValue = inputs => {
    setInputs(inputs);
  };

  const setRoomValue = rooms => {
    setRooms(rooms);
  };

  const [reportInput, setInputs] = useState(initialInput);
  const [reportRooms, setRooms] = useState(initialRooms);
  const [reportResult, setResult] = useState(initialResult);
  const [reportComparables, setComparables] = useState(initialComparables);
  const [reportRoomPriceResult, setRoomPriceResult] = useState(
    initialRoomPriceResult
  );
  const [haveResult, setHaveResult] = useState(false);
  const [searching, setSearching] = useState(false);
  const [totalCalc, setTotalCalc] = useState(initialTotalCalc);
  const [predictRanges, setPredictRanges] = useState(initialPredictRanges);
  const [requestSent, setRequestSent] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");

  const isLoggedIn = cookie.load("rentalios-email") ? true : false;
  const queryObj: any = searchParamsToObject(window.location.search);
  const isNoHeader = queryObj.noHeader === 'true';

  useEffect(() => {
    if(!isNoHeader && !isLoggedIn) {
      window.open("/", "_self");
    }
    setEmailAddress(cookie.load("rentalios-email"));
  }, []);

  const generateReport = async inputs => {
    if(emailAddress == undefined || !validator.isEmail(emailAddress)){
      alert("Incorrect email.");
    }
    else if (!reportInput.address) {
      alert("Missing address field.");
    } else {

      let coordinates = await getLatLng(reportInput.address);
      
      getPredictRangeResult(coordinates);
      setRequestSent(true)
      
      /*
      let predictRangeResult = []
      setPredictRanges(predictRangeResult);
      let rooms: any = [];
      let promises: any = [];
      for (let i = 0; i < reportRooms.length; i++) {
        let current = reportRooms[i];
        let room = {
          ...current,
          ...reportInput,
          longitude: coordinates.lng,
          latitude: coordinates.lat
        };
        rooms.push(room);
        promises.push(getPredict(room));
      }

      Promise.all(promises).then((values: any[]) => {
        let result: ReportResultProps = {
          address: reportInput.address!,
          propertyType: roomKeyToValue(reportInput.house_type!),
          laundry: laundryKeyToValue(reportInput.laundry!),
          accessibility: accessibilityKeyToValue(reportInput.wheelchair!),
          smokingPolicy: smokingKeyToValue(reportInput.smoking!),
          parking: parkingKeyToValue(reportInput.parking!),
          petPolicy: petKeyToValue(reportInput.cats!)
        };
        let roomPriceResults: ReportRoomPriceResultProps[] = [];
        let max = 0,
          min = 0,
          avg = 0;
        let roomComparabales: ComparablesProps[] = [];
        for (let i = 0; i < values.length; i++) {
          if (values[i]) {
            let prediction = values[i].prediction;
            let currency = values[i].currency;
            let roomPrice: ReportRoomPriceResultProps = {
              avgPrice: prediction.price,
              minPrice: prediction.minimum,
              maxPrice: prediction.maximum,
              currencyCode: currency.currency_code,
              furnished: furnishedKeyToValue(reportRooms[i].furnished!),
              bedroomType: bedBathKeyToValue(reportRooms[i].private_room!),
              bathroomType: bedBathKeyToValue(reportRooms[i].private_bath!)
            };
            roomPriceResults.push(roomPrice);
            max = max + prediction.maximum;
            min = min + prediction.minimum;
            avg = avg + prediction.price;
            let comparables: ComparablesReportProps[] = values[
              i
            ].comparables.map((a, index) => {
              let comparable: ComparablesReportProps = {
                number: index + 1,
                address: a.address,
                propertyType: roomKeyToValue(a.house_type!),
                laundry: laundryKeyToValue(a.laundry!),
                accessibility: accessibilityKeyToValue(a.wheelchair!),
                smokingPolicy: smokingKeyToValue(a.smoking!),
                parking: parkingKeyToValue(a.parking!),
                petPolicy: petKeyToValue(a.pet_friendly!),
                price: numberToCurrency(a.price.toString(), a.currency_code),
                bedroomType: bedBathKeyToValue(a.private_room),
                bathroomType: bedBathKeyToValue(a.private_bath),
                daysOld: getDaysOld(a.run_id),
                distance: getDistance(a.currency_code, a.distance)
              };
              return comparable;
            });
            let comparablesProps: ComparablesProps = { data: comparables };
            roomComparabales.push(comparablesProps);
          }
        }
        if (roomComparabales.length > 0) {
          setComparables(roomComparabales);
          setTotalCalc({
            totalAvg: avg,
            totalMax: max,
            totalMin: min
          });
          setRoomPriceResult(roomPriceResults);
          setResult(result);
          setHaveResult(true);
          setSearching(false);
        } else {
          setHaveResult(false);
          setSearching(false);
          alert("Sorry, we do not have data for that address yet.");
        }
      });
      */
    }
  };

  const getLatLng = async (address: string) => {
    let geoCodeResult = await fetchGeocoderMatch(address);
    let coordinates = geoCodeResult[0].coordinates;
    return coordinates;
  };

  const getPredictRangeResult = async coordinates => {
    // get predict range
    let rangePredictions: PredictRangeProps[] = [];
    try {
      let today = moment().format("YYYY-MM-DD");
      let lastYear = moment()
        .subtract(12, "months")
        .format("YYYY-MM-DD");
      let predictRangeParams: PredictRangeInput = {
        longitude: coordinates.lng,
        latitude: coordinates.lat,
        start_date: lastYear,
        end_date: today,
        interval: 31,
        sqft: 1,
        house_type: reportInput.house_type,
        laundry: reportInput.laundry,
        parking: reportInput.parking,
        cats: reportInput.cats,
        dogs: reportInput.cats,
        furnished: reportInput.furnished,
        smoking: reportInput.smoking,
        wheelchair: reportInput.wheelchair,
        emailAddress: emailAddress,
        currencyCode: getCurrencyCode(),
      
      };
      sendReport(predictRangeParams, reportRooms, reportInput.address ? reportInput.address : '');
      //
      /*
      let response = await getPredictRange(predictRangeParams);
      
      if (response.status === 200) {
        const [{ predictions, currency }] = response.data;
        rangePredictions = predictions.map((a, i, array) => {
          let percentChange = calcPercentChange(
            i > 0 ? array[i - 1].price : a.price,
            a.price
          );
          console.log(a)
          return {
            month: `${moment(a.date).format("MMMM")} ${moment(a.date).format(
              "YYYY"
            )}`,
            percentChange: percentChange.changed,
            trend: percentChange.trend,
            highest:
              a.maximum != "NaN"
                ? numberToCurrency(a.maximum.toString(), currency.currency_code)
                : "-",
            lowest:
              a.minimum != "NaN"
                ? numberToCurrency(a.minimum.toString(), currency.currency_code)
                : "-",
            rentEstimate:
              a.price != "NaN"
                ? numberToCurrency(a.price.toString(), currency.currency_code)
                : "-",
            price: a.price,
            currencyCode: currency.currency_code,
            xLabel: `${moment(a.date).format("MMM")}`
          };
        });
      }
      console.log("range predictions ", rangePredictions)
      return rangePredictions;*/
    } catch (err) {
      return rangePredictions;
    }
  };

  const getPredict = async inputs => {
    try {
      const response = await fetchPredictionAndComparables(inputs);
      if (response.status === 200) {
        const [{ comparables, currency, prediction }] = response.data;
        return {
          comparables: comparables,
          currency: currency,
          prediction: prediction
        };
      }
    } catch (err) {
      return null;
    }
  };

  return (
    <div>
      {searching ? (
        <Loading />
      ) : (!isLoggedIn && !isNoHeader) ? '' : haveResult ? (
        <div>
          <Result
            roomDetail={reportResult}
            roomPrices={reportRoomPriceResult}
            totalPrice={totalCalc}
            comparables={reportComparables}
            predictRanges={predictRanges}
            onBack={() => setHaveResult(false)}
          />
        </div>
      ) : (
        <div>
          <div className="rental-report-wrapper">
            <div className="rental-report-inner">
              <span className="report-title">New Rental Report</span>
              <div>
                <TopInput inputs={reportInput} setReportValue={setInputValue} />
              </div>
              <div>
                <RoomInput rooms={reportRooms} setReportValue={setRoomValue} />
              </div>
              <div className="rental-email">
                <div className="border-btm">
                  <div className="room-title">
                    <span>Send report as email*</span>
                  </div>
                  <Form.Item label="Email Address">
                    <Input
                      style={{ height: "30px", marginRight: "40px", marginTop: "3px" }}
                      placeholder="Enter an email address to recieve this report as an email"
                      onChange={e => setEmailAddress(e.target.value)}
                      value={emailAddress}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="create-report-wrapper">
                <button
                  onClick={generateReport}
                  className="create-report-button"
                >
                  Create Report
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ModalStyled closable={false} footer={false} width={424} visible={requestSent}>
        <div className='modal-report-title'>
          Rental Request
        </div>
        <div className="info-wrapper">
          <div>
            <GreenCheck />
          </div>
          <div>
            <div>
              Your request for the report was succesfully sent. 
            </div>
            <div>
              You will receive an email in the next 24 hours.
            </div>
          </div>
        </div>
        <div className="footer-btn-wrapper">
          <button onClick={() => setRequestSent(false)} className="accept-btn">
            Accept
          </button>
        </div>

      </ModalStyled>
    </div>
  );
};
export default RentalReport;


const ModalStyled = styled(Modal)`
  .modal-report-title {
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 16px;
    color: #66b015;
    padding-bottom: 24px;
    border-bottom: 0.5px solid #D8DEE4;
    margin: 0 -24px;
    padding-left: 24px;
  }
  .info-wrapper {
    display: flex;
    align-items: center;
    padding: 24px 0 24px 24px;
    gap: 24px;
    border-bottom: 0.5px solid #D8DEE4;
    margin: 0 -24px;
  }
  .footer-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 24px 0;

    .accept-btn {
      background: #66B015;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #FFFFFF;
      width: 192px;
      height: 48px;
      border: none;
    }
  }
`