import React from 'react'
import useWindowSize from '../hooks/useWindowSize';
import { LG_SCREEN_BREAKPOINT } from '../constants';
import './SearchesTable/css/SearchesTable.css'

export default function Pagination({ goToNext, goToPrev, pages }) {
    const [width] = useWindowSize(); 
    const isMobile = React.useMemo(
        () => window.innerWidth <= LG_SCREEN_BREAKPOINT,
        [width]
    )

    return (        
        <div className="d-flex justify-content-center mb-2" style={isMobile ? {position: "relative", top: "-325px"} : {}}>            
            {goToPrev && <button onClick={goToPrev} className="mr-4 actionButton">&#8592;</button>}
            {pages.pageOne && <button onClick={goToPrev} className="mr-4 paginationButtonSelected"> {pages.pageOne} </button>}
            {pages.pageTwo && goToNext && <button onClick={goToNext} className="mr-4 paginationButton"> {pages.pageTwo} </button>}
            {goToNext && <button onClick={goToNext} className="actionButton">&#8594;</button>}
        </div>
    )
}
