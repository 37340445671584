import { FiltersReducerAction, FiltersState, Actions } from "./state.d";
import { Coords } from "google-map-react";

const isCoords = (x: any): x is Coords => x && x.lat && x.lng;

function reducer(
  state: FiltersState,
  action: FiltersReducerAction
): FiltersState {
  const { type, payload }: { type: Actions; payload: any } = action;

  switch (type) {
    case "setAddress":
      return { ...state, address: payload };

    case "setUserType":
      return { ...state, userType: payload };

    case "setPropertyType":
      return { ...state, propertyType: payload };

    case "setPropertyType":
      return { ...state, propertyType: payload };

    case "setRoom":
      return { ...state, selectedRoom: payload };

    case "setBath":
      return { ...state, selectedBath: payload };

    case "setLaundry":
      return { ...state, laundry: payload };

    case "setFurnishing":
      return { ...state, furnishing: payload };

    case "setAccessibility":
      return { ...state, accessibility: payload };

    case "setSmokingPolicy":
      return { ...state, smokingPolicy: payload };

    case "setParking":
      return { ...state, parking: payload };

    case "setPetPolicy":
      return { ...state, petPolicy: payload };

    case "setSqft":
      return { ...state, sqft: payload };

    case "setTargetPrice":
      return { ...state, targetPrice: payload };

    case "setTotalBedrooms":
      return { ...state, totalBedrooms: payload };

    case "setTotalBathrooms":
      return { ...state, totalBathrooms: payload };

    case "resetFilters":
      return {
        ...state,
        propertyType: -1,
        sqft: -1,
        targetPrice: "",
        totalBedrooms: "",
        totalBathrooms: "",
        selectedRoom: -1,
        selectedBath: -1,
        laundry: -1,
        furnishing: -1,
        accessibility: -1,
        smokingPolicy: -1,
        parking: -1,
        petPolicy: -1
      };

    case "setCoords":
      if (isCoords(payload)) return { ...state, coords: payload };

    // This is a special case only to merge the internal State
    // with the URL query params. This SHOULD not be used in any other case.
    case "mergeStates":
      return { ...state, ...payload };
  }

  // Catch-all option.
  console.warn("Warning: No changes from the state reducer.");
  return state;
}

const initialState: FiltersState = {
  roomsArray: [{}],
  reportType: "Normal",
  address: "",
  userType: "Estimate Rent",
  propertyType: -1,
  sqft: -1,
  targetPrice: "",
  totalBedrooms: "",
  totalBathrooms: "",
  selectedRoom: -1,
  selectedBath: -1,
  laundry: -1,
  furnishing: -1,
  accessibility: -1,
  smokingPolicy: -1,
  parking: -1,
  petPolicy: -1,
  coords: null,
  currencySymbol: "$"
};

export { reducer, initialState };
