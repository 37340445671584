import React from 'react'
import TableHead from './TableHead'
import TableBody from './TableBody'
import { LG_SCREEN_BREAKPOINT } from '../../constants'
import useWindowSize from '../../hooks/useWindowSize';

export default function Table( {listing, element, setNewSearch} ) {                    
    const [width] = useWindowSize(); 
    const isMobile = React.useMemo(
        () => window.innerWidth <= LG_SCREEN_BREAKPOINT,
        [width]
    )

    return (
        <div className="table-responsive" style={isMobile ? {position: "relative", top: "-325px"} : {}}>
            <table className="table">
                <TableHead />                
                <TableBody 
                    searches={listing} 
                    element = {element}
                    setNewSearch = {setNewSearch}
                />                
            </table>
        </div>
    )
}
