import React from 'react'
import SearchInfo from './SearchInfo'

export default function TableBody( {searches, element, setNewSearch} ) {
    return (
        <tbody style={{background: "white"}}>
            {searches.map(search => (
                <SearchInfo 
                    key={search.id}
                    search={search} 
                    searchedID={element.id}                    
                    setNewSearch = {setNewSearch}
                />           
            ))}                            
        </tbody>       
    )
}
