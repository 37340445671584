import React from 'react'
import './css/SearchesTable.css'

export default function SearchInfo( {search, searchedID, setNewSearch} ) {
    function isSelected() {
        if(search.id === searchedID) { return "selectedSearch"}
        return ""
    }

    return (
        <tr className={isSelected()}>
            <td>{search.address}</td>
            <td>{search.house_type}</td>
            <td>{search.private_room}</td>
            <td>{search.private_bath}</td>
            <td>{search.laundry}</td>
            <td>{search.furnished}</td>
            <td>{search.accessible}</td>
            <td>{search.smoking}</td>
            <td>{search.parking}</td>
            <td>{search.pets}</td>
            <td>{search.time_of_search}</td>
            <td>{search.price}</td>                 
            <td><button onClick={() => setNewSearch(search.id)} className="viewMarkerButton">View</button></td>
        </tr>
    )
}
