import React, { useState, FunctionComponent, useMemo } from "react";
import {
  Navbar,
  Row,
  Col,
  ModalDialog,
  Form,
  Button,
  Alert
} from "react-bootstrap";
import styled from "styled-components";
import RentalForm from "../components/pages/Rentals/RentalForm";
import styles from "../components/pages/Rentals/RentRegistry.style";
import { useUser } from "../store/user/context";
import { postRentals, fetchGeocoderMatch } from "../api";
import { HomeProps } from "../components/pages/Home/Home";

type RentalFormType = {
  address: string;
  rent: string;
  property: string;
  size: string;
  bedroom: string;
  bathroom: string;
  furnishing: string;
};

const BrandName = React.memo(styled.div`
  padding-left: 5px;
  font-family: AvenirNext, Helvetica;
`);

const RentalsRegistry: FunctionComponent<HomeProps> = props => {
  const [rental_forms, set_rental_forms] = useState<RentalFormType[]>([
    {
      address: "",
      rent: "",
      property: "",
      size: "",
      bedroom: "",
      bathroom: "",
      furnishing: ""
    }
  ]);
  const { user, setUser } = useUser();
  const [user_type, set_user_type] = useState();
  const [validated, set_validated] = useState(false);

  const isLoggedIn = useMemo(() => !!user, [user]);

  // Merges the rental object in the index with this new data.
  const updateForm = (index: number, payload: RentalForm) => {
    rental_forms[index] = { ...rental_forms[index], ...payload };
    const new_forms = [...rental_forms];
    set_rental_forms(new_forms);
  };

  function add_rent() {
    if (rental_forms.length < 10) {
      const new_forms = [
        ...rental_forms,
        {
          address: "",
          rent: "",
          property: "",
          size: "",
          bedroom: "",
          bathroom: "",
          furnishing: ""
        }
      ];
      set_rental_forms(new_forms);
    }
  }

  function rmv_rent(i) {
    if (rental_forms.length > 1) {
      const new_forms = [
        ...rental_forms.slice(0, i),
        ...rental_forms.slice(i + 1, rental_forms.length)
      ];
      set_rental_forms(new_forms);
    }
  }

  function user_type_handler(type) {
    set_user_type(type);
    document.getElementById("user_choice")!.style.display = "none";
    document.getElementById("user_rent")!.style.display = "flex";
  }

  async function handleFormSubmission(event) {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === true) {
      // Start loading spinner here
      // pleaseWait(true)
      let invalid_address = true;
      let invalid_address_name;

      // Check if the user is logged in.
      if (isLoggedIn && user) {
        const promisesArray = rental_forms.map(async (form: RentalForm) => {
          // Fetch the address with reverse geocoding from Gmaps
          // Get [lat, lng] returned
          const address = form.address;
          const address_data = await fetchGeocoderMatch(address!);
          const [firstAddress] = address_data;

          let [lat, lng]: number[] | string[] = [0, 0];
          if (firstAddress) {
            [lat, lng] = [
              firstAddress.coordinates.lat,
              firstAddress.coordinates.lng
            ];
          } else {
            invalid_address = false;
            invalid_address_name = address;
          }

          // Get data from the user object.
          const user_id = user.id;

          const extendedObject = {
            furnished: form.furnishing,
            private_room: form.bedroom,
            private_bath: form.bathroom,
            address: form.address,
            room_size: form.size,
            house_type: form.property,
            price: form.rent,
            lat,
            lng,
            user_id,
            type: user_type
          };
          return extendedObject;
        });

        // Wait for all promises to finish
        const extendedForms = await Promise.all(promisesArray);

        // API call goes here.
        try {
          if (invalid_address) {
            console.log(extendedForms);
            const response = await postRentals(extendedForms);
            console.log({ response });
            window.location.href = "https://www.rentalios.com";
          } else {
            alert(
              "The address '" + invalid_address_name + "' does not exists."
            );
          }
        } catch (error) {
          console.error(error);
          document.getElementById("alert_error")!.style.display = "block";
        }
      } else {
        alert("You need to be logged in to do this.");
      }

      // Stop loading spinner here
      // pleaseWait(false)
    } else {
      set_validated(true);
      console.log(validated);
    }
  }

  function handleDismiss() {
    document.getElementById("alert_error")!.style.display = "none";
  }

  let alert_error = (
    <Col
      md={{ span: 6, offset: 3 }}
      style={styles.alert_error}
      id="alert_error"
    >
      <Alert
        variant="danger"
        onClose={() => {
          handleDismiss();
        }}
        dismissible
      >
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <p>
          Something went wrong while processing the information. Please try
          again in a few minutes, if the error perisists, please contact support
          for more information.
        </p>
      </Alert>
    </Col>
  );

  return (
    <div style={styles.login_background}>
      <div style={styles.black_background}>
        <ModalDialog
          id="user_choice"
          className="container"
          centered
          style={{ margin: "0% auto" }}
        >
          <Col style={styles.choice_modal}>
            <img
              src="https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5cf96f8722a3cb8d41386ed6_pencil-edit-button.svg"
              width="40px"
              height="40px"
              style={{ display: "block", margin: "auto", marginBottom: "20px" }}
            />
            <div style={styles.login_title}>Edit your profile</div>
            <div
              style={{ textAlign: "center" as "center", marginBottom: "20px" }}
            >
              Are you a landlord or tenant?
            </div>
            <div>
              <button
                style={styles.choice_button}
                onClick={() => {
                  user_type_handler(0);
                }}
              >
                Landlord
              </button>
              <button
                style={styles.choice_button}
                onClick={() => {
                  user_type_handler(1);
                }}
              >
                Tenant
              </button>
            </div>
            <a style={styles.skip_button} href="https://www.rentalios.com">
              <div style={styles.skip_button}>Skip ></div>
            </a>
          </Col>
        </ModalDialog>
        <ModalDialog
          id="user_rent"
          className="container"
          size="lg"
          style={{ display: "none", margin: "0% auto", padding: "50px 0px" }}
        >
          <Col style={styles.choice_modal}>
            <img
              src="https://uploads-ssl.webflow.com/5c932b56a572a26625a724d6/5cf96f86995e8c01e088b9b6_closed-door-with-border-silhouette.svg"
              width="40px"
              height="40px"
              style={{ display: "block", margin: "auto", marginBottom: "20px" }}
            />
            <div style={styles.login_title}>Do you rent a room?</div>
            <div
              style={{ textAlign: "center" as "center", marginBottom: "20px" }}
            >
              Share how much you currently rent your room for. Your information
              will help us better estimate rents in the future.
            </div>
            <Form
              id="rentals_form"
              noValidate
              onSubmit={e => handleFormSubmission(e)}
              validated={validated}
            >
              <div
                style={{
                  overflowY: "scroll",
                  overflowX: "hidden",
                  height: "240px",
                  maxHeight: "240px"
                }}
              >
                {rental_forms.map((form_data, index) => {
                  let buttons = (
                    <>
                      <Col
                        md={{ span: 4, offset: 5 }}
                        style={styles.add_button}
                        onClick={add_rent}
                      >
                        Add another property
                      </Col>
                      <Col
                        md={3}
                        style={styles.min_button}
                        onClick={() => {
                          rmv_rent(index);
                        }}
                      >
                        Delete property
                      </Col>
                    </>
                  );
                  if (rental_forms.length == 1) {
                    buttons = (
                      <Col
                        md={{ span: 4, offset: 8 }}
                        style={styles.add_button}
                        onClick={add_rent}
                      >
                        Add another property
                      </Col>
                    );
                  } else if (index < rental_forms.length - 1) {
                    buttons = (
                      <Col
                        md={{ span: 3, offset: 9 }}
                        style={styles.min_button}
                        onClick={() => {
                          rmv_rent(index);
                        }}
                      >
                        Delete property
                      </Col>
                    );
                  }
                  return (
                    <div key={index}>
                      <RentalForm
                        data={form_data}
                        handleDataChange={payload => updateForm(index, payload)}
                      />
                      <Row style={{ marginTop: "20px" }}>{buttons}</Row>
                      <hr />
                    </div>
                  );
                })}
              </div>

              <Button type="submit" style={styles.submit_button}>
                Submit
              </Button>
            </Form>
          </Col>
        </ModalDialog>
      </div>
      {alert_error}
    </div>
  );
};

export default RentalsRegistry;
