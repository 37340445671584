import React, {
  FunctionComponent,
  CSSProperties,
  useState,
  useRef
} from "react";
import LocationArrow from "./icons/LocationArrow";
import styled, { keyframes } from "styled-components";
import usePosition from "../hooks/usePosition";
import { PRIMARY_COLOR, PRIMARY_DARK_COLOR } from "../constants";
import { useAppState } from "../store/app/state";
import { handlePredictionExecution } from "../utilities";
import { useComparables } from "../store/comparables/context";
import { useResults } from "../store/results/context";
import analytics from "../api/analytics";
import { FiltersState } from "../store/app/state.d";
import { useHistory } from "react-router-dom";
import { t } from "../gaia/gaia";
const INPUT_HEIGHT = 44;

const SearchButton = React.memo(styled.button`
  border: none;
  background-color: ${PRIMARY_COLOR};
  outline: none !important;
  width: 150px;
  transition: background-color 200ms;
  position: relative

  &:hover {
    background-color: ${PRIMARY_DARK_COLOR};
  }
`);

const EstimateLabel = React.memo(styled.p`
  color: white;
  font-size: 15px;
  font-weight: bold;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`);

const LocationButton = React.memo(styled.button`
  border: none;
  outline: none !important;
  width: ${INPUT_HEIGHT}px;
  background-color: transparent;
  z-index: 1;
  transition: filter 200ms;

  &:hover {
    filter: brightness(0.7);
  }
`);

const AddressContainer = React.memo(styled.div<{ variant?: string }>`
  height: ${INPUT_HEIGHT}px;
  border-radius: 9px !important;
  -webkit-appearance: none;
  border: solid 1px #979797;
  outline: none;
  padding: 0px 30px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  font-family: AvenirNext;
  font-size: 18px;
  position: relative;

  input {
    color: #4a4a4a;
    outline: none;
  }

  input + label {
    position: absolute;
    left: 0px;
    bottom: 7px;
    color: #7d7d7d;
    cursor: text;
    padding: 0px 30px;
    margin: 0;

    &.current_location_label {
      text-align: left;
      color: ${PRIMARY_COLOR};
      background-color: white;
      width: 100% !important;
    }
  }

  ${props => {
    if (props.variant === "small")
      return `
        height: 31px;
        font-size: 14px;
        padding: 0px 20px;

        &::placeholder {
          font-size: 14px;
        }
      `;
  }}
`);

// MARK: - Animated icon

const pulse = keyframes`
  0% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.11);
  }
  100% {
    opacity: 0.8;
    transform: scale(1);
  }
`;

const AnimatedIcon = styled.div`
  animation: 1s ${pulse} ease-in infinite;
`;

const AddressInput: FunctionComponent<{
  value: string;
  report: boolean;
  setValue: (val: string) => void;
  onEnterPress: () => void;
  className?: string;
  style?: CSSProperties;
}> = props => {
  const {
    value,
    report,
    setValue,
    className = "",
    style,
    onEnterPress,
    ...rest
  } = props;
  const { appState, dispatchAppState } = useAppState();
  const { comparables, setComparables } = useComparables();
  const { results, setResults } = useResults();
  const { position, positionError } = usePosition();
  const [usingCurrentLocation, setUsingCurrentLocation] = useState(false);
  const addressInputRef = useRef<HTMLInputElement>(null);
  const [location, setLocation] = useState<string>();
  const [waiting, setWaiting] = useState(false);
  const history = useHistory();

  function keyPressHandler(e) {
    if (e.key == "Enter") {
      onEnterPress && onEnterPress();
    }
  }

  function switchFocusToInput() {
    if (addressInputRef && addressInputRef.current) {
      addressInputRef.current.focus();
    }
  }

  // MARK: - Geolocation from browser logic

  function handleGetLocation() {
    // console.log("clicked");
    console.log({ position, positionError });

    // Check if the location has already been retrieved and refill the input field with this data
    // instead of calling the Reverse Geocoding API again
    if (location) {
      setUsingCurrentLocation(true);
      setValue(location);
      return;
    }

    setWaiting(true);
    if (positionError) {
      // Handle error
      setWaiting(false);
      alert(
        "Cannot determine current location. Please allow the website to use your current location or try again later."
      );
      return;
    } else if (position) {
      try {
        const coords = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        // // DEV ONLY
        // const coords = {
        //   lat: 42.348049,
        //   lng: -71.099731
        // };

        const displayName = Object.values(coords).join(",");

        setUsingCurrentLocation(true);
        setValue(displayName);
        setLocation(displayName); // Save location from the response for possible later use
      } catch (error) {
        console.error(error);
      }
    } else {
      throw "Undefined behavior.";
    }

    setWaiting(false);
  }

  function handleClearCurrentLocation() {
    // Clear the state
    setUsingCurrentLocation(false);
    setValue("");

    // Switch focus to the input
    switchFocusToInput();
  }

  // MARK: - Logic to execute search

  // Checks the form for the required fields and highlights missing required fields.
  // Return true or false depending on form completion.
  function checkForm(state: FiltersState) {
    // Check if an address was given.
    if (!state.address) {
      return false;
    } else {
      return true;
    }
  }

  async function executeEstimation() {
    if (checkForm(appState)) {
      try {
        // Clear current values. This triggers the Loading indicator.
        setResults({});

        // Then execute the search.
        await handlePredictionExecution({
          state: appState,
          dispatch: dispatchAppState,
          setResults: setResults,
          setComparables: setComparables,
          ...props,
          history: history
        });

        // Send action execution to GA
        analytics.estimateActionEvent();
      } catch (error) {
        // Handle errors.
        console.error(error);
        history.push("/");
        alert(error);
      }
    } else {
      // setErrorsVisibility(true);
      alert("Missing the address field.");
    }
  }

  return (
    <AddressContainer
      className={`d-flex ${className}`}
      style={{ ...style, paddingRight: 0, overflow: "hidden", width: "100%" }}
    >
      {/* Input field */}
      <input
        ref={addressInputRef}
        className="w-100 h-100"
        style={{ border: "none" }}
        value={value}
        onKeyPress={keyPressHandler}
        onChange={e => setValue(e.target.value)}
        {...rest}
      />

      {/* Pseudo-placeholder label */}
      {value === "" && (
        <label
          data-localizer="detect"
          className="placeholder_label"
          onClick={switchFocusToInput}
        >
          {t("Enter Address or Zip Code")}{" "}
        </label>
      )}

      {/* Label indicating that the current location is being used */}
      {usingCurrentLocation && (
        <label
          className="current_location_label"
          title={"The value of this field is set to your current location"}
          onClick={handleClearCurrentLocation}
        >
          {t("Current Location")}
        </label>
      )}

      {/* Arrow button to get current location */}
      <LocationButton
        onClick={handleGetLocation}
        title="Autofill with current location"
        className="px-2"
      >
        {waiting ? (
          <AnimatedIcon title="Getting location...">
            <LocationArrow />
          </AnimatedIcon>
        ) : (
          <LocationArrow />
        )}
      </LocationButton>
    </AddressContainer>
  );
};

export default AddressInput;
