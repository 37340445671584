import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { MAPS_API_KEY } from '../../api/config'
import AddressMarker from '../pages/Results/AddressMarker'
import './css/SearchesMap.css'
import { LG_SCREEN_BREAKPOINT } from '../../constants'
import useWindowSize from '../../hooks/useWindowSize'

export default function SearchesMap( { element }) {
    const [actualMarker, setMarker] = useState(element)    
    const [width] = useWindowSize(); 
    const isMobile = React.useMemo(
        () => window.innerWidth <= LG_SCREEN_BREAKPOINT,
        [width]
    )       
    const defaultProps = {
        defaultCenter: {     
            lat: 37.360825,
            lng: -121.949902 
        },
        zoom: 14     
    }
    
    useEffect(() => {
        setMarker(element)        
    }, [element])

    function setCenterMap(lat, lng) {
        return {
            lat: Number(lat),
            lng: Number(lng)
        }
    }

    function getCountry(code) {
        switch(code) {
            case "USD": return "United States"
            case "IDR": return "Indonesia"
            case "MXN": return "Mexico"
            default: return "Unknown"                
        }
    }

    return (        
        <>            
            <div style={{ height: '65vh', width: '100%' }} className="py-3">            
                <GoogleMapReact
                    bootstrapURLKeys={{ key: MAPS_API_KEY, language: 'en' }}
                    defaultCenter={defaultProps.defaultCenter}
                    center={setCenterMap(actualMarker.lat, actualMarker.lng)}
                    defaultZoom={defaultProps.zoom}
                    zoom = {14}
                    hoverDistance={40 / 2}
                >       
                    <AddressMarker 
                        key = {actualMarker.id}
                        title = {actualMarker.address}
                        lat = {Number(actualMarker.lat)}
                        lng = {Number(actualMarker.lng)}
                    ></AddressMarker>
                </GoogleMapReact>
            </div>

            <div className={isMobile ? "overlay-container-mobile": "overlay-container"}>
                <div className={ isMobile? "overlayUser-mobile" : "overlayUser"}>
                    <p className="title-user">User</p><hr/>
                    <p className="option-user">SEARCH LOCATION</p>
                    <p className="option-user-value">{getCountry(element.currency_code)}</p>
                    <p className="option-user">SEARCH TIME</p>
                    <p className="option-user-value">{element.time_of_search}</p>
                </div>                
                <div className={ isMobile ? "overlayEstimate-mobile" : "overlayEstimate"}>
                    <p className="title">ESTIMATES</p>
                    <p className="option">Minimum .... <span className="option-value">{element.min}</span></p>
                    <p className="option"><b>Average .... <span className="option-average">{element.price}</span></b></p>
                    <p className="option">Maximum .... <span className="option-value">{element.max}</span></p>         
                </div>
            </div>

            {/*<div style={{position: "static"}}>
                <div className={isMobile ? "overlayMobile ":"overlay"}>
                    <p className="title">ESTIMATES</p>
                    <p className="option">Minimum .... <span className="option-value">{element.min}</span></p>
                    <p className="option"><b>Average .... <span className="option-average">{element.price}</span></b></p>
                    <p className="option">Maximum .... <span className="option-value">{element.max}</span></p>                
                </div>                                    
            </div>*/}                               
      </>
    )
}
  