import React from "react";

const Logo: React.FunctionComponent<{
  width?: string;
  height?: string;
  color?: string;
}> = ({ color = "#14C7F5", width = "17px", height = "21px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 17 21"
    fill="none"
  >
    <path
      d="M15.9352 7.32086L8.37272 1.10884C8.15665 0.946792 7.9946 0.946792 7.77853 1.10884L0.270088 7.32086C0.108035 7.48292 0 7.64497 0 7.86104V20.5552C0 20.8253 0.162053 20.9873 0.43214 20.9873H15.7191C15.9892 20.9873 16.1512 20.8253 16.1512 20.5552V7.86104C16.2053 7.64497 16.0972 7.48292 15.9352 7.32086ZM13.7205 18.8806H2.48481V13.0467C2.86293 12.2365 3.61918 11.7503 4.64551 11.7503C5.88791 11.7503 6.64416 12.5065 7.02228 13.803C7.67049 16.1257 9.18298 17.3681 11.3437 17.3681C12.37 17.3681 13.1803 17.1521 13.7745 16.7199V18.8806H13.7205ZM13.7205 13.803C13.3423 14.6132 12.5861 15.0994 11.5598 15.0994C10.2633 15.0994 9.50709 14.3431 9.18298 13.0467C8.58879 10.67 7.0763 9.42755 4.86158 9.42755C3.83525 9.42755 3.02498 9.64362 2.43079 10.0758V8.6713L8.04862 3.97177L13.7205 8.6713V13.803Z"
      fill={color}
    />
  </svg>
);

export default Logo;
