import React, { FunctionComponent, useState } from "react";
import Top from "./Top";
import RoomList from "../RoomList";
import StickyMap from "../../../icons/StickyMap";
import StickyList from "../../../icons/StickyList";
import Map from "../Map";
import { Coords, RoomProps } from "../Types";

interface SearchMobileProps {
  isMobile: boolean;
  mapCoords: Coords;
  rooms: any;
  searchInput: any;
  onSearchRoom: Function;
  showModal: Function;
  onDragMarkerEnd: Function;
}

const SearchMobile: FunctionComponent<SearchMobileProps> = props => {
  const initialRoomDetail: RoomProps = {
    price: "",
    currency_code: "",
    address: "",
    image: "",
    href: "",
    latitude: 0,
    longitude: 0,
    run_id: "",
    source: ""
  };
  const [showingMap, setShowMap] = useState(false);
  const [showingRoomDetail, setShowRoomDetail] = useState(false);
  const [roomDetail, setRoomDetail] = useState(initialRoomDetail);

  const showMap = () => {
    setShowMap(!showingMap);
    setShowRoomDetail(false);
  };

  const showRoomDetail = (roomDetails: RoomProps) => {
    setRoomDetail(roomDetails);
    setShowRoomDetail(true);
  };

  return (
    <div className="search-mobile">
      <div>
        <Top
          searchInput={props.searchInput}
          onSearchRoom={props.onSearchRoom}
        />
      </div>
      {showingMap ? (
        <div>
          <Map
            onMarkerClick={showRoomDetail}
            zoom={12}
            coords={props.mapCoords}
            rooms={props.rooms}
            onDragMarkerEnd={props.onDragMarkerEnd}
          />
        </div>
      ) : (
        <div>
          {props.rooms.map((item, index) => {
            return (
              <RoomList
                datePublished={item.run_id}
                price={item.price}
                image={item.image}
                address={item.address}
                href={item.href}
                source={item.source}
                isMobile={props.isMobile}
                showModal={props.showModal}
              />
            );
          })}
        </div>
      )}
      <div className="sticky">
        <div onClick={showMap} className="mobile-sticky">
          {showingMap ? (
            <div>
              <StickyList />
              <span style={{ marginLeft: "10px" }}>List</span>
            </div>
          ) : (
            <div>
              <StickyMap />
              <span style={{ marginLeft: "10px" }}>Map</span>
            </div>
          )}
        </div>
        {showingMap && showingRoomDetail ? (
          <div style={{ background: "white" }}>
            <RoomList
              datePublished={roomDetail.run_id}
              price={roomDetail.price}
              image={roomDetail.image}
              address={roomDetail.address}
              href={roomDetail.href}
              source={roomDetail.source}
              showModal={props.showModal}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
export default SearchMobile;
