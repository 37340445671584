import React, { FC } from "react";
import styled from "styled-components";
import { PRIMARY_COLOR, PRIMARY_DARK_COLOR } from "../../constants";

const CX_MAX = 20.313;
const CX_MIN = 6.5;

const MAX_1 = -14;
const MAX_2 = 13;
const MAX_3 = -6.5;

const Dot = styled.ellipse<{ position: number }>`
  background-color: red;
  transition: transform 200ms ease-out;

  ${props => {
    return `transform: translateX(${props.position}px);`;
  }};
`;

const FilterIcon: FC<{
  toggled: boolean;
  width?: string;
  height?: string;
}> = ({ toggled = false, width = "26", height = "28" }) => {
  const DotOne = React.useMemo(() => {
    return (
      <>
        <Dot
          cx={CX_MAX}
          cy="4"
          fill={toggled ? PRIMARY_DARK_COLOR : PRIMARY_COLOR}
          rx="4.063"
          ry="4"
          position={toggled ? MAX_1 : 0}
        />
        <Dot
          cx={CX_MAX}
          cy="4"
          fill="#fff"
          rx="2.438"
          ry="2.4"
          position={toggled ? MAX_1 : 0}
        />
      </>
    );
  }, [toggled]);

  const DotTwo = React.useMemo(() => {
    return (
      <>
        <Dot
          cx={CX_MIN}
          cy="13.6"
          fill={toggled ? PRIMARY_DARK_COLOR : PRIMARY_COLOR}
          rx="4.063"
          ry="4"
          position={toggled ? MAX_2 : 0}
        />
        <Dot
          cx={CX_MIN}
          cy="13.6"
          fill="#fff"
          rx="2.438"
          ry="2.4"
          position={toggled ? MAX_2 : 0}
        />
      </>
    );
  }, [toggled]);

  const DotThree = React.useMemo(() => {
    return (
      <>
        <Dot
          cx="16.25"
          cy="24"
          fill={toggled ? PRIMARY_DARK_COLOR : PRIMARY_COLOR}
          rx="4.063"
          ry="4"
          position={toggled ? MAX_3 : 0}
        />
        <Dot
          cx="16.25"
          cy="24"
          fill="#fff"
          rx="2.438"
          ry="2.4"
          position={toggled ? MAX_3 : 0}
        />
      </>
    );
  }, [toggled]);

  return (
    <svg
      height={height}
      viewBox="0 0 26 28"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        {/* Dot 1 */}
        <rect
          fill={toggled ? PRIMARY_DARK_COLOR : PRIMARY_COLOR}
          height="3.2"
          rx="1.6"
          width="26"
          y="2.4"
        />
        {DotOne}

        {/* Dot 2 */}
        <rect
          fill={toggled ? PRIMARY_DARK_COLOR : PRIMARY_COLOR}
          height="3.2"
          rx="1.6"
          width="26"
          y="12"
        />
        {DotTwo}

        {/* Dot 3 */}
        <rect
          fill={toggled ? PRIMARY_DARK_COLOR : PRIMARY_COLOR}
          height="3.2"
          rx="1.6"
          width="26"
          y="22.4"
        />
        {DotThree}
      </g>
    </svg>
  );
};

export default FilterIcon;
