import OAuth from "oauth-1.0a";
import hmacsha1 from "hmacsha1";
const komodoUrl = "https://komodo.rentalios.com";

const CONSUMERKEY = "5OwhzHbRRmoddjOSZVWvjM2xsyoGgfkPlEWj3LH4";
const CONSUMERSECRET = "nD90vsNKd79m2TtWg2CNUy1gDbB19r8xyJ22LOhxTs2Ivi58IH";
const TOKENKEY = "ye0GUKBxCNI9cWaKNhOpK4HIkM7nHt";
const TOKENSECRET = "egNUJdf6fFcctMhWXqsdcWTdYGMGUQ";

class Oauth1Helper {
  static getAuthHeaderForRequest(request) {
    const oauth = new OAuth({
      consumer: { key: CONSUMERKEY, secret: CONSUMERSECRET },
      signature_method: "HMAC-SHA1",
      hash_function(base_string, key) {
        return hmacsha1(key, base_string);
      }
    });

    const authorization = oauth.authorize(request, {
      key: TOKENKEY,
      secret: TOKENSECRET
    });

    return oauth.toHeader(authorization);
  }
}

export { komodoUrl, Oauth1Helper };
