import React, { useState, useEffect } from "react";
import { MainContent } from "../components/Fields";
import SearchErrorIcon from "../components/icons/SearchErrorIcon";
import serverTimeOutIcon from "../assets/images/serverTimeOutIcon.svg";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import {
  HeaderText,
  Subtitle,
  LogoContainer,
  Container,
  GoBackButton
} from "../components/pages/Error";
import { PRIMARY_COLOR } from "../constants";
import BottomInput from "../components/pages/Error/BottomInput";
import BottomMap from "../components/pages/Error/BottomMap";
import { t } from "../gaia/gaia";

function ErrorPage(props) {
  const history = useHistory();
  function handleGoBack() {
    history.push("/");
  }
  function handleTryAgain() {
    history.goBack();
  }
  let errorCode = _.get(props, "location.state.errorCode", 500); // default to be server error
  if (errorCode === 204 || props.noRooms) {
    return (
      <MainContent className="container text-center">
        <Container className="d-flex align-self-center mx-auto">
          <LogoContainer className="align-self-center text-center w-100">
            <SearchErrorIcon height="150px" width="132px" />
          </LogoContainer>
        </Container>
        <HeaderText style={{ fontSize: "24px" }}>
          {t("Sorry, we do not have data for that address yet.")}
        </HeaderText>
        {props.noRooms ? (
          <div>
            <Subtitle>{t("Not available in your country? ")}</Subtitle>
            <Subtitle>
              <a
                href="https://www.rentalios.com/#contact"
                style={{ color: "#66B015" }}
              >
                Contact us
              </a>
              {t(" to start in your country as well")}
            </Subtitle>
          </div>
        ) : (
          <div>
            <Subtitle>
              {t("Not available in your country? ")}{" "}
              <a
                href="https://www.rentalios.com/#contact"
                style={{ color: "#66B015" }}
              >
                Contact us
              </a>
              {t(" to start in your country as well")}
            </Subtitle>
          </div>
        )}
        {props.noRooms ? (
          ""
        ) : (
          <div>
            <div>
              <BottomInput {...props} />
            </div>
            <div style={{ paddingTop: "150px" }}>
              <BottomMap />
            </div>
          </div>
        )}
      </MainContent>
    );
  } else {
    return (
      <MainContent className="container text-center">
        <Container className="d-flex align-self-center mx-auto">
          <LogoContainer className="align-self-center text-center w-100">
            <img
              src={serverTimeOutIcon}
              style={{ height: "150px", width: "132px" }}
            />
          </LogoContainer>
        </Container>
        <HeaderText>{t("Connection has timed out")}</HeaderText>
        <Subtitle>
          {t(
            "Our server is taking too long to respond. Please try again in a few minutes."
          )}
        </Subtitle>
        <div className="d-flex">
          <GoBackButton className="mt-5 mx-auto" onClick={handleTryAgain}>
            {t("Try again")}
          </GoBackButton>
        </div>
      </MainContent>
    );
  }
}

export default ErrorPage;
