import React from "react";

const Logo: React.FunctionComponent<{
  width?: string;
  height?: string;
}> = ({ width = "19", height = "27" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 19 27"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16607 0.689453C11.5701 0.689453 13.8305 1.63673 15.5304 3.35655C18.6765 6.53882 19.0675 12.5264 16.3664 16.1688L9.16607 26.6895L1.955 16.154C-0.735309 12.5264 -0.344341 6.53882 2.8017 3.35655C4.50168 1.63673 6.7616 0.689453 9.16607 0.689453ZM5.96371 9.71113C5.96371 11.5435 7.43793 13.0349 9.24915 13.0349C11.0604 13.0349 12.5346 11.5435 12.5346 9.71113C12.5346 7.87878 11.0604 6.38735 9.24915 6.38735C7.43793 6.38735 5.96371 7.87878 5.96371 9.71113Z"
      fill="#66B015"
    />
  </svg>
);

export default Logo;
