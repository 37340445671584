import React from 'react'
import SearchingTable from '../../components/SearchesTable/SearchingTable'

export default function Searches() {
    return (
        <>                        
            <div className="container-fluid">                
                <SearchingTable />
            </div>
        </>
    )
}
