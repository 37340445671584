import axios from "axios";

const api = axios.create({
  // baseURL:
});

const MAPS_API_KEY = "AIzaSyCaBYe0SHLJmkay0T9PW3qf-EPxsU_5Mfg";
const MAILGUN_API_KEY = "key-66b72d5ce489d48dfe022fa0c84dcfa3";

export { MAPS_API_KEY, MAILGUN_API_KEY };
