import React, { FunctionComponent } from "react";
import { ReportRoomPriceResultProps, TotalCalc } from "../Types";
import { numberToCurrency } from "../../../../utilities";

interface Props {
  rooms: ReportRoomPriceResultProps[];
  totalPrice: TotalCalc;
}

const Component: FunctionComponent<Props> = props => {
  return (
    <div className="report-breakdown-section bottom-line">
      <div>
        <span className="report-section-title">Breakdown</span>
      </div>
      <div>
        <div>
          <table className="breakdown-table">
            <thead>
              <tr>
                <th></th>
                <th>Min. total rent</th>
                <th style={{ color: "#66B015" }}>Est. total rent</th>
                <th>Max. total rent</th>
              </tr>
            </thead>
            <tbody>
              {props.rooms.map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      borderBottom: "solid 1px #979797",
                      borderTop: "solid 1px #979797"
                    }}
                  >
                    <td style={{ padding: "15px" }}>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: 800,
                          paddingBottom: "17px"
                        }}
                      >
                        Room {index + 1}
                      </div>
                      <div className="d-flex">
                        <div className="report-detail">
                          <span className="input-title-param">Bedroom:</span>
                          <span>{item.bedroomType}</span>
                        </div>
                        <div className="report-detail">
                          <span className="input-title-param">Bathroom:</span>
                          <span>{item.bathroomType}</span>
                        </div>
                        <div className="report-detail">
                          <span className="input-title-param">Furnishing:</span>
                          <span>{item.furnished}</span>
                        </div>
                      </div>
                    </td>
                    <td
                      className="estimated-price"
                      style={{ borderRight: "none" }}
                    >
                      <div>
                        {numberToCurrency(
                          item.minPrice.toString(),
                          item.currencyCode
                        )}
                      </div>
                    </td>
                    <td className="estimated-price average">
                      <div>
                        {numberToCurrency(
                          item.avgPrice.toString(),
                          item.currencyCode
                        )}
                      </div>
                    </td>
                    <td className="estimated-price">
                      <div>
                        {numberToCurrency(
                          item.maxPrice.toString(),
                          item.currencyCode
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td style={{ position: "relative" }}>
                  <div className="total-estimated-result">RESULTS</div>
                </td>
                <td className="estimated-price" style={{ borderRight: "none" }}>
                  <div>
                    {numberToCurrency(
                      props.totalPrice.totalMin.toString(),
                      props.rooms[0].currencyCode
                    )}
                  </div>
                </td>
                <td
                  className="estimated-price average"
                  style={{ color: "#66B015" }}
                >
                  <div>
                    {numberToCurrency(
                      props.totalPrice.totalAvg.toString(),
                      props.rooms[0].currencyCode
                    )}
                  </div>
                </td>
                <td className="estimated-price">
                  <div>
                    {numberToCurrency(
                      props.totalPrice.totalMax.toString(),
                      props.rooms[0].currencyCode
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Component;
