import React, { useState } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Map from "../components/pages/SearchResults/Map";
import TopInput from "../components/pages/SearchResults/TopInput";
import RoomList from "../components/pages/SearchResults/RoomList";
import "../components/pages/SearchResults/search.css";
import { fetchSearchRoom, fetchGeocoderMatch } from "../api";
import Loading from "./Loading";
import { numberToCurrency } from "../utilities";
import { Coords, RoomProps } from "../components/pages/SearchResults/Types";
import ErrorPage from "../pages/ErrorPage";
import { useMediaQuery } from "react-responsive";
import SearchMobile from "../components/pages/SearchResults/mobile/SearchMobile";
import NavigationModal from "../components/pages/Results/NavigationModal";

interface SearchProps {
  page?: Number;
  address?: String;
  page_size?: Number;
  house_type?: string;
  laundry?: number;
  private_room?: number;
  private_bath?: number;
  min_price?: number;
  max_price?: number;
  wheelchair?: number;
  smoking?: number;
  cats?: number;
  parking?: number;
}

interface QuerySearchProps {
  address?: String;
  propertyType?: string;
  targetPrice?: number;
  selectedRoom?: number;
  selectedBath?: number;
  laundry?: number;
  furnishing?: number;
  accessibility?: number;
  smokingPolicy?: number;
  parking?: number;
  petPolicy?: number;
}

function paramsToObject(entries) {
  const result: QuerySearchProps = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const getRooms = async inputs => {
  let geoCodeResult = await fetchGeocoderMatch(inputs.address);
  let coordinates = geoCodeResult[0].coordinates;
  // call api
  if (coordinates) {
    inputs.ll = `${coordinates.lng},${coordinates.lat}`;
  }
  const response = await fetchSearchRoom(inputs);
  return {
    coordinates: coordinates,
    rooms: response.data.data.map(a => {
      return {
        ...a,
        price: numberToCurrency(a.price.toString(), a.currency_code)
      };
    })
  };
};

const initialSearch: SearchProps = {};

const ResultPage = props => {
  const initialRooms: RoomProps[] = [];
  const initialCoords: Coords = { lat: 37.360825, lng: -121.949902 };
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });

  const [rooms, setRooms] = useState(initialRooms);
  const [noRooms, setNoRooms] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchInput, setInputs] = useState(initialSearch);
  const [mapCoords, setCoordinates] = useState(initialCoords);
  const [showNavigationModal, setNavigationModal] = useState(false);
  const [modalSource, setModalSource] = useState("");

  const queryString = window.location.search;
  const onSearchRoom = async inputs => {
    setSearching(true);
    setNoRooms(false);
    // get lat and lng from address
    try {
      let response = await getRooms(inputs);
      setCoordinates({
        lat: Number(response.coordinates.lat),
        lng: Number(response.coordinates.lng)
      });
      setRooms(response.rooms);
      if (response.rooms.length === 0) {
        setNoRooms(true);
      }
      setInputs(inputs);
      setSearching(false);
    } catch (error) {
      // Handle errors.
      props.history.push("/error");
    }
  };

  const onDragMarkerEnd = (coords:any) => {
    let newInput = { ...searchInput };
    newInput.address = `${coords.lat}, ${coords.lng}`;
    onSearchRoom(newInput);
  };

  const onModalSource = source => {
    setNavigationModal(true);
    setModalSource(source);
  };

  if (queryString && queryString !== "") {
    const urlParams = new URLSearchParams(queryString);
    const entries = urlParams.entries();
    const params = paramsToObject(entries);
    if (Object.keys(searchInput).length === 0) {
      setSearching(true);
      let query: SearchProps = {
        min_price: params.targetPrice,
        max_price: params.targetPrice,
        house_type: params.propertyType,
        address: params.address,
        laundry: params.laundry,
        wheelchair: params.accessibility,
        smoking: params.smokingPolicy,
        parking: params.parking,
        private_room: params.selectedRoom,
        private_bath: params.selectedBath,
        cats: params.petPolicy
      };
      setInputs(query);
      getRooms(query)
        .then(a => {
          setCoordinates({
            lat: Number(a.coordinates.lat),
            lng: Number(a.coordinates.lng)
          });
          setRooms(a.rooms);
          if (a.rooms.length === 0) {
            setNoRooms(true);
          }
          setSearching(false);
        })
        .catch(a => props.history.push("/error"));
    }
  }

  return (
    <div>
      {searching ? (
        <Loading />
      ) : isTabletOrMobile ? (
        <SearchMobile
          mapCoords={mapCoords}
          isMobile={isTabletOrMobile}
          rooms={rooms}
          searchInput={searchInput}
          onSearchRoom={onSearchRoom}
          showModal={onModalSource}
          onDragMarkerEnd={onDragMarkerEnd}
        />
      ) : (
        <div className="searchFormWrapper">
          <TopInput
            onSearchRoom={onSearchRoom}
            min_price={searchInput.min_price}
            max_price={searchInput.max_price}
            house_type={searchInput.house_type}
            address={searchInput.address}
            laundry={searchInput.laundry}
            private_room={searchInput.private_room}
            private_bath={searchInput.private_bath}
            wheelchair={searchInput.wheelchair}
            smoking={searchInput.smoking}
            cats={searchInput.cats}
            parking={searchInput.parking}
          />
          <div>
            <Row>
              <Col span={8} className="room-list-wrapper">
                {noRooms ? (
                  <ErrorPage noRooms={true} />
                ) : (
                  rooms.map((item, index) => {
                    return (
                      <RoomList
                        datePublished={item.run_id}
                        price={item.price}
                        image={item.image}
                        address={item.address}
                        href={item.href}
                        source={item.source}
                        showModal={onModalSource}
                      />
                    );
                  })
                )}
              </Col>
              <Col span={16}>
                <Map
                  coords={mapCoords}
                  onDragMarkerEnd={onDragMarkerEnd}
                  rooms={rooms}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
      <div>
        <NavigationModal
          visible={showNavigationModal}
          onCancel={() => setNavigationModal(false)}
          onOk={() => setNavigationModal(false)}
          source={modalSource}
        />
      </div>
    </div>
  );
};

export default ResultPage;
