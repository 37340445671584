import React, { FunctionComponent } from "react";
import styled from "styled-components";
import MaterialIcon from "@material/react-material-icon";

interface SelectInput {
  value: string | number;
  style?: React.CSSProperties;
  className?: string;
  placeholder?: string;
  variant?: string;
  placeholderSelected?: boolean;
}

const InputContainer = React.memo(styled.div<{
  variant: SelectInput["variant"];
}>`
  position: relative;
  ${props => {
    if (props.variant === "small") {
      return `
        min-width: 150px;
        height: 31px;
        font-size: 14px;
        // padding: 0px 30px;
      `;
    }
  }}
`);

const Input = React.memo(styled.select<SelectInput>`
  height: 44px;
  border-radius: 9px !important;
  -webkit-appearance: none;
  border: solid 1px #979797;
  outline: none;
  padding: 0px 28px 0px 4px;
  font-family: AvenirNext;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  background-color: #ffffff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  option:checked {
    color: #7d7d7d;
    padding: 0 20px;
  }

  option:first-child {
    color: #7d7d7d !important;
  }

  ${props => {
    let styling = "";

    if (props.variant == "small") {
      styling += `
        height: 31px;
        font-size: 14px;
        // padding: 0px 20px;
      `;
    }

    if (props.placeholderSelected) {
      styling += `
        color: #7d7d7d;

        option { 
          color: #4a4a4a;
        }
      `;
    }

    return styling;
  }}
`);

const Icon = React.memo(styled.div`
  width: 32px;
  height: 23px;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  justify-content: left;
  text-align: left;
  user-select: none !important;
  pointer-events: none;

  & > i {
    align-self: center;
    font-size: 32px;
    position: relative;
    // left: -4px;
  }
`);

const MemoIcon = React.memo(({ icon }: { icon: string }) => (
  <MaterialIcon icon={icon} />
));

const SelectInput: FunctionComponent<SelectInput> = props => {
  const isPlaceholderSelected = (value: any) => {
    return value === null || value === "-1" || value === -1 || value === "";
  };

  const placeholderSelected = isPlaceholderSelected(props.value);

  return (
    <InputContainer className="position-relative" variant={props.variant}>
      <Input
        value={props.value}
        className={props.className}
        placeholder={props.placeholder}
        style={props.style}
        variant={props.variant}
        placeholderSelected={placeholderSelected}
        {...props}
      >
        {props.children}
      </Input>

      <Icon>
        <MemoIcon icon="arrow_drop_down" />
      </Icon>
    </InputContainer>
  );
};

export default SelectInput;
