import React, { useState, useEffect, FunctionComponent } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import cookie from "react-cookies";
import Home from "./pages/Home";
import { HouseRentReportComponent } from "./components/HouseRentReportComponent";
import SearchResult from "./pages/SearchResult";
import RentalReport from "./pages/RentalReport";
import LoginPage from "./pages/LoginPage";
import RentalsRegistry from "./pages/RentalsRegistry";
import RentalsInfo from "./pages/RentalsInfo";
import { searchParamsToObject } from "./utilities";
import Test from "./pages/Test";
import HeatMapAnalytics from "./pages/Dashboard-pages/HeatMapAnalytics"
import ErrorPage from "./pages/ErrorPage";
import { useAppState } from "./store/app/state";
import { useUser } from "./store/user/context";
import analytics from "./api/analytics";
import DefaultLayout from "./layouts/DefaultLayout";
import "./styles/App.css";
import Searches from "./pages/Dashboard-pages/Searches";
import Analytics from "./pages/Dashboard-pages/Analytics";
import SelectLanguage from './components/SelectLanguage';
import i18nConfig from './config/i18n';
import gaia, { t } from './gaia/gaia';
const Results = React.lazy(() => import("./pages/Results"));

const App: FunctionComponent = props => {
  const { dispatchAppState } = useAppState();
  const { user, setUser } = useUser();
  const [token, setToken] = useState<string | null>(null);
  const [locale, setLocale] = useState<string>('en')
  const [isLocale, setIsLocale] = useState<boolean>(false)
  // Effect that only runs once; retrieves user and token from Cookie.
  useEffect(() => {
    
    // When component mounts, we check the localStorage.
    const userCookie = cookie.load("rentalios_user");
    const tokenCookie = cookie.load("rentalios_token");

    // And save them in the app state.
    if (userCookie && tokenCookie) {
      setUser(userCookie);
      setToken(tokenCookie);

      // Send the event to analytics as a "soft" login
      analytics.loginEvent(userCookie.id, true);
    }
  }, []);

  // Effect for parsing the URL query into an object and adding it to state.
  // Only executes once.
  useEffect(() => {
    // If we have coordinates, then don't do anything aside from displaying the info.
    // (NOTE: after being able to parse and interpret the results, we have to
    // do the logic for sending out an API call here).
    const queryString = window.location.search;

    if (queryString && queryString.length > 0) {
      (async function () {
        // Try to parse the information in the URL query.
        // First check if there is a URL query to parse into an object.
        const queryObj: any = searchParamsToObject(queryString);
        // console.log("We have URL params", queryObj);

        // Check if it's valid
        // TO-DO

        // Then merge with the App State.
        dispatchAppState({ type: "mergeStates", payload: queryObj });
      })();
    }
  }, []);

  useEffect(() => {
    const { supportedLocales, fallbackLocale } = i18nConfig;
  
    gaia
      .init({
        supportedLocales: Object.keys(supportedLocales),
        locale: 'en',
        fallbackLocale
      })
      .then((locale) => {
        setLocale(locale)
        setIsLocale(true)
      });
     

  }, []);


  const onSelectLocale = (newLocale: string) => {
    setIsLocale(false)
   
    gaia.setLocale(newLocale)
      .then(() => {
        setLocale(newLocale)
        setIsLocale(true)
      });
  };

  return (
    <div className="antialiased">
     
      <BrowserRouter>
        <DefaultLayout>
          <Switch>
            <Route path="/houseRent" exact key="Home" component={HouseRentReportComponent} />
            <Route path="/" exact key="Home" component={Home} />
            <Route path="/login" exact key="Login" component={LoginPage} />
            <Route
              path="/rentals_registry"
              exact
              key="RentalsRegistry"
              component={RentalsRegistry}
            />
            <Route
              path="/rentals_info"
              exact
              key="RentalsInfo"
              component={RentalsInfo}
            />

            <Route
              path="/searches"
              exact
              key="Searches"
              component={Searches}
            />

            <Route
              path="/analytics"
              exact
              key="Analytics"
              component={Analytics}
            />

            <Route
              path="/heatMapAnalytics"
              exact
              key="heatMapAnalytics"
              component={HeatMapAnalytics}
            />

            <Route
              path="/error"
              exact
              key="Error"
              component={ErrorPage}
            />

            <React.Suspense fallback={<div>Loading...</div>}>
              <Route path="/results" key="Results" exact component={Results} />
              <Route path="/report" key="Report" exact component={HouseRentReportComponent} />
              <Route path="/rentalreport" key="RentalReport" exact component={RentalReport} />
              <Route path="/searchresults" key="SearchResults" exact component={SearchResult} />
            </React.Suspense>

            {/* Dev only route for testing components */}
            {process.env.NODE_ENV === "development" && (
              <Route path="/test" exact key="test" component={Test} />
            )}
            <Redirect from="/*" to="/" />
          </Switch>
        </DefaultLayout>
      </BrowserRouter>
      {/* <SelectLanguage
        value={locale}
        onChange={(onSelectLocale)}
      /> */}
    </div>
  );
};

export default App;
