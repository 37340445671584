import React, { useState, useEffect } from "react";
import { MainContent } from "../components/Fields";
import Logo from "../components/icons/Logo";
import {
  HeaderText,
  Subtitle,
  Circle,
  CirclePatch,
  LogoContainer,
  LoadingText,
  Container
} from "../components/pages/Loading";
import { PRIMARY_COLOR } from "../constants";
import { t } from '../gaia/gaia';
const Loading: React.FunctionComponent<{}> = props => {
  return (
    <MainContent className="container text-center">
      <HeaderText>{t('ALWAYS A FAIR PRICE')}</HeaderText>

      <Subtitle>
        {t('Rentalios is the best way to compare rent rates with other local listings')} 
      </Subtitle>

      <Container className="d-flex align-self-center mx-auto">
        <Circle color={PRIMARY_COLOR}>
          <CirclePatch />
        </Circle>

        <LogoContainer className="align-self-center text-center w-100">
          <Logo height="72px" width="72px" />
        </LogoContainer>
      </Container>

      <LoadingText className="mx-auto mt-3">{t('Calculating rent')} . . .</LoadingText>
    </MainContent>
  );
};

export default Loading;
