import React from "react";

const Logo: React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = "#E24C4B", width = "18px", height = "18px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
      fill={color}
    />
    <path
      d="M18 8.99995C18 13.95 13.95 18 9 18C6.13127 18 3.60002 16.7062 1.96875 14.625C3.4875 15.8625 5.45626 16.5937 7.59374 16.5937C12.5437 16.5937 16.5937 12.5437 16.5937 7.59373C16.5937 5.45625 15.8625 3.4875 14.625 1.96875C16.7063 3.59994 18 6.13118 18 8.99995Z"
      fill="#D1403F"
    />
    <path
      d="M13.1062 13.1063C12.7687 13.4438 12.2062 13.4438 11.8687 13.1063L8.99999 10.2376L6.13125 13.1063C5.79376 13.4438 5.23123 13.4438 4.89374 13.1063C4.55625 12.7688 4.55625 12.2063 4.89374 11.8688L7.76248 9.00007L4.89374 6.13134C4.55625 5.79385 4.55625 5.23132 4.89374 4.89383C5.23123 4.55634 5.79376 4.55634 6.13125 4.89383L8.99999 7.76257L11.8687 4.89383C12.2062 4.55634 12.7687 4.55634 13.1062 4.89383C13.4437 5.23132 13.4437 5.79385 13.1062 6.13134L10.2375 9.00007L13.1062 11.8688C13.4437 12.2063 13.4437 12.7688 13.1062 13.1063Z"
      fill="white"
    />
  </svg>
);

export default Logo;
