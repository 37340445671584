import React, { useState, useMemo } from "react";
import axios from "axios";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Nav, Modal } from "react-bootstrap";
import cookie from "react-cookies";
import analytics from "../api/analytics";
import { useUser } from "../store/user/context";
import LoggedInItem from "./LoginLoggedOutItem";
import LoggedOutItem, { AvatarImage } from "./LoginLoggedInItem";
import styled from "styled-components";
import useWindowSize from "../hooks/useWindowSize";
import { LG_SCREEN_BREAKPOINT } from "../constants";

type AppType = 1 /* Google*/ | 2 /* Facebook */;

const URL = "https://us-central1-rentalios.cloudfunctions.net";
const clientId =
  "441046532427-qvrimgnl8tb2ebvkjrfu2fomcjkke5bi.apps.googleusercontent.com";

const LoginButton = styled.button``;

const GoogleLoginButton = renderProps => (
  <button
    style={styles.google_button}
    onClick={renderProps.onClick}
    disabled={false}
  >
    Continue with Google
  </button>
);

const FacebookLoginButton = renderProps => (
  <button style={styles.facebook_button} onClick={renderProps.onClick}>
    Continue with Facebook
  </button>
);

const Login = props => {
  const [show, setShow] = useState(false);
  const { user, setUser } = useUser();
  const cookieMeta = {
    path: "/",
    domain: "rentalios.com"
  };

  const isLoggedIn = useMemo(() => !!user, [user]);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const logout = () => {
    const userId = user ? user.id : undefined;

    // Clear login info in the browser
    window.localStorage.clear();
    cookie.remove("rentalios_token", cookieMeta);
    cookie.remove("rentalios_user", cookieMeta);
    cookie.remove("rentalios-email", cookieMeta);

    // Clear from App's state
    setUser(null);

    // Event in GA of user log out action
    analytics.logoutEvent(userId);
    window.open("/", "_self");
  };

  const rentals = () => {
    props.history.push("/myRentals");
  };

  const responseGoogle = async response => {    
    cookie.save("rentalios-email", response.profileObj.email);
    handleResponse(response, 2);
  };

  const responseFacebook = async response => {
    cookie.save("rentalios-email", response.email);
    handleResponse(response, 1);
  };

  const handleResponse = async (response, app: AppType) => {
    let userData = {};

    // The received response object varies depending on if it's a success callback coming from facebook or google.
    // Create a new object depending on the received AppType.
    if (app === 1) {
      userData = {
        userID: response.userID,
        name: response.name,
        email: response.email,
        picture: response.picture.data.url
      };
    } else if (app === 2) {
      const { profileObj } = response;

      userData = {
        userID: response.googleId,
        name: `${profileObj.givenName} ${profileObj.familyName}`,
        email: profileObj.email,
        picture: profileObj.imageUrl
      };
    }

    try {
      const loginResponse = await axios.post(`${URL}/user_login`, {
        ...userData,
        app
      });
      const [user]: [User] = loginResponse.data;
      const userObject = JSON.stringify(user);

      // Save the User in browser
      window.localStorage.setItem("user", userObject);
      window.localStorage.setItem("token", response.accessToken);
      cookie.save("rentalios_token", response.accessToken, cookieMeta);
      cookie.save("rentalios_user", userObject, cookieMeta);

      // Set the App's state User
      setUser(user);

      // Close the popup
      handleClose();

      // Trigger a GA event of the user logging in
      analytics.loginEvent(user.id);

      // Redirect user
      if (user.rentals === 0) {
        window.location.href = "/rent_info";
      }
    } catch (error) {
      console.error(error);
      // TODO: - Add an alert to tell the user what he should do...
    }
  };

  const [width] = useWindowSize();
  const navWidthClass = React.useMemo(
    () => (window.innerWidth <= LG_SCREEN_BREAKPOINT ? "w-100" : "w-auto"),
    [width]
  );

  return (
    <Nav className={`mr-auto ml-md-auto ${navWidthClass}`}>
      {!isLoggedIn ? (
        <LoggedInItem handleShow={handleShow} />
      ) : (
        <LoggedOutItem
          user={user}
          handleLogout={logout}
          handleRentals={rentals}
        />
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={styles.login_title}>
          <Modal.Title className="text-center">Sign up to see more</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <GoogleLogin
            clientId={clientId}
            buttonText="Continue with Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
            // Provide custom render element for Google Login button
            render={GoogleLoginButton}
          />
          <br />
          <FacebookLogin
            appId="1278109152340180"
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            textButton="Continue with Facebook"
            render={FacebookLoginButton}
          />
        </Modal.Body>
      </Modal>
    </Nav>
  );
};

const styles = {
  login_title: {
    fontFamily: "AvenirNext, Helvetica",
    display: "block",
    color: "#4a4a4a"
  },
  google_button: {
    fontFamily: "AvenirNext, Helvetica",
    background: "#d74937",
    border: "none",
    width: "100%",
    padding: "15px",
    marginBottom: "15px",
    color: "#FFF"
    // fontWeight: "bold"
  },
  facebook_button: {
    fontFamily: "AvenirNext, Helvetica",
    background: "#3c5a99",
    border: "none",
    width: "100%",
    padding: "15px",
    marginBottom: "15px",
    color: "#FFF"
    // fontWeight: "bold"
  }
};

export default Login;
