import React from "react";

const Logo: React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = "#414850", width = "16px", height = "16px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8.52759 2.56696V15.9999H11.7364V13.5679H12.7913V15.9999H16.0001V2.56696H8.52759ZM14.3005 10.7955H10.2272V9.74058H14.3005V10.7955ZM14.3005 8.22849H10.2272V7.17355H14.3005V8.22849ZM14.3005 5.66146H10.2272V4.60652H14.3005V5.66146Z"
      fill={color}
    />
    <path
      d="M0 16H3.20879V13.568H4.26374V16H7.47253V0H0V16ZM1.69962 2.03956H5.77291V3.09451H1.69962V2.03956ZM1.69962 4.60659H5.77291V5.66154H1.69962V4.60659ZM1.69962 7.17363H5.77291V8.22857H1.69962V7.17363ZM1.69962 9.74066H5.77291V10.7956H1.69962V9.74066Z"
      fill={color}
    />
  </svg>
);

export default Logo;
