import React from "react";

const Logo: React.FunctionComponent<{
  width?: string;
  height?: string;
}> = ({ width = "20", height = "20" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 100 100"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 14H77C81.9635 14 86 18.0365 86 23V77C86 81.9635 81.9635 86 77 86H63.5V61.25H70.25L74.75 50H63.5V41C63.5 38.7168 65.2033 38.7299 67.4077 38.747C67.6015 38.7485 67.7992 38.75 68 38.75H72.5V27.5H63.5C56.0435 27.5 50 33.5435 50 41V50H41V61.25H50V86H23C18.0365 86 14 81.9635 14 77V23C14 18.0365 18.0365 14 23 14Z"
      fill="white"
    />
  </svg>
);

export default Logo;
