// src/comparables-context.tsx
import React, { createContext, useState, useMemo, useContext } from "react";
import "./user.d";

const UserContext = createContext<UserContextValue | undefined>(undefined);

function UserProvider(props: UserProviderProps) {
  const [user, setUser] = useState<User>();

  const value = useMemo(() => ({ user, setUser }), [user]);

  return <UserContext.Provider value={value} {...props} />;
}

function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUser };
