import React from "react";

const Logo: React.FunctionComponent<{
  width?: string;
  height?: string;
  color?: string;
}> = ({ color = "#373471", width = "20", height = "20" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 100 100"
    fill="none"
  >
    <path
      d="M39.8 14L24.2 15.1803V18.7213H30.2V77.1475C30.2 77.7377 29.96 79.1541 29 80.0984C28.04 81.0426 24.6 80.8852 23 80.6885V84.2295H46.4V80.6885H41.6C40.16 80.6885 39.8 79.5082 39.8 78.918V60.623C40.6 60.4262 42.32 60.0328 42.8 60.0328C43.28 60.0328 55.4 77.3443 61.4 86C63.32 85.0557 72.6 84.0328 77 83.6393L74.6 80.0984C73.4 79.9016 70.52 79.3902 68.6 78.918C66.68 78.4459 55.8 62.9836 50.6 55.3115C52.6 52.9508 57.32 47.2852 60.2 43.5082C63.08 39.7311 68.6 38 71 37.6066V34.0656C68.6 34.459 63.56 35.3639 62.6 35.8361C61.64 36.3082 54.2 36.4262 50.6 36.4262V40.5574H56C55.04 43.8623 44.8 52.9508 39.8 57.082V14Z"
      fill={color}
    />
  </svg>
);

export default Logo;
