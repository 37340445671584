import React, { useEffect, useState } from "react";
import {
  LaundryField,
  FurnishingField,
  AccessibilityField,
  SmokingPolicyField,
  ParkingField,
  PetPolicyField
} from "../../Fields";
import { AdvancedSettings } from "./Home";

export default (props: AdvancedSettings) => {
  const { className, dispatch, state, isMobile } = props;
  const spacingClass = "mb-3";

  return (
    <div className={className}>
      {!isMobile ? (
        <>
          {/* First extra row - Laundry, Furnishing, and Accessibility*/}
          <div className={`row no-gutters ${spacingClass}`}>
            <div className="col">
              <span className="input-title">Laundry:</span>
              <LaundryField
                value={state.laundry}
                onChange={e => {
                  dispatch({ type: "setLaundry", payload: e.target.value });
                }}
              />
            </div>

            <div className="col mx-3">
              <span className="input-title">Furnishing:</span>
              <FurnishingField
                value={state.furnishing}
                onChange={e => {
                  dispatch({ type: "setFurnishing", payload: e.target.value });
                }}
              />
            </div>

            <div className="col">
              <span className="input-title">Accessibility:</span>
              <AccessibilityField
                value={state.accessibility}
                onChange={e => {
                  dispatch({
                    type: "setAccessibility",
                    payload: e.target.value
                  });
                }}
              />
            </div>
          </div>

          {/* Second extra row  - Smoking, Parking, and Pet*/}
          <div className={`row no-gutters ${spacingClass}`}>
            <div className="col">
              <span className="input-title">Smoking Policy:</span>
              <SmokingPolicyField
                value={state.smokingPolicy}
                onChange={e => {
                  dispatch({
                    type: "setSmokingPolicy",
                    payload: e.target.value
                  });
                }}
              />
            </div>

            <div className="col mx-3">
              <span className="input-title">Parking:</span>
              <ParkingField
                value={state.parking}
                onChange={e => {
                  dispatch({ type: "setParking", payload: e.target.value });
                }}
              />
            </div>

            <div className="col">
              <span className="input-title">Pet Policy:</span>
              <PetPolicyField
                value={state.petPolicy}
                onChange={e => {
                  dispatch({ type: "setPetPolicy", payload: e.target.value });
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {/* First extra row - Laundry and Furnishing*/}
          <div className={`row no-gutters ${spacingClass}`}>
            <div className="col">
              <span className="input-title">Laundry:</span>
              <LaundryField
                value={state.laundry}
                onChange={e => {
                  dispatch({ type: "setLaundry", payload: e.target.value });
                }}
              />
            </div>

            <div className="col ml-3">
              <span className="input-title">Furnishing:</span>
              <FurnishingField
                value={state.furnishing}
                onChange={e => {
                  dispatch({ type: "setFurnishing", payload: e.target.value });
                }}
              />
            </div>
          </div>

          {/* Second extra row  - Accesibility and Smoking*/}
          <div className={`row no-gutters ${spacingClass}`}>
            <span className="input-title">Accessibility:</span>
            <div className="col">
              <AccessibilityField
                value={state.accessibility}
                onChange={e => {
                  dispatch({
                    type: "setAccessibility",
                    payload: e.target.value
                  });
                }}
              />
            </div>
            <div className="col ml-3">
              <span className="input-title">Smoking Policy:</span>
              <SmokingPolicyField
                value={state.smokingPolicy}
                onChange={e => {
                  dispatch({
                    type: "setSmokingPolicy",
                    payload: e.target.value
                  });
                }}
              />
            </div>
          </div>

          {/* Third extra row - Parking and PetPolicy*/}
          <div className={`row no-gutters ${spacingClass}`}>
            <div className="col">
              <span className="input-title">Parking:</span>
              <ParkingField
                value={state.parking}
                onChange={e => {
                  dispatch({ type: "setParking", payload: e.target.value });
                }}
              />
            </div>

            <div className="col ml-3">
              <span className="input-title">Pet Policy:</span>
              <PetPolicyField
                value={state.petPolicy}
                onChange={e => {
                  dispatch({ type: "setPetPolicy", payload: e.target.value });
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
