import styled, { keyframes } from "styled-components";
import React, { FunctionComponent, MouseEvent, CSSProperties } from "react";
import { PRIMARY_COLOR } from "../../../constants";

type Button = {
  className?: string;
  style?: CSSProperties;
  variant?: "small";
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
};

const StyledButton = React.memo(styled.button<Button>`
  height: 61px;
  width: 418px;
  border-radius: 10px;
  background-color: ${PRIMARY_COLOR};
  padding-left: 3rem;
  padding-right: 3rem;

  font-family: AvenirNext, Helvetica;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  outline: none !important;
  cursor: pointer;
  border: none;

  ${props => {
    if (props.variant === "small")
      return `
        height: 44px;
      `;
  }};
`);

const GoBackButton: FunctionComponent<Button> = React.memo(
  ({ children, ...props }) => <StyledButton {...props}>{children}</StyledButton>
);

const HeaderText = React.memo(styled.div`
  font-family: AvenirNext;
  font-size: 35px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-top: 50px;
  margin-bottom: 50px;
`);

const Subtitle = React.memo(styled.div`
  font-family: AvenirNext;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-bottom: 30px;
`);

const Container = React.memo(styled.div`
  width: 140px;
  height: 140px;
  z-index: 1;
`);

const LogoContainer = React.memo(styled.div`
  color: #4a4a4a;
  z-index: 2;
`);

export {
  LogoContainer,
  Container,
  Subtitle,
  HeaderText,
  GoBackButton
};
