import React from "react";
import AddressMarkerIcon from "../../../components/icons/Marker";

const AddressMarker = props => {
  return (
    <div style={{ cursor: "pointer", zIndex: 99, position: "absolute" }}>
      <AddressMarkerIcon />
    </div>
  );
};

export default AddressMarker;
