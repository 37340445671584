import React from "react";

const Logo: React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = "#9B9B9B", width = "16", height = "16" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12.1467 9.83979C11.2647 9.83979 10.4751 10.2388 9.95119 10.8642L6.54316 8.94539C6.64914 8.64964 6.70739 8.33164 6.70739 8.00023C6.70739 7.66889 6.64914 7.35089 6.54316 7.05514L9.95093 5.13583C10.4748 5.76147 11.2645 6.16061 12.1466 6.16061C13.7199 6.16061 14.9999 4.89078 14.9999 3.32987C15.0001 1.76944 13.72 0.5 12.1467 0.5C10.5732 0.5 9.29301 1.76944 9.29301 3.32981C9.29301 3.66121 9.35119 3.97928 9.45724 4.2751L6.04927 6.19447C5.52543 5.56916 4.73589 5.17029 3.8541 5.17029C2.28036 5.17029 1 6.43973 1 8.00017C1 9.56054 2.28036 10.83 3.8541 10.83C4.73589 10.83 5.52536 10.4311 6.04921 9.80586L9.45724 11.7248C9.35119 12.0206 9.29294 12.3387 9.29294 12.6702C9.29294 14.2306 10.5731 15.5 12.1466 15.5C13.72 15.5 15 14.2305 15 12.6702C15.0001 11.1096 13.72 9.83979 12.1467 9.83979ZM12.1467 1.49025C13.1694 1.49025 14.0015 2.31546 14.0015 3.32981C14.0015 4.34468 13.1694 5.17029 12.1467 5.17029C11.1238 5.17029 10.2916 4.34468 10.2916 3.32981C10.2916 2.31546 11.1238 1.49025 12.1467 1.49025ZM3.85416 9.83979C2.83103 9.83979 1.99863 9.01451 1.99863 8.00023C1.99863 6.98582 2.83103 6.16061 3.85416 6.16061C4.87689 6.16061 5.70889 6.98582 5.70889 8.00023C5.70889 9.01451 4.87682 9.83979 3.85416 9.83979ZM12.1467 14.5098C11.1238 14.5098 10.2916 13.6845 10.2916 12.6703C10.2916 11.6556 11.1238 10.83 12.1467 10.83C13.1694 10.83 14.0015 11.6556 14.0015 12.6703C14.0015 13.6845 13.1694 14.5098 12.1467 14.5098Z"
      fill={color}
      stroke={color}
      stroke-width="0.5"
    />
  </svg>
);

export default Logo;
