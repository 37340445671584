import React, {
  Component,
  CSSProperties,
  useRef
} from "react";
import Button from "../components/Button";
import useWindowSize from "../hooks/useWindowSize";

import {
  PropertyTypeField,
  ReportContainer,
  RoomsInput,
  BathsInput,
  MainContent,
  SmokingPolicyField,
  ReportTitle,
  AdvancedOptionsButton,
  Description,
  TargetPriceField,
  LaundryField,
  ParkingField,
  FurnishingField,
  PetPolicyField,
  AccessibilityField
} from "../components/Fields";
import RoomReport from "../components/RoomReport";
import AddressInput from "../components/AddressInput";
// const [width] = useWindowSize();


interface IProps {
  superhero: string;
}

interface IState {
  propertyType: string;
  laundry: string;
  furnishing: string;
  accesibility: string;
  smokingPolicy: string;
  parking: string;
  petPolicy: string;
  address: string;
  roomsArray: [{}];
}

function createReport() {

}

export class HouseRentReportComponent extends React.Component {
  state: IState;
  

  // isMobile = React.useMemo(() => window.innerWidth <= 767, [width]);

  addRoom() {
    var roomsArrayNew = this.state.roomsArray;
    console.log({id:roomsArrayNew.length,bathroomType:0})
    roomsArrayNew.push({id:roomsArrayNew.length,bathroomType:0});
    // console.log(this.state.roomsArray)
    this.setState(roomsArrayNew)
    console.log(this.state);

  }

  deleteRoom = (id) =>{
    var roomsArrayNew = this.state.roomsArray;
    for (let index = 0; index < roomsArrayNew.length; index++) {
      const element = roomsArrayNew[index];
      if (element["id"] == id){
        roomsArrayNew.splice(index, 1);
      }
    }
    this.setState({roomsArray:roomsArrayNew});
  }

  handleAddressChange(value: string){
    this.state.address = value;
  }

  handleButtonClick(){

  }

  changeInput(inputType:string, value: string){
    var obj = {};
    obj[inputType] = value;
    this.setState(obj);
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
      propertyType: '',
      laundry: '',
      furnishing: '',
      accesibility: '',
      smokingPolicy: '',
      parking: '',
      petPolicy: '',
      address: '',
      roomsArray: [{id:0,bedroomType:0,bathroomType:0}]
    }
    this.deleteRoom = this.deleteRoom.bind(this);
  }
  render() {
     return (
      <ReportContainer className="container">
        <div className="justify-content-leading text-leading">
          <ReportTitle>
            Rental report
          </ReportTitle>
          <div className="ml-1" style={{ maxWidth: "750px" , marginTop:"45px"}}>
            <p style={{fontStyle:"normal", fontSize:"20px", fontFamily:"AvenirNext", fontWeight:"bold"}}>Address</p>
            <AddressInput
              style={!this.state.address ? { borderColor: "red" } : {borderColor: "green"}}
              value={this.state.address}
              report={true}
              setValue={this.handleAddressChange}
              onEnterPress={this.handleButtonClick}
            />
  
            <div className="row no-gutters mt-4">
              <div className="col-md mb-3 mb-md-0" style={{display:"inline-block"}}>
                <div className="row no-gutters">
                  <div className="col-5">
                  <p className="mt-2" style={{display:"inline-block", color: "#737373", fontSize: "20px", fontFamily: "AvenirNext", fontStyle:"normal"}}>Property Type</p>
                  </div>
                  <div className="col-7">
                  <PropertyTypeField
                  className="w-100"
                  value={this.state.propertyType}
                  onChange={(e: any) =>
                    this.changeInput('propertyType',e.target.value)
                  }
                />
                  </div>
                </div>
              </div>
  
              <div className="w-100 d-md-none"></div>
  
              <div className="col ml-md-3 mb-3 mb-md-0">
                <div className="row no-gutters">
                    <div className="col-5">
                      <p className="mt-2" style={{display:"inline-block", color: "#737373", fontSize: "20px", fontFamily: "AvenirNext", fontStyle:"normal"}}>Smoking Policy</p>
                    </div>
                    <div className="col-7">
                      <SmokingPolicyField
                        className="w-100"
                        value={this.state.smokingPolicy}
                        onChange={e =>
                          this.changeInput('smokingPolicy',e.target.value)
                        }
                      />
                  </div>
                </div>
              </div>
  
              <div className="w-100 d-md-none"></div>
  
              
            </div>
  
            <div className="row no-gutters">
              <div className="col-md mb-3 mb-md-0" style={{display:"inline-block"}}>
                <div className="row no-gutters">
                  <div className="col-5">
                  <p className="mt-2" style={{display:"inline-block", color: "#737373", fontSize: "20px", fontFamily: "AvenirNext", fontStyle:"normal"}}>Laundry</p>
                  </div>
                  <div className="col-7">
                  <LaundryField
                  className="w-100"
                  value={this.state.laundry}
                  onChange={(e: any) =>
                    this.changeInput('laundry',e.target.value)

                  }
                />
                  </div>
                </div>
              </div>
  
              <div className="w-100 d-md-none"></div>
  
              <div className="col ml-md-3 mb-3 mb-md-0">
                <div className="row no-gutters">
                    <div className="col-5">
                      <p className="mt-2" style={{display:"inline-block", color: "#737373", fontSize: "20px", fontFamily: "AvenirNext", fontStyle:"normal"}}>Parking</p>
                    </div>
                    <div className="col-7">
                      <ParkingField
                        className="w-100"
                        value={this.state.parking}
                        onChange={e =>
                          this.changeInput('parking',e.target.value)
                        }
                      />
                  </div>
                </div>
              </div>
  
              <div className="w-100 d-md-none"></div>
            </div>
  
            <div className="row no-gutters">
              <div className="col-md mb-3 mb-md-0" style={{display:"inline-block"}}>
                <div className="row no-gutters">
                  <div className="col-5">
                  <p className="mt-2" style={{display:"inline-block", color: "#737373", fontSize: "20px", fontFamily: "AvenirNext", fontStyle:"normal"}}>Furnishing</p>
                  </div>
                  <div className="col-7">
                  <FurnishingField
                  className="w-100"
                  value={this.state.furnishing}
                  onChange={(e: any) =>
                    this.changeInput('furnishing',e.target.value)

                  }
                />
                  </div>
                </div>
              </div>
  
              <div className="w-100 d-md-none"></div>
  
              <div className="col ml-md-3 mb-3 mb-md-0">
                <div className="row no-gutters">
                    <div className="col-5">
                      <p className="mt-2" style={{display:"inline-block", color: "#737373", fontSize: "20px", fontFamily: "AvenirNext", fontStyle:"normal"}}>Pets</p>
                    </div>
                    <div className="col-7">
                      <PetPolicyField
                        className="w-100"
                        value={this.state.petPolicy}
                        onChange={e =>
                          this.changeInput('petPolicy',e.target.value)
                        }
                      />
                  </div>
                </div>
              </div>
  
              <div className="w-100 d-md-none"></div>
            </div>
  
            <div className="row no-gutters">
              <div className="col-md mb-3 mb-md-0" style={{display:"inline-block"}}>
                <div className="row no-gutters">
                  <div className="col-5">
                  <p className="mt-2" style={{display:"inline-block", color: "#737373", fontSize: "20px", fontFamily: "AvenirNext", fontStyle:"normal"}}>Accessibility</p>
                  </div>
                  <div className="col-7">
                  <AccessibilityField
                  className="w-100"
                  value={this.state.accesibility}
                  onChange={(e: any) =>
                    this.changeInput('accesibility',e.target.value)

                  }
                />
                  </div>
                </div>
              </div>
  
              <div className="w-100 d-md-none"></div>
  
              <div className="col ml-md-3 mb-3 mb-md-0">
              </div>
  
              <div className="w-100 d-md-none"></div>
            </div>
  
            <div className="row no-gutters mt-3">
              <div
                className={`
                  col-md mb-md-0
                  ${false ? "d-none" : "d-block"}
                `}
              />
  
              <div
                className={`
                  col-md mb-md-0 ml-md-3
                  ${false? "d-none" : "d-block"}
                `}
              />
            </div>
            </div>
  
            {
              this.state.roomsArray.map((room, index) => {
                return(
                <RoomReport
                bedroom={0}
                bathroom={0}
                key={index}
                id={index}
                deleteRoom={this.deleteRoom.bind(this,index)}
                />
                )
              })
            }
        </div>
        <div className="d-flex justify-content-end" style= {{alignContent:"right"}}>
          <Button style={{fontWeight:"bold", alignContent:"right", backgroundColor:"black", color:"white"}} className="p-2" onClick={this.addRoom.bind(this)}>
            Add room
          </Button>
        </div>
        <div className="d-flex">
          <Button style={{fontWeight:"bold"}} className="mt-5 mx-auto" onClick={createReport}>
            Create Report
          </Button>
        </div>
        <div className="d-flex">
        </div>
      </ReportContainer>
    )
    }
  }
