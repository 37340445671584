import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { mappingSearches } from './utilities/TableFunctions'
import Table from './Table'
import SearchesMap from '../SearchesMap/SearchesMap'
import Pagination from '../Pagination'

export default function SearchingTable() {
    const [listings, setListings] = useState([])    
    const [page, setPage] = useState(0)    
    const [loading, setLoading] = useState(true)
    const [noData, setNoData] = useState(false)        
    const [actualSearch, setActualSearch] = useState({})
    const [nextPage, setNextPage] = useState()
    const [previousPage, setPreviousPage] = useState(null)
    const [numberOfPages, setNumberOfPages] = useState({})

    const baseURL = 'https://us-central1-rentalios.cloudfunctions.net/searches?offset='        

    useEffect(() => {
        var newListing = []
        let cancel

        let endpoint = baseURL + (page * 50).toString()
        setLoading(true)
        axios.get(endpoint, {
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(res => {                          
            if(res.data.length === 0) {
                setNoData(true)
                setLoading(false)                
                return 
            }

            setNoData(false)
            res.data.map(search => (
                newListing = [...newListing, mappingSearches(search)]
            ))               
            
            establishPages(newListing[0])
            
            setActualSearch(newListing[0])
            setListings(newListing)
            setLoading(false)
        })
            
        return () => cancel()
    }, [page])

    function establishPages(firstData) {
        if ((firstData.id - 50) <= 0) {
            let pages = {pageOne: page+1, pageTwo: null}
            setNumberOfPages(pages)
            setNextPage(null)
            setPreviousPage(1)            
        }
        else {
            let pages = {pageOne: page+1, pageTwo: page+2}
            setNumberOfPages(pages)
            setNextPage(1)
            setPreviousPage(page === 0 ? null : 1)            
        }        
    }
    
    function goToNextPage() {
        setPage(page+1)
    }

    function goToPreviousPage() {
        setPage(page-1)
    }

    function setNewSearch(id) {        
        setActualSearch(listings.find(element => element.id === id))        
        window.scrollTo(0,0)
    }

    if (loading) return "Loading..."    
    if (noData) return "No Data"

    return (
        <div style={{background: "#f5f7fa"}}> 
            <SearchesMap element = {actualSearch} />              
            <div className="container-fluid">
            <Table            
                listing = {listings} 
                element = {actualSearch}
                setNewSearch = {setNewSearch}                
            />
            </div>            
            <Pagination
                goToNext = {nextPage ? goToNextPage : null}
                goToPrev = {previousPage ? goToPreviousPage : null}
                pages = {numberOfPages}
            />
        </div>        
    )
}
