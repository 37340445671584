import React, { FunctionComponent, useState } from "react";
import "./error.css";
import Map from "../../icons/Map";

const BottomMap: FunctionComponent<{}> = props => {
  return (
    <div>
      <div>
        <span className="available-cross-the-world">Available Across The World</span>
      </div>
      <div>
        <Map />
      </div>
    </div>
  );
};
export default BottomMap;
