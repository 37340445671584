import React, { FunctionComponent, CSSProperties, Component } from "react";
import styled from "styled-components";
import Delete from "../assets/images/delete.png";

import {
    PropertyTypeField,
    BathsInput,
    RoomsInput

  } from "../components/Fields";
import { initialize } from "react-ga";

const ContentIcon = React.memo(styled.img`
  width: 20px;
  height: 20px;
`);

interface RoomState {
  bedroom: number;
  bathroom: number;
}

export default class RoomRemport extends Component<{id: number, bedroom: number, bathroom: number, deleteRoom}> {
  state: RoomState;
  changeBedroom(event){
    this.setState({bedroom:event.target.value})
  }

  changeBathroom(event){
    this.setState({bathroom:event.target.value})

  }
  constructor(props){
    super(props);
    this.state = {
      bedroom:0,
      bathroom:0
    }
    this.changeBathroom = this.changeBathroom.bind(this)
    this.changeBedroom = this.changeBedroom.bind(this)
  }

  render(){
  return (
    <div>
        <div className="row">
          <div className="col">
              <p style={{fontFamily: "AvenirNext", fontSize: "20px", fontWeight: "bold"}}>Room {this.props.id}</p>
          </div> 
          <div className="col" style={{textAlign:"right"}}>
              <ContentIcon style={{alignSelf:"right", display:"inline-block"}} src={Delete} /><p onClick={this.props.deleteRoom} style={{fontFamily: "AvenirNext", fontSize: "15px", fontWeight: "normal", color: "#d33131", textAlign:"right", display:"inline-block"}}>Delete</p>
          </div> 
        </div>
        <hr style={{margin:"0px !important", border:"1px solid #979797"}}></hr>
        <div className="row no-gutters">
          <div className="col-md mb-3 mb-md-0" style={{display:"inline-block"}}>
            <div className="row no-gutters">
              <div className="col-5">
              <p className="mt-2" style={{display:"inline-block", color: "#737373", fontSize: "20px", fontFamily: "AvenirNext", fontStyle:"normal"}}>Bedroom type</p>
              </div>
              <div className="col-7">
              <RoomsInput
              className="w-100"
              value={this.state.bedroom}
              onChange={this.changeBedroom}
            />
              </div>
            </div>
          </div>

          <div className="w-100 d-md-none"></div>

          <div className="col ml-md-3 mb-3 mb-md-0">
            <div className="row no-gutters">
                <div className="col-5">
                  <p className="mt-2" style={{display:"inline-block", color: "#737373", fontSize: "20px", fontFamily: "AvenirNext", fontStyle:"normal"}}>Bathroom type</p>
                </div>
                <div className="col-7">
                  <BathsInput
                    className="w-100"
                    value={this.state.bathroom}
                    onChange={this.changeBathroom}
                  />
              </div>
            </div>
          </div>

          <div className="w-100 d-md-none"></div>
        </div>
    </div>
  );
  }
}


  
