import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SearchNavBar from "../components/pages/Results/SearchNavbar";
import useWindowSize from "../hooks/useWindowSize";
import backgroundPattern from "../assets/background.svg";
import { useLocation } from "react-router-dom";
import { LG_SCREEN_BREAKPOINT } from "../constants";
import { searchParamsToObject } from "../utilities";

interface DefaultLayout {
  navbarOverride?: JSX.Element;
  background?: boolean;
}

const Background = React.memo(styled.div<{ background?: boolean }>`
  background-size: cover;

  ${props => {
    if (props.background != false) {
      return `background-image: url("${backgroundPattern}");`;
    }
  }}
`);

const DefaultLayout: FunctionComponent = React.memo(props => {
  const [width] = useWindowSize();
  const isMobile = React.useMemo(
    () => window.innerWidth <= LG_SCREEN_BREAKPOINT,
    [width]
  );
  const location = useLocation();
  const queryString = window.location.search;
  const queryObj: any = searchParamsToObject(queryString);
  console.log(queryObj);

  return (
    <>
      <Background background={!isMobile && location.pathname === "/"}>
        {queryObj.noHeader === "true" ? (
          <div>{props.children}</div>
        ) : (
          <div>
            <Header /*navbarOverride={location.pathname === "/$%·$SDDSASSDDS" && <SearchNavBar /> } */
            />
            <div>{props.children}</div>
          </div>
        )}
        <Footer>Footer</Footer>
      </Background>
    </>
  );
});

export default DefaultLayout;
