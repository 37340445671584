import React from "react";

const Logo: React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = "#414850", width = "18px", height = "15px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 15"
    fill="none"
  >
    <path
      d="M8.99998 0L0 7.94117H2.7V15H7.20001V9.70589H10.8V15H15.3V7.94117H18L8.99998 0Z"
      fill={color}
    />
  </svg>
);

export default Logo;
