import React from "react";

const Logo: React.FunctionComponent<{
  width?: string;
  height?: string;
}> = ({ width = "20", height = "20" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3953 100.182C33.0399 94.7017 35.8853 81.2287 46.4171 71.6543C57.0543 61.9841 63.34 53.2808 55.6038 42.1601C47.8676 31.0393 36.7469 31.5229 28.5272 35.391C21.9514 38.4855 4.78565 47.1154 -1.5 50.5L0 103.566C6.76916 104.211 20.4042 105.5 20.791 105.5C21.1778 105.5 28.6883 101.954 32.3953 100.182ZM39.6479 62.4675C45.7898 62.4675 50.7687 57.4885 50.7687 51.3467C50.7687 45.2049 45.7898 40.226 39.6479 40.226C33.5061 40.226 28.5272 45.2049 28.5272 51.3467C28.5272 57.4885 33.5061 62.4675 39.6479 62.4675Z"
        fill="white"
      />
      <path
        d="M51.7357 39.7424C59.7942 33.7791 77.942 21.0788 86.065 17.9844"
        stroke="white"
        strokeWidth="5.80214"
        strokeLinecap="round"
      />
      <path
        d="M49.8017 32.4898C49.9628 29.1052 48.1577 22.4328 39.6479 22.8196C31.1381 23.2064 26.4319 26.8489 25.1426 28.6218"
        stroke="white"
        strokeWidth="2.90107"
        strokeLinecap="round"
      />
      <path
        d="M41.582 16.0505C45.4501 15.567 53.4763 18.5647 52.7027 24.7536C51.9291 30.9426 50.2852 31.684 49.8017 32.4899"
        stroke="white"
        strokeWidth="2.90107"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.582 49.8961C43.4512 49.8961 44.9666 48.3808 44.9666 46.5116C44.9666 45.7848 44.7375 45.1116 44.3476 44.5601C46.4175 45.4464 47.8676 47.502 47.8676 49.8961C47.8676 53.1006 45.2699 55.6983 42.0655 55.6983C38.8611 55.6983 36.2634 53.1006 36.2634 49.8961C36.2634 48.0918 37.087 46.4798 38.3788 45.4157C38.2612 45.7594 38.1974 46.128 38.1974 46.5116C38.1974 48.3808 39.7127 49.8961 41.582 49.8961Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="100" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
