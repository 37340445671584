import React, { useEffect, useState } from "react";
import axios from "axios";
import Chart from "../../components/AnalyticsChart/Chart";
import AnalyticsFilter from "../../components/AnalyticsChart/components/AnalyticsFilter";
import "../../components/AnalyticsChart/css/Chart.css";
import { komodoUrl, Oauth1Helper } from "../../config/komodo";

export default function ScrapingChart() {
  const URL = `${komodoUrl}/scraping_analytics_range?`;
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [startDate, setStartDate] = useState(getDate(getDateFromToday(8 * 7)));
  const [endDate, setEndDate] = useState(getDate(getDateFromToday(0)));
  const [interval, setInterval] = useState("7");
  const [source, setSource] = useState("");
  const [scrappingValues, setScrappingValues] = useState([]);

  useEffect(() => {
    setLoading(true);

    const endpoint =
      URL +
      "start_date=" +
      startDate +
      "&end_date=" +
      endDate +
      "&interval=" +
      interval +
      "&source=" +
      source;
    let cancel;

    const request = {
      url: endpoint,
      method: "GET"
    };

    const authHeader = Oauth1Helper.getAuthHeaderForRequest(request);
    axios
      .get(request.url, {
        headers: authHeader,
        cancelToken: new axios.CancelToken(c => (cancel = c))
      })
      .then(res => {
        if (res.data.length === 0) {
          setNoData(true);
          setLoading(false);
          return;
        }

        setScrappingValues(res.data);

        setNoData(false);
        setLoading(false);
      });
  }, [startDate, endDate, interval, source]);

  function getDate(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const monthString =
      month.toString().length > 1 ? month.toString() : "0" + month.toString();
    const dayString =
      day.toString().length > 1 ? day.toString() : "0" + day.toString();

    return year.toString() + "-" + monthString + "-" + dayString;
  }

  function changeStartDate(event) {
    const value = event.event.target.value;
    if (value === "") {
      setStartDate(getDate(getDateFromToday(8 * 7)));
      return;
    }

    setStartDate(value);
  }

  function changeEndDate(event) {
    const value = event.event.target.value;
    if (value === "") {
      setEndDate(getDate(getDateFromToday(0)));
      return;
    }
    setEndDate(value);
  }

  function changeInterval(event) {
    const value = event.event.target.value;
    var numInterval;

    switch (value) {
      case "Daily":
        numInterval = "1";
        break;
      case "Weekly":
        numInterval = "7";
        break;
      case "Monthly":
        numInterval = "30";
        break;
      default:
        numInterval = "7";
        break;
    }

    setInterval(numInterval);
  }

  function changeSource(event) {
    const value = event.event.target.value;
    setSource(value);
  }

  function getDateFromToday(daysBefore) {
    var date = new Date();
    if (daysBefore === 0) {
      return date;
    }

    const newDate = date.getDate() - daysBefore;
    date.setDate(newDate);

    return date;
  }

  return (
    <div id="analytics">
      {loading ? (
        "Loading"
      ) : noData ? (
        "No data"
      ) : (
        <>
          <div>
            <Chart data={scrappingValues} source={source} />
            <div className="container mb-5">
              <hr />
              <AnalyticsFilter
                start={{ func: changeStartDate, val: startDate }}
                end={{ func: changeEndDate, val: endDate }}
                granularity={{ func: changeInterval, val: interval }}
                source={{ func: changeSource, val: source }}
              />
            </div>
          </div>

          <div className="container">
            <h2>MAP</h2>
          </div>
        </>
      )}
    </div>
  );
}
