import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { AppStateProvider } from "./store/app/state";
import { ComparablesProvider } from "./store/comparables/context";
import { ResultsProvider } from "./store/results/context";
import { UserProvider } from "./store/user/context";
import analytics from "./api/analytics";

// Initialize google analytics and set up page tracking.
analytics.intializeAnalytics();
analytics.intializePageTracking();

ReactDOM.render(
  <AppStateProvider>
    <UserProvider>
      <ComparablesProvider>
        <ResultsProvider>
          <App />
        </ResultsProvider>
      </ComparablesProvider>
    </UserProvider>
  </AppStateProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
