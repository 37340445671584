import React, { FunctionComponent, useState } from "react";
import FilterIcon from "../../icons/FilterIcon";

const FilterToggle: FunctionComponent<{
  toggled: boolean;
  className?: string;
  width?: string;
  height?: string;
  onClick?: (e: any) => void;
  style?: React.CSSProperties;
}> = ({ width, height, toggled, style, ...props }) => {
  const [hover, setHover] = useState(false);

  function mouseEnterHandler() {
    setHover(true);
  }

  function mouseLeaveHandler() {
    setHover(false);
  }

  return (
    <div
      {...props}
      style={{ ...style, cursor: "pointer" }}
      // onClick={props.onClick}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <FilterIcon
        width={width}
        height={height}
        toggled={hover || toggled}
      ></FilterIcon>
    </div>
  );
};

export default FilterToggle;
