import React from "react";

const Logo: React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = "#9B9B9B", width = "16px", height = "16px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      opacity="0.9"
      d="M8.4 0.329468C5.6 0.329468 3.12 1.83535 1.84 4.1338L0 2.31089V7.46259H5.2L2.96 5.2434C4 3.26198 6 1.91461 8.4 1.91461C11.68 1.91461 14.4 4.60934 14.4 7.85888C14.4 11.1084 11.68 13.8032 8.4 13.8032C5.76 13.8032 3.6 12.1388 2.72 9.8403H1.04C1.92 13.0106 4.88 15.3883 8.4 15.3883C12.64 15.3883 16 11.9802 16 7.85888C16 3.73752 12.56 0.329468 8.4 0.329468ZM7.2 4.29232V8.33442L10.96 10.5536L11.6 9.52328L8.4 7.62111V4.29232H7.2Z"
      fill={color}
    />
  </svg>
);

export default Logo;
