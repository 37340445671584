import React, { FunctionComponent, useState, useMemo } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useUser } from "../../../store/user/context";
import "./Rentals.d";

const RentalForm: FunctionComponent<{
  add_handler?: () => void;
  rmv_handler?: () => void;
  data: RentalForm;
  handleDataChange: (data: RentalForm) => void;
}> = props => {
  const { data, handleDataChange } = props;

  return (
    <Form.Group className="rental">
      <Form.Control
        required
        type="text"
        name="address"
        placeholder="Address"
        value={data.address}
        onChange={(e: any) => handleDataChange({ address: e.target.value })}
      />
      <Form.Control.Feedback type="invalid">Please fill in the address!</Form.Control.Feedback>
      <Row>
        <Col md={4} sm={6} style={{ marginTop: "20px" }}>
          <Form.Control
            required
            type="number"
            min="300"
            max="3250"
            name="rent"
            placeholder="$ Rent"
            value={data.rent}
            onChange={(e: any) => handleDataChange({ rent: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">Rent amount should be between $300 and $3250.</Form.Control.Feedback>
        </Col>
        <Col md={4} sm={6} style={{ marginTop: "20px" }}>
          <Form.Control
            required
            as="select"
            name="property"
            value={data.property}
            onChange={(e: any) =>
              handleDataChange({ property: e.target.value })
            }
          >
            <option value="">Property Type</option>
            <option value="1">Apartment</option>
            <option value="0">House</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">Choose a property type!</Form.Control.Feedback>
        </Col>
        <Col md={4} sm={6} style={{ marginTop: "20px" }}>
          <Form.Control
            as="select"
            name="size"
            value={data.size}
            onChange={(e: any) =>
              handleDataChange({ size: e.target.value })
            }
          >
            <option value="-1">Room Size</option>
            <option value="0">Small (70 - 125 sqft)</option>
            <option value="1">Medium (125 - 150 sqft)</option>
            <option value="2">Large (150 - 200 sqft)</option>
            <option value="3">Extra (200 - 500 sqft)</option>
          </Form.Control>
        </Col>
        <Col md={4} sm={6} style={{ marginTop: "20px" }}>
          <Form.Control
            required
            as="select"
            name="bedroom"
            value={data.bedroom}
            onChange={(e: any) => handleDataChange({ bedroom: e.target.value })}
          >
            <option value="">Bedroom Type</option>
            <option value="1">Shared Bedroom</option>
            <option value="0">Private Bedroom</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">Choose a bedroom type!</Form.Control.Feedback>
        </Col>
        <Col md={4} sm={6} style={{ marginTop: "20px" }}>
          <Form.Control
            required
            as="select"
            name="bathroom"
            value={data.bathroom}
            onChange={(e: any) =>
              handleDataChange({ bathroom: e.target.value })
            }
          >
            <option value="">Bathroom Type</option>
            <option value="1">Shared Bathroom</option>
            <option value="0">Private Bathroom</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">Choose a bathroom type!</Form.Control.Feedback>
        </Col>
        <Col md={4} sm={6} style={{ marginTop: "20px" }}>
          <Form.Control
            required
            as="select"
            name="furnishing"
            value={data.furnishing}
            onChange={(e: any) =>
              handleDataChange({ furnishing: e.target.value })
            }
          >
            <option value="">Furnishing</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">Is the place furnished?</Form.Control.Feedback>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default RentalForm;
