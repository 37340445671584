import React from "react";

const Logo: React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}> = ({ color = "#66B015", width = "14px", height = "20px" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 14 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00071 0C8.87049 0 10.6286 0.728674 11.9508 2.05161C14.3977 4.49952 14.7018 9.10532 12.6009 11.9072L7.00071 20L1.3921 11.8958C-0.700366 9.10532 -0.39628 4.49952 2.05064 2.05161C3.37285 0.728674 5.13056 0 7.00071 0ZM4.50977 6.93971C4.50977 8.34921 5.65638 9.49647 7.06511 9.49647C8.47383 9.49647 9.62045 8.34921 9.62045 6.93971C9.62045 5.53021 8.47383 4.38296 7.06511 4.38296C5.65638 4.38296 4.50977 5.53021 4.50977 6.93971Z"
      fill={color}
    />
  </svg>
);

export default Logo;
