function mappingSearches(search) {   
    const unknown = "Unknown"
    
    return {
        id: search.id,
        lat: search.latitude.toString(),
        lng: search.longitude.toString(),
        address: addressMapping(search.address, search.id),
        client: clientMapping(search.client_platform),
        currency_code: currencyCodeMapping(search.currency_code),
        house_type: search.house_type === 1 ? "House" : (search.house_type === 0 ? "Apartment" : unknown),
        private_room: search.private_room === 1 ? "Private" : (search.private_room === 0 ? "Shared" : unknown),
        private_bath: search.private_bath === 1 ? "Private" : (search.private_bath === 0 ? "Shared" : unknown),
        laundry: laundryMapping(search.laundry),
        furnished: search.furnished === 1 ? "Furnished" : (search.furnished === 0 ? "Unfurnished" : unknown),
        accessible: search.wheelchair === 1 ? "Accessible" : (search.wheelchair === 0 ? "Not Accessible" : unknown),
        smoking: search.smoking === 1 ? "Smoking" : (search.smoking === 0 ? "No Smoking" : unknown),
        parking: parkingMapping(search.parking),
        pets: petsMapping(search.cats, search.dogs),
        time_of_search: dateMapping(search.time_of_search),
        price: currencyPrice(search.prediction_price ? search.prediction_price.toString() : "0", currencyCodeMapping(search.currency_code)),
        max: currencyPrice(search.prediction_maximum ? search.prediction_maximum.toString() : "0", currencyCodeMapping(search.currency_code)),
        min: currencyPrice(search.prediction_minimum ? search.prediction_minimum.toString() : "0", currencyCodeMapping(search.currency_code))
    }
}

function currencyPrice(price, currency) {    
    var separator = getSeparator(currency)
    var symbol = getSymbol(currency)
    var thousand = getThousand(currency)       

    const newPrice = parseFloat(price.replace(/,/g, ""))
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, separator
        )
        
    const shortenedPrice = getShortenedNumber(newPrice, separator, thousand)

    return symbol + shortenedPrice
}

function getSeparator(code) {
    switch(code) {
        case "USD": return ","
        case "IDR": return "."
        case "MXN": return ","
        default: return ","
    }
}

function getSymbol(code) {
    switch(code) {
        case "USD": return "$"
        case "IDR": return "Rp. "
        case "MXN": return "$"
        case "INR": return "₹"
        default: return "$"
    }
}

function getThousand(code) {
    switch(code) {
        case "USD": return "k"
        case "IDR": return "k"
        case "MXN": return " mil"
        default: return "k"
    }
}

function getShortenedNumber(price, separator, thousand) {
    if (price.length <= 5) { return price }
  
    const priceArr = price.split(separator)
    var resultString = ""
  
    for (let i = 0; i <= priceArr.length - 2; i++) {    
      resultString += priceArr[i]
      
      if (i !== priceArr.length - 2) { resultString += separator }
    }
  
    return (resultString + thousand)
}

function addressMapping(address, id) {
    if(address == "" || !address ) { return "No address of search: " + id}

    return address
}

function currencyCodeMapping(currencyCode) { 
    if (currencyCode == "" || !currencyCode ) { return "not available" }

    return currencyCode
}

function clientMapping(client) {
    switch(client) {
        case 0:
            return "Web"
        case 1:
            return "iOS"
        case 2:
            return "Android"
    }
}

function dateMapping(time) {    
    return new Date(time).toLocaleDateString('en-GB', {  
        day : 'numeric',
        month : 'short',
        year : 'numeric'
    })        
}

function petsMapping(cats, dogs) {
    if (cats === 1 || dogs === 1) { return "Pet Friendly" }
    else if (cats === 0 || dogs === 0) { return "No Pets"}
    
    return "Unknown"
}

function parkingMapping(parking) {
    switch (parking) {
        case 0:
          return "No Parking"
        case 1:
          return "Off-street Parking"
        case 2:
          return "Street Parking"
        default:
          return "Unknown"
      }
}

function laundryMapping(laundry) {
    switch (laundry) {
        case 0:
            return "No Laundry"            
        case 1:
            return "In Unit Laundry"                
        case 2:
            return "Laundry Hookups"             
        case 3:
            return "On Building Laundry"
        case 4:
            return "On Site Laundry"
        default:
            return "Unknown"
    }
}

export {
    mappingSearches,
    currencyPrice,
    dateMapping,
    petsMapping,
    parkingMapping,
    laundryMapping,   
}