import React from 'react'
import './css/SearchesTable.css'

export default function TableHead() {
    return (
        <thead className="searchesHeader">
            <tr>
                <th scope="col">Approximate Address</th>
                <th scope="col">Type</th>
                <th scope="col">Room</th>
                <th scope="col">Bath</th>
                <th scope="col">Laundry</th>
                <th scope="col">Furnished</th>
                <th scope="col">Accessibility</th>
                <th scope="col">Smoking</th>
                <th scope="col">Parking</th>
                <th scope="col">Pets</th>
                <th scope="col">Date created</th>
                <th scope="col">Rent price</th>
                <th scope="col">Listing</th>
            </tr>        
        </thead> 
    )
}
