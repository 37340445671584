import ReactGA from "react-ga";
import "../types/GA.d";

const events = {
  // Inits Google Analytics
  intializeAnalytics() {
    const googleAnalyticsId = process.env.REACT_APP_GA_ID;

    if (googleAnalyticsId) {
      ReactGA.initialize(googleAnalyticsId, {
        // Set this to true to see debug logs of GA in the console
        debug: false
      });
    } else {
      throw "Google Analytics ID not set.";
    }
  },

  intializePageTracking() {
    ReactGA.pageview(window.location.pathname);
  },

  logoutEvent(userId?: number) {
    // Event in GA of user log out action
    const event: GAActionType = {
      category: "User",
      action: "Log out",
      value: userId
    };

    // Trigger a GA event of the user logging out
    ReactGA.event(event);
  },

  loginEvent(userId: number, softLogin: boolean = false) {
    // Set the userId for tracking.
    // The USER_ID value should be a unique, persistent, and non-personally identifiable
    // string identifier that represents a user or signed-in account across devices.
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id#user_id
    ReactGA.set({ userId: `${userId}` });

    // Trigger the login event
    const event: GAActionType = {
      category: "User",
      action: "Log in",
      value: userId,
      // When the app is relaunched we execute a login, but we set nonInteraction to true because it
      // is an automatic interaction that happens when the user is already logged in and returning
      // to the app.
      nonInteraction: softLogin
    };

    ReactGA.event(event);
  },

  estimateActionEvent(userId?: number) {
    const event: GAActionType = {
      category: "Estimation",
      action: "Estimate requested",
      value: userId
    };

    ReactGA.event(event);
  }
};

export default events;
