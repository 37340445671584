import { useState } from "react";

const formHook = (initialValues, callback) => {
  const [inputs, setInputs] = useState(initialValues);
  const handleSubmit = event => {
    if (event) event.preventDefault();
    callback();
  };
  const handleInputChange = (value: any, field: string) => {
    setInputs(inputs => ({
      ...inputs,
      [field]: value
    }));
  };
  return {
    handleSubmit,
    handleInputChange,
    inputs
  };
};
export default formHook;
